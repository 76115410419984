/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useNavigate } from "react-router-dom";
import { DatePicker, Space } from "antd";

const TreatmentPlanList = () => {
  const navigate = useNavigate();
  const [treatmentSearch, setTreatmentSearch] = useState("");
  const [diagnosis, setDiagnosis] = useState("");
  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [treatmentList, setTreatmentList] = useState([
    { id: 1, name: "Treatment 1" },
    { id: 2, name: "Treatment 2" },
    { id: 3, name: "Treatment 3" },
    { id: 4, name: "Treatment 4" },
    { id: 5, name: "Treatment 5" },
    { id: 6, name: "Treatment 6" },
    { id: 7, name: "Treatment 7" },
    { id: 8, name: "Treatment 8" },
    { id: 9, name: "Treatment 9" },
    { id: 10, name: "Treatment 10" },
    // above entery are static
  ]);

  // Filter treatment list based on search query
  const filteredTreatments = treatmentList.filter((treatment) =>
    treatment.name.toLowerCase().includes(treatmentSearch.toLowerCase())
  );

  const handleCheckboxChange = (id, treatmentName, index) => {
    setSelectedTreatments((prevSelected) => {
      if (prevSelected.find((treatment) => treatment.id === id)) {
        return prevSelected.filter((treatment) => treatment.id !== id);
      } else {
        return [...prevSelected, { id, name: treatmentName, index }];
      }
    });
  };

  const removeTreatment = (id) => {
    setSelectedTreatments((prevSelected) =>
      prevSelected.filter((treatment) => treatment.id !== id)
    );
  };

  const SubmitForm = async (e) => {
    e.preventDefault();

    // if (!name_of_treatment) {
    //   toast.error("Please fill out all required fields.");
    //   return;
    // }

    let payload = {
      selectedTreatments: selectedTreatments,
    };

    try {
      // Replace this with your API call
      // const { data } = await YourApi.post(YourApiEndpoint, payload);
      // if (data) {
      //   toast.success("Treatment added successfully.");
      //   navigate("/treatmentlist");
      // }
      console.log("Treatment added:", payload);
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item22"
        id1="menu-items22"
        activeClassName="add-treatment"
      />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Treatment Plan</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Add Treatment Plan</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={SubmitForm}>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-heading">
                          <h4>Treatment Details</h4>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms cal-icon">
                          <label>
                            Treatment Date
                            <span className="login-danger"></span>
                          </label>
                          <DatePicker
                            className="form-control datetimepicker"
                            onChange={""}
                            suffixIcon={null}
                            placeholder="Select Date"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            Add Treatment
                            <span className="login-danger"></span>
                          </label>
                          <div className="selected-treatments ">
                            {selectedTreatments.map((treatment) => (
                              <div key={treatment.id} className="treatment-tag">
                                {treatment.name}
                                <button
                                  type="button"
                                  onClick={() => removeTreatment(treatment.id)}
                                  className="remove-treatment"
                                >
                                  <FeatherIcon icon="x" size="14" />
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group ">
                          <label htmlFor="treatmentSearch">
                            Treatment Search
                          </label>
                          <br />
                          <input
                            className="form-control treatment-search"
                            type="text"
                            id="treatmentSearch"
                            placeholder="Search treatment"
                            value={treatmentSearch}
                            onChange={(e) => setTreatmentSearch(e.target.value)}
                          />
                          <br />

                          <div className="row">
                            {filteredTreatments.map((treatment, index) => (
                              <div
                                key={treatment.id}
                                className="col-md-6 col-12 d-flex checkboxinputBlock"
                              >
                                <div className="col-md-8 col-12 col-sm-12 checkboxBlock mb-3">
                                  <div className="custom-control custom-checkbox d-flex">
                                    <input
                                      type="checkbox"
                                      className="form-check-input form-check-primary"
                                      id={`treatment-${treatment.id}`}
                                      checked={
                                        !!selectedTreatments.find(
                                          (t) => t.id === treatment.id
                                        )
                                      }
                                      onChange={() =>
                                        handleCheckboxChange(
                                          treatment.id,
                                          treatment.name,
                                          index
                                        )
                                      }
                                    />
                                    <label
                                      className="form-check-label ps-3"
                                      htmlFor={`treatment-${treatment.id}`}
                                    >
                                      {treatment.name}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            Diagnosis
                            <span className="login-danger"></span>
                          </label>
                          <textarea
                            className="form-control"
                            type="text"
                            rows="3"
                            cols="50"
                            value={diagnosis}
                            onChange={(e) => setDiagnosis(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="doctor-submit text-end">
                          <button
                            type="submit"
                            className="btn btn-primary submit-form me-2"
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary cancel-form"
                            onClick={() => navigate("/patientsprofile")}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreatmentPlanList;
