/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { onShowSizeChange, itemRender } from "../../Pagination";
import {
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
} from "../../imagepath";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { loadingService } from "../../../helper/observer";
import AuthApi from "../../../helper/authApi";
import Api from "../../../helper/api";
import { Modal } from "react-bootstrap";

const TreatmentList = () => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [treatmentList, setTreatmentList] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const handleDelete = async () => {
    if (!showDelete) return;

    loadingService.next(true);
    try {
      const response = await AuthApi.delete(
        `${Api.deletetreatmentmaster}/${showDelete}`
      );
      loadingService.next(false);

      if (response) {
        toast.success("Deleted Successfully");
        // Optionally refresh the list or perform other UI updates
      }
    } catch (error) {
      loadingService.next(false);
      toast.error("An error occurred while deleting");
    }
  };

  const columns = [
    {
      title: "Sr No.",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name of  Treatment",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Details",
      dataIndex: "detail",
      sorter: (a, b) => a.detail.localeCompare(b.detail),
    },
    {
      title: "Action",
      dataIndex: "",
      render: (text, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle ms-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  className="dropdown-item"
                  to={`/edit-treatment/${record.id}`}
                >
                  <i className="far fa-edit me-2" />
                  Edit
                </Link>
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#delete_treatment"
                  onClick={() => setShowDelete(record.id)}
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  const fetchData = async () => {
    try {
      const udata = localStorage.getItem("userdata");
      const data = JSON.parse(udata);
      loadingService.next(true);
      const response = await AuthApi.get(
        `${Api.treatmentmasterlist}/${data.user.organization_id}`
      );
      loadingService.next(false);

      const { data: responseData, message, status } = response.data;
      if (!responseData) {
        if (status === 401) navigate("/");
        // Handle other error cases
        return;
      }
      setTreatmentList(responseData);
    } catch (error) {
      console.error("Error fetching form list:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Ensure treatmentList is available before applying filter
  const filteredTreatmentList = treatmentList
    ? treatmentList.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchQuery)
        )
      )
    : [];

  useEffect(() => {
    fetchData();
  }, []);
  
  return (
    <>
      <Header />
      <Sidebar
        id="menu-item22"
        id1="menu-items22"
        activeClassName="treatment-list"
      />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Treatment </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">Treatment List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Treatment List</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              <form>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                  onChange={handleSearch}
                                />
                                <Link className="btn">
                                  <img src={searchnormal} alt="#" />
                                </Link>
                              </form>
                            </div>
                            <div className="add-group">
                              <Link
                                to="/add-treatment"
                                className="btn btn-primary add-pluss ms-2"
                              >
                                <img src={plusicon} alt="#" />
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        <Link to="#" className=" me-2">
                          <img src={pdficon} alt="#" />
                        </Link>
                        <Link to="#" className=" me-2"></Link>
                        <Link to="#" className=" me-2">
                          <img src={pdficon3} alt="#" />
                        </Link>
                        <Link to="#">
                          <img src={pdficon4} alt="#" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      pagination={{
                        total: filteredTreatmentList.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        // showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={filteredTreatmentList}
                      rowSelection={rowSelection}
                      rowKey={(record) => record.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showDelete} onHide={() => setShowDelete(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Are you sure want to delete this ?</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={imagesend} alt="#" width={50} height={46} />
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setShowDelete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleDelete} // Call the delete function here
            >
              Send
            </button>
          </Modal.Footer>
        </Modal>
    </>
  );
};

export default TreatmentList;
