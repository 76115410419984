/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { DatePicker } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Select from "react-select";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import toast from "react-hot-toast";
import { loadingService } from "../../helper/observer";

const EditPatients = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [first_name, setFirst_name] = useState("");
  const [middle_name, setMiddle_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [contact_number, setContact_Number] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState("");
  const [userData, setUserData] = useState(null);
  const [bookAppointment, setBookAppointment] = useState("");
  const [checkbookAppointment, setCheckBookAppointment] = useState(false);
  const [patient_image, setPatient_image] = useState(null); // Store the file
  const [patientImageURL, setPatientImageURL] = useState(""); // Store the URL for previously uploaded image
  const [patientName, setPatientName] = useState("");
  const [whatsapp_notification, setWhatsapp_Notification] = useState("");
  const [imagePreview, setImagePreview] = useState(""); // For new image preview

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    setCheckBookAppointment(event.target.checked);
  };

  const getPatientInfo = async () => {
    try {
      const response = await AuthApi.get(`${Api.patientInfo}/${id}`);
      const { data, message, status } = response;
      
      if (!data) {
        toast.success(message);
        if (status === 401) navigate("/login");
        return;
      }

      const patient = data.data;
      setFirst_name(patient.first_name);
      setMiddle_name(patient.middle_name);
      setLast_name(patient.last_name);
      setGender(patient.gender);
      setAge(patient.age);
      setContact_Number(patient.contact_number);
      setEmail(patient.email);
      setDob(patient.dob);
      setAddress(patient.address);
      setPatientImageURL(patient.patient_image);
      setWhatsapp_Notification(patient.whatsapp_notification);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPatientInfo();
  }, []);

  // Handle file selection and preview
  const handleFileChange = (setter, nameSetter, previewSetter) => (event) => {
    const file = event.target.files[0];
    setter(file);
    nameSetter(file ? file.name : "");

    // Create preview URL
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        previewSetter(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      previewSetter("");
    }
  };

  const SubmitForm = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("middle_name", middle_name);
    formData.append("last_name", last_name);
    formData.append("contact_number", contact_number);
    formData.append("email", email);
    if (dob)
      formData.append(
        "dob",
        dob instanceof Date ? dob.toISOString().split("T")[0] : dob
      );
    formData.append("age", age);
    formData.append("gender", gender);
    formData.append("address", address);
    if (patient_image instanceof File) {
      formData.append("patient_image", patient_image);
    }
    formData.append(
      "whatsapp_notification",
      whatsapp_notification === "Yes" ? "1" : "0"
    );

    console.log("FormData contents:");
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      loadingService.next(true);
      const response = await AuthApi.post(
        `${Api.patientUpdate}/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      loadingService.next(false);

      if (response.data) {
        toast.success("Patient updated successfully");
        navigate("/patientslist");
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      if (error.response && error.response.data && error.response.data.errors) {
        console.log("Validation errors:", error.response.data.errors);
        for (let field in error.response.data.errors) {
          toast.error(`${field}: ${error.response.data.errors[field][0]}`);
        }
      } else {
        toast.error(
          "An error occurred while submitting the form. Please try again later."
        );
      }
      loadingService.next(false);
    }
  };

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="add-patient"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Patients </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Edit Patient</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={SubmitForm}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Patinets Details</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              First Name <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="first_name"
                              placeholder=""
                              value={first_name}
                              onChange={(e) => setFirst_name(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Middle Name <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="middle_name"
                              placeholder=""
                              value={middle_name}
                              onChange={(e) => setMiddle_name(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Last Name <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="last_name"
                              placeholder=""
                              value={last_name}
                              onChange={(e) => setLast_name(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Mobile No.<span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="contact_number"
                              placeholder=""
                              maxLength={10}
                              value={contact_number}
                              onChange={(e) =>
                                setContact_Number(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Email <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="email"
                              name="email"
                              placeholder=""
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="form-group local-forms cal-icon">
                            <label>
                              Date Of Birth{" "}
                              <span className="login-danger"></span>
                            </label>
                            <DatePicker
                              className="form-control datetimepicker"
                              format="YYYY-MM-DD"
                              selected={dob}
                              onChange={(date) => setDob(date)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Age <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="age"
                              placeholder=""
                              value={age}
                              onChange={(e) => setAge(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="form-group select-gender">
                            <label className="gen-label">
                              Gender <span className="login-danger"></span>
                            </label>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  className="form-check-input"
                                  value="male"
                                  checked={gender === "Male"}
                                  onChange={(e) => setGender(e.target.value)}
                                />
                                Male
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  className="form-check-input"
                                  value="female"
                                  checked={gender === "Female"}
                                  onChange={(e) => setGender(e.target.value)}
                                />
                                Female
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12">
                          <div className="form-group local-forms">
                            <label>
                              Address <span className="login-danger"></span>
                            </label>
                            <textarea
                              className="form-control"
                              rows={3}
                              cols={30}
                              name="address"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-top-form">
                            <label className="local-top">Patient Image</label>
                            <div className="settings-btn upload-files-avator">
                              <input
                                type="file"
                                accept="image/*"
                                name="patient_image"
                                id="patient_image"
                                className="hide-input"
                                onChange={handleFileChange(setPatient_image, setPatientName, setImagePreview)}
                              />
                              <label htmlFor="patient_image" className="upload">
                                Choose File
                              </label>
                            </div>

                            {/* Display file name */}
                            <p>
                              <strong>Image:</strong> {patientName || "No file chosen"}
                            </p>

                            {/* Conditionally show the image */}
                            {imagePreview ? (
                              <div className="image-preview">
                                <img
                                  src={imagePreview}
                                  alt="Selected Image Preview"
                                  style={{
                                    maxWidth: "15%",
                                    height: "auto",
                                    marginTop: "10px",
                                  }}
                                />
                              </div>
                            ) : patientImageURL ? ( // Corrected this part
                              <div className="image-preview">
                                <img
                                  src={patientImageURL}
                                  alt="Patient"
                                  style={{ maxWidth: "100%", height: "auto" }}
                                />
                              </div>
                            ) : (
                              <p>No image uploaded yet.</p>
                            )}
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group select-gender">
                            <label className="gen-label">
                              WhatsApp Notification{" "}
                              <span className="login-danger"></span>
                            </label>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="whatsapp"
                                  value="Yes"
                                  className="form-check-input"
                                  checked={whatsapp_notification === "Yes"}
                                  onChange={(e) =>
                                    setWhatsapp_Notification(e.target.value)
                                  }
                                />
                                Yes
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="whatsapp"
                                  value="No"
                                  className="form-check-input"
                                  checked={whatsapp_notification === "No"}
                                  onChange={(e) =>
                                    setWhatsapp_Notification(e.target.value)
                                  }
                                />
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group select-gender">
                              <label className="gen-label">
                                Book Appointment{" "}
                                <span className="login-danger"></span>
                              </label>
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    type="checkbox"
                                    name="book_appointment"
                                    className="form-check-input"
                                    checked={checkbookAppointment}
                                    onChange={handleCheckboxChange}
                                  />
                                  Click Here
                                </label>
                              </div>
                            </div>
                          </div>

                          {/* Conditionally render the address field based on checkbox state */}
                          {checkbookAppointment && (
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Book Appointment{" "}
                                  <span className="login-danger"></span>
                                </label>

                                <DatePicker
                                  className="form-control datetimepicker"
                                  format="DD-MMM-YYYY"
                                  selected={bookAppointment}
                                  onChange={(date) => setBookAppointment(date)}
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              onClick={() => navigate("/patientslist")}
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default EditPatients;
