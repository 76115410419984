/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { DatePicker } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Select from "react-select";
import { imagesend } from "../imagepath";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import { loadingService } from "../../helper/observer";

const Add_Expense = () => {
  const navigate = useNavigate();
  const [organization_id, setorganization_id] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [userData, setUserData] = useState(null);
  const [item_name, setitem_name] = useState("");
  const [purchase_from, setPurchaseForm] = useState("");
  const [age, setage] = useState("");
  const [purchase_by, setpurchase_by] = useState("");
  const [amount, setamount] = useState("");
  const [paid_by, setpaid_by] = useState("");
  const [payment_status, setpayment_status] = useState("");
  const [other_info, setother_info] = useState("");
  const [receipt, setReceipt] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [purchase_date, setPurchased] = useState([
    // { value: 1, label: "Select Doctor" },
    { value: 2, label: "Bernardo James" },
    { value: 3, label: "Galaviz Lalema" },
    { value: 4, label: "Tarah Williams" },
  ]);
  // eslint-disable-next-line no-unused-vars
  const [payment_mode, setPaymentmethod] = useState([
    { value: 1, label: "Select Card" },
    { value: 2, label: "Cheque" },
    { value: 3, label: "Debit Card" },
    { value: 4, label: "Pay Pal " },
  ]);
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState([
    { value: 1, label: "Select statue" },
    { value: 2, label: "Approved" },
    { value: 3, label: "Rejected" },
    { value: 4, label: "Pending" },
  ]);
  // eslint-disable-next-line no-unused-vars
  const loadFile = (event) => {
    // Handle file loading logic here
  };
  const fetchBranchId = async () => {
    try {
      const udata = localStorage.getItem("userdata");
      if (udata) {
        const data = JSON.parse(udata);
        setorganization_id(data.user.organization_id); // Update organization_id/branchid
      }
    } catch (error) {
      console.error("Error fetching organization ID:", error);
    }
  };
  useEffect(() => {
    fetchBranchId();
  }, []);
  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);

    // Ensure userData is not null before accessing data property
    if (data && data.data) {
      setUserData(data.data);
    }
  }, []);

  const SubmitForm = async (e) => {
    e.preventDefault();

    if (!item_name || !purchase_from) {
      toast.error("Please fill out all required fields.");
      return;
    }

    // Check if userData is not null
    if (!userData || !userData.organization_id) {
      toast.error("User data is not available.");
      return;
    }

    let payload = {
      user_id: userData.user_id,
      item_name: item_name,
      purchase_date: purchase_date,
      purchase_form: purchase_from,
      age: age,
      purchase_by: purchase_by,
      amount: amount,
      paid_by:paid_by,
      payment_status: payment_status,
      other_info:other_info,
      payment_mode : payment_mode,
    };

    try {
      loadingService.next(true);

      const { data } = await AuthApi.post(
        `${Api.addexpense}/${organization_id}`, 
        payload);
      loadingService.next(false);
      if (data) {
        toast.success("Success");
        navigate("");
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  // eslint-disable-next-line no-unused-vars
  const onChange = (date, dateString) => {};
  return (
    <>
      <Header />
      <Sidebar />
      <>
        <div>
          <div className="page-wrapper">
            <div className="content">
              {/* Page Header */}
              <div className="page-header">
                <div className="row">
                  <div className="col-sm-12">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#">Accounts</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <i className="feather-chevron-right">
                          <FeatherIcon icon="chevron-right" />
                        </i>
                      </li>
                      <li className="breadcrumb-item active">Add Expense</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <form onSubmit={SubmitForm}>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-heading">
                              <h4>Add Expenses</h4>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Item Name
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder
                                value={item_name}
                                onChange={(e) => setitem_name(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Purchase From
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder
                                value={purchase_from}
                                onChange={(e) =>
                                  setPurchaseForm(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms cal-icon">
                              <label>
                                Purchase Date
                                <span className="login-danger"></span>
                              </label>
                              <DatePicker
                                className="form-control datetimepicker"
                                onChange={onChange}
                                suffixIcon={null}
                                // placeholder='24/11/2022'
                                value={""}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Purchased by
                                <span className="login-danger"></span>
                              </label>
                              <Select
                                defaultValue={selectedOption}
                                onChange={setSelectedOption}
                                options={purchase_date}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                id="search-commodity"
                                components={{
                                  IndicatorSeparator: () => null,

                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "2px solid rgba(46, 55, 164, 0.1);",
                                    boxShadow: state.isFocused
                                      ? "0 0 0 1px #2e37a4"
                                      : "none",
                                    "&:hover": {
                                      borderColor: state.isFocused
                                        ? "none"
                                        : "2px solid rgba(46, 55, 164, 0.1)",
                                    },
                                    borderRadius: "10px",
                                    fontSize: "14px",
                                    minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen
                                      ? "rotate(-180deg)"
                                      : "rotate(0)",
                                    transition: "250ms",
                                    width: "35px",
                                    height: "35px",
                                  }),
                                }} 
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Age
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder
                                value={age}
                                onChange={(e) => setage(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                              Setpaid_by
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder
                                value={paid_by}
                                onChange={(e) => setpaid_by(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                              Purchase By
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder
                                value={purchase_by}
                                onChange={(e) => setpurchase_by(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                              Setpayment Status
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder
                                value={payment_status}
                                onChange={(e) => setpayment_status(e.target.value)}
                              />
                            </div>
                          </div><div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                              Setother Info
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder
                                value={other_info}
                                onChange={(e) => setother_info(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Amount
                                <span className="login-danger"></span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder
                                value={""}
                              />
                            </div>
                          </div>
                          
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Paid By
                                <span className="login-danger"></span>
                              </label>
                              <Select
                                defaultValue={selectedOption}
                                onChange={setamount}
                                options={payment_mode}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                id="search-commodity"
                                components={{
                                  IndicatorSeparator: () => null,

                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "2px solid rgba(46, 55, 164, 0.1);",
                                    boxShadow: state.isFocused
                                      ? "0 0 0 1px #2e37a4"
                                      : "none",
                                    "&:hover": {
                                      borderColor: state.isFocused
                                        ? "none"
                                        : "2px solid rgba(46, 55, 164, 0.1)",
                                    },
                                    borderRadius: "10px",
                                    fontSize: "14px",
                                    minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen
                                      ? "rotate(-180deg)"
                                      : "rotate(0)",
                                    transition: "250ms",
                                    width: "35px",
                                    height: "35px",
                                  }),
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-4 col-xl-4">
                            <div className="form-group local-forms">
                              <label>
                                Payment Status
                                <span className="login-danger"></span>
                              </label>
                              <Select
                                defaultValue={selectedOption}
                                onChange={setSelectedOption}
                                options={status}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                id="search-commodity"
                                components={{
                                  IndicatorSeparator: () => null,
                                  
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "2px solid rgba(46, 55, 164, 0.1);",
                                    boxShadow: state.isFocused
                                      ? "0 0 0 1px #2e37a4"
                                      : "none",
                                    "&:hover": {
                                      borderColor: state.isFocused
                                        ? "none"
                                        : "2px solid rgba(46, 55, 164, 0.1)",
                                    },
                                    borderRadius: "10px",
                                    fontSize: "14px",
                                    minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen
                                      ? "rotate(-180deg)"
                                      : "rotate(0)",
                                    transition: "250ms",
                                    width: "35px",
                                    height: "35px",
                                  }),
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-8">
                            <div className="form-group local-forms">
                              <label>
                                Other Information
                                <span className="login-danger"></span>
                              </label>
                              <textarea className="form-control" rows={2} />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-top-form">
                              <label className="local-top">
                                Receipt <span className="login-danger"></span>
                              </label>
                              <div className="settings-btn upload-files-avator">
                                <input
                                  type="file"
                                  accept="image/*"
                                  name="patient_image"
                                  id="file"
                                  className="hide-input"
                                  value={receipt}
                                  onChange={(e) => setReceipt(e.target.value)}
                                />
                                <label htmlFor="file" className="upload">
                                  Choose File
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="doctor-submit text-end">
                              <button
                                type="submit"
                                className="btn btn-primary submit-form me-2"
                              >
                                Create Expense
                              </button>
                              <button
                                type="submit"
                                className="btn btn-primary cancel-form"
                                onClick={() => {
                                  navigate("/expenses");
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    <Link
                      to="#"
                      className="btn btn-white me-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button type="submit" className="btn btn-danger">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Add_Expense;
