/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { favicon, imagesend } from "../imagepath";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { DatePicker } from "antd";
import Select from "react-select";
import toast from "react-hot-toast";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";

const EditBranches = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  //   const [branchid, setBranchID] = useState(null);
  const [branch_name, setbranch_name] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [is_default_wp_number, setis_default_wp_number] = useState(false);
  const [organization_id, setorganization_id] = useState("");
  const [address_line1, setaddress_line1] = useState("");
  const [address_line2, setaddress_line2] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [postal_code, setpostal_code] = useState("");

  useEffect(() => {
    getEditInfo();
  }, []);

  const getEditInfo = async () => {
    try {
      const response = await AuthApi.get(`${Api.branchinfo}/${id}`);
      const { data, message, status } = response;

      // Check if the status is 401 and ensure it's defined
      if (status === 401) {
        toast.error("Unauthorized. Redirecting to login...");
        navigate("/login");
        return;
      }

      // Check if data exists
      if (!data || !data.data) {
        toast.error(message || "An error occurred.");
        return; // Exit the function if no data is found
      }

      const branch = data.data;

      // Set state values with a fallback for missing fields
      setbranch_name(branch.branch_name || "");
      setphone_number(branch.phone_number || "");
      setis_default_wp_number(branch.is_default_wp_number || "");
      setorganization_id(branch.organization_id || "");
      setaddress_line1(branch.address_line1 || "");
      setaddress_line2(branch.address_line2 || "");
      setcity(branch.city || "");
      setstate(branch.state || "");
      setpostal_code(branch.postal_code || "");
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while fetching the information.");
    }
  };

  const SubmitForm = async (e) => {
    e.preventDefault();

    if (!branch_name) {
      toast.error("Please fill out all required fields.");
      return;
    }

    let payload = {
      branch_name: branch_name,
      phone_number: phone_number,
      is_default_wp_number: is_default_wp_number,
      organization_id: organization_id,
      address_line1: address_line1,
      address_line2: address_line2,
      city: city,
      state: state,
      postal_code: postal_code,
    };
    try {
      const { status } = await AuthApi.put(
        `${Api.updatebranch}/${id}`,
        payload
      );

      if (status) {
        toast.success("successfully");
        navigate("/branchlist");
      }
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
      console.error("Error occurred while submitting form:", error);
    }
  };

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item1"
        id1="menu-items1"
        activeClassName="edit-doctor"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Branches</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Edit Branch</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={SubmitForm}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Edit Branch Details</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Branch Name{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={branch_name}
                              onChange={(e) => setbranch_name(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              phone_number{" "}
                              <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={phone_number}
                              onChange={(e) => setphone_number(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group">
                            <label className="local-forms">
                              Do you want us to use this number for WhatsApp
                              Update?
                            </label>
                            <div className="status-toggle">
                              <input
                                type="checkbox"
                                id="status_1"
                                className="check"
                                checked={is_default_wp_number}
                                onChange={(e) =>
                                  setis_default_wp_number(e.target.checked)
                                } // Use e.target.checked for boolean
                              />
                              <label htmlFor="status_1" className="checktoggle">
                                checkbox
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              organization_id{" "}
                              <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={organization_id}
                              onChange={(e) =>
                                setorganization_id(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              address_line1{" "}
                              <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={address_line1}
                              onChange={(e) => setaddress_line1(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              address_line2{" "}
                              <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={address_line2}
                              onChange={(e) => setaddress_line2(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              city <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={city}
                              onChange={(e) => setcity(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              state <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={state}
                              onChange={(e) => setstate(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              postal_code <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={postal_code}
                              onChange={(e) => setpostal_code(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary cancel-form"
                              onClick={() => navigate("/branchlist")}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default EditBranches;
