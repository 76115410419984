/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { DatePicker } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Select from "react-select";
import { Link } from "react-router-dom";
import Api from "../../../helper/api";
import AuthApi from "../../../helper/authApi";
import toast from "react-hot-toast";
import { loadingService } from "../../../helper/observer";
import GeneralSettingsHeader from "../../GeneralSettingsHeader";
import OrganisationDropdown from "../../OrganisationDropdown";

const Registration = () => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [ownerdefaultwpnumber, setOwnerdefaultwpnumber] = useState(false);
  const [clinicName, setClinicName] = useState("");
  const [clinicPhoneNumber, setClinicPhoneNumber] = useState("");
  const [clinicAddressLine1, setClinicAddressLine1] = useState("");
  const [clinicAddressLine2, setClinicAddressLine2] = useState("");
  const [cityClinic, setCityClinic] = useState("");
  const [cityState, setCityState] = useState("");
  const [cityPincode, setCityPincode] = useState("");
  const [isDefaultWpNumber, setIsDefaultWpNumber] = useState(false);
  const [branchName, setBranchName] = useState("");
  const [branchAddressLine1, setBranchAddressLine1] = useState("");
  const [branchAddressLine2, setBranchAddressLine2] = useState("");
  const [branchState, setBranchState] = useState("");
  const [branchCity, setBranchCity] = useState("");
  const [branchPincode, setBranchPincode] = useState("");
  const [isBranchWpNumber, setIsBranchWpNumber] = useState(false);
  const [isBranchphoneNumber, setIsBranchphoneNumber] = useState("");
  const [userData, setUserData] = useState(null);

  // Handler function for owner WhatsApp checkbox
const handleOwnerCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setOwnerdefaultwpnumber(isChecked);
  
    // If owner WhatsApp is checked, uncheck the clinic WhatsApp checkbox
    if (isChecked) {
      setIsDefaultWpNumber(false);
    }
  };
  
  // Handler function for clinic WhatsApp checkbox
  const handleClinicCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setIsDefaultWpNumber(isChecked);
  
    // If clinic WhatsApp is checked, uncheck the owner WhatsApp checkbox
    if (isChecked) {
      setOwnerdefaultwpnumber(false);
    }
  };


  const SubmitForm = async (e) => {
    e.preventDefault();

    // Frontend validation to check if passwords match
  if (password !== passwordConfirmation) {
    toast.error("Passwords do not match.");
    return;
  }


    if (!password || !passwordConfirmation || !name) {
      toast.error("Please fill out all required fields.");
      return;
    }
    let payload = {
        name: name,
        email: email,
        mobile_number: mobileNumber,
        password: password,
        password_confirmation: passwordConfirmation,
        is_owner_no_is_default_wp_number: ownerdefaultwpnumber,
        clinic_name: clinicName,
        clinic_phone_number: clinicPhoneNumber,
        clinic_address_line1: clinicAddressLine1,
        clinic_address_line2: clinicAddressLine2,
        clinic_city: cityClinic,
        clinic_state: cityState,
        clinic_pincode: cityPincode,
        default_wp_number: isDefaultWpNumber,
        branch_name: branchName,
        branch_address_line1: branchAddressLine1,
        branch_address_line2: branchAddressLine2,
        branch_state: branchState,
        branch_city: branchCity,
        branch_pincode: branchPincode,
        is_branch_wp_number: isBranchWpNumber,
        branch_phone_number: isBranchphoneNumber,
      
        
      };
  
      try {
        loadingService.next(true);
  
        const { data } = await AuthApi.post(Api.register, payload);
        loadingService.next(false);

        if (data) {
          toast.success("Success");
          
        }
      } catch (error) {
        console.error("Error occurred while submitting form:", error);
        // Handle error - display error message or perform appropriate actions
        toast.error(
          "An error occurred while submitting the form. Please try again later."
        );
      }
    };
  
    useEffect(() => {
      // set default styling
      let udata = localStorage.getItem("userdata");
      let data = JSON.parse(udata);
  
      setUserData(userData);
      setUserData(data.data);

    }, []);
  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="add-patient"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">App Settings </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Registeration</li>
                  </ul>
                </div>
              </div>
            </div>
            <OrganisationDropdown />
            <GeneralSettingsHeader />
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={SubmitForm}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>New Registeration</h4>
                          </div>
                        </div>
                        <div className="col-12 mb-2">
                          <div className="form-heading">
                            <h5>Personal Details</h5>
                          </div>
                        </div>
                        {/* Name */}
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Name <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              placeholder=""
                            />
                          </div>
                        </div>

                        {/* Email */}
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Email <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder=""
                            />
                          </div>
                        </div>

                        {/* Mobile Number */}
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Mobile Number{" "}
                              <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              maxLength={10}
                              value={mobileNumber}
                              onChange={(e) => setMobileNumber(e.target.value)}
                            />
                          </div>
                        </div>

                        {/* WhatsApp Update */}
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group">
                            <label className="whatsapp-label">
                              Do you want us to use this number for WhatsApp
                              Update?
                            </label>
                            <div className="status-toggle">
                              <input
                                type="checkbox"
                                id="default_whatsap"
                                className="check"
                                checked={ownerdefaultwpnumber}
                                onChange={handleOwnerCheckboxChange}
                                />
                              <label htmlFor="default_whatsap" className="checktoggle">
                                checkbox
                              </label>
                            </div>
                          </div>
                        </div>

                        {/* Password */}
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Password <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              maxLength={10}
                            />
                          </div>
                        </div>

                        {/* Confirm Password */}
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Confirm Password{" "}
                              <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="password"
                              value={passwordConfirmation}
                              onChange={(e) =>
                                setPasswordConfirmation(e.target.value)
                              }
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 mb-2">
                            <div className="form-heading">
                              <h5>Clinic Details</h5>
                            </div>
                          </div>
                          {/* Clinic Name */}
                          <div className="col-12 col-md-4 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Clinic Name{" "}
                                <span className="login-danger"></span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={clinicName}
                                onChange={(e) => setClinicName(e.target.value)}
                                placeholder=""
                              />
                            </div>
                          </div>

                          {/* Clinic Phone Number */}
                          <div className="col-12 col-md-4 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Clinic Number{" "}
                                <span className="login-danger"></span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                maxLength={10}
                                value={clinicPhoneNumber}
                                onChange={(e) =>
                                  setClinicPhoneNumber(e.target.value)
                                }
                                placeholder=""
                              />
                            </div>
                          </div>

                          {/* Clinic Address Line 1 */}
                          <div className="col-12 col-md-4 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Clinic Address Line 1{" "}
                                <span className="login-danger"></span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={clinicAddressLine1}
                                onChange={(e) =>
                                  setClinicAddressLine1(e.target.value)
                                }
                              />
                            </div>
                          </div>

                          {/* WhatsApp Update */}
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group">
                              <label className="whatsapp-label">
                                Do you want us to use this number for WhatsApp
                                Update?
                              </label>
                              <div className="status-toggle">
                                <input
                                  type="checkbox"
                                  id="clinic_whatsapp_no."
                                  className="check"
                                  checked={isDefaultWpNumber}
                                  onChange={handleClinicCheckboxChange}
                                />
                                <label
                                  htmlFor="clinic_whatsapp_no."
                                  className="checktoggle"
                                >
                                  checkbox
                                </label>
                              </div>
                            </div>
                          </div>

                          {/* Clinic Address Line 2 */}
                          <div className="col-12 col-md-4 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Clinic Address Line 2{" "}
                                <span className="login-danger"></span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={clinicAddressLine2}
                                onChange={(e) =>
                                  setClinicAddressLine2(e.target.value)
                                }
                              />
                            </div>
                          </div>

                          {/* City Clinic */}
                          <div className="col-12 col-md-4 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                City Clinic{" "}
                                <span className="login-danger"></span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={cityClinic}
                                onChange={(e) => setCityClinic(e.target.value)}
                              />
                            </div>
                          </div>

                          {/* City State */}
                          <div className="col-12 col-md-4 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                City State{" "}
                                <span className="login-danger"></span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={cityState}
                                onChange={(e) => setCityState(e.target.value)}
                              />
                            </div>
                          </div>

                          {/* City Pincode */}
                          <div className="col-12 col-md-4 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                City Pincode{" "}
                                <span className="login-danger"></span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={cityPincode}
                                onChange={(e) => setCityPincode(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 mb-2">
                            <div className="form-heading">
                              <h5>Primary Branch Details</h5>
                            </div>
                          </div>
                          {/* Branch Details Heading */}
                          <div className="col-12 mb-2">
                            <div className="form-heading">
                              <h5>Branch Details</h5>
                            </div>
                          </div>

                          {/* Branch Name */}
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Branch Name{" "}
                                <span className="login-danger"></span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={branchName}
                                onChange={(e) => setBranchName(e.target.value)}
                                placeholder=""
                              />
                            </div>
                          </div>

                          {/* Branch Address Line 1 */}
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Branch Address Line 1{" "}
                                <span className="login-danger"></span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={branchAddressLine1}
                                onChange={(e) =>
                                  setBranchAddressLine1(e.target.value)
                                }
                                placeholder=""
                              />
                            </div>
                          </div>

                          {/* Branch Address Line 2 */}
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Branch Address Line 2{" "}
                                <span className="login-danger"></span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={branchAddressLine2}
                                onChange={(e) =>
                                  setBranchAddressLine2(e.target.value)
                                }
                                placeholder=""
                              />
                            </div>
                          </div>

                          {/* Branch City */}
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Branch City{" "}
                                <span className="login-danger"></span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={branchCity}
                                onChange={(e) => setBranchCity(e.target.value)}
                                placeholder=""
                              />
                            </div>
                          </div>

                          {/* Branch State */}
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Branch State{" "}
                                <span className="login-danger"></span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={branchState}
                                onChange={(e) => setBranchState(e.target.value)}
                                placeholder=""
                              />
                            </div>
                          </div>

                          {/* Branch Pincode */}
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Branch Pincode{" "}
                                <span className="login-danger"></span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={branchPincode}
                                onChange={(e) =>
                                  setBranchPincode(e.target.value)
                                }
                                placeholder=""
                              />
                            </div>
                          </div>
                          {/* Branch Pincode */}
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Branch Phone No.{" "}
                                <span className="login-danger"></span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={isBranchphoneNumber}
                                onChange={(e) =>
                                    setIsBranchphoneNumber(e.target.value)
                                }
                                placeholder=""
                              />
                            </div>
                          </div>

                          {/* Branch WhatsApp Number */}
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group">
                              <label className="whatsapp-label">
                                Set as default WhatsApp number for branch?
                              </label>
                              <div className="status-toggle">
                                <input
                                  type="checkbox"
                                  id="branch_wp_number"
                                  className="check"
                                  checked={isBranchWpNumber}
                                  onChange={(e) =>
                                    setIsBranchWpNumber(e.target.checked)
                                  }
                                />
                                <label
                                  htmlFor="branch_wp_number"
                                  className="checktoggle"
                                >
                                  checkbox
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="steps-button text-end">
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Registration;
