/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import GeneralSettingsHeader from "../../GeneralSettingsHeader";
import OrganisationDropdown from "../../OrganisationDropdown";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { favicon } from "../../imagepath";
import Select from "react-select";
import { Table } from "antd";
import { itemRender, onShowSizeChange } from "../../Pagination";
import { invoice_list_data } from "../../GlobalData/TableData";
import {
  invoice_icon1,
  invoice_icon2,
  invoice_icon3,
  invoice_icon4,
  invoiceicon5,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
} from "../../imagepath";
import { DatePicker } from "antd";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Api from "../../../helper/api";
import AuthApi from "../../../helper/authApi";
import { loadingService } from "../../../helper/observer"
import { Modal } from "react-bootstrap";

const EditPermission = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const onChange = (date, dateString) => {};
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [menu, setMenu] = useState(false);
  const [date, setDate] = useState(new Date());
  const [name, setName] = useState("");
  const [userData, setUserData] = useState(null);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };


  // to EDIT Permissions
  const SubmitForm = async (e) => {
    e.preventDefault();

    if (!name) {
      toast.error("Please fill out all required fields.");
      return;
    }

    let payload = {
      name: name,
    };

    try {
      const { status } = await AuthApi.put(
        `${Api.updatepermissions}/${id}`,
        payload
      );
      if (status) {
        toast.success("Success");
        navigate("/addpermission");
      }
    } catch (error) {
      toast.error(error);
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };


  // to Edit Permissions
  const getPermissionsInfo = async () => {
    try {
      const response = await AuthApi.get(`${Api.permissionsinfo}/${id}`);
      const { data, message, status } = response;
      console.log(data);
      if (!data) {
        toast.success(message);
        if (status === 401) navigate("/login");
        return;
      }

      const permissions = data.data;
      setName(permissions.name);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPermissionsInfo();
  }, []);


  return (
    <>
      <div>
        <Header />
        <Sidebar activeClassName="usermanagement" />

        <div className="page-wrapper">
          {/* Page Content */}
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="index.html">Dashboard </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Settings</li>
                  </ul>
                </div>
              </div>
            </div>
            <OrganisationDropdown/>
            <GeneralSettingsHeader />{" "}
            {/* Header for General Settings Page only */}
            {/* Clinic Name Start */}
            {/* Clinic Name End */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table">
                  <div className="card-body p-4">
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="row align-items-center">
                          <div className="card invoices-tabs-card">
                            <div className="card-body card-body pt-0 pb-0">
                              <div className="permissionsnroles-main-tabs">
                                <div className="row align-items-center">
                                  <div className="col-lg-8 col-md-8">
                                    <div className="invoices-tabs">
                                      <ul>
                                        <li>
                                          <Link
                                            to="/addpermission"
                                            className="active"
                                          >
                                            Permissions
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/roleslist">Roles</Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <form onSubmit={SubmitForm}>
                            <div className="col-md-4">
                              <div className="form-group form-placeholder">
                                <label>
                                  Permission <span className="star-red"></span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <button
                                type="submit"
                                className="btn btn-primary me-2"
                              >
                                Add
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPermission;
