/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Table } from "antd";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Select from "react-select";
import { itemRender, onShowSizeChange } from "../Pagination";
import { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { DatePicker } from "antd";
import {
  blogimg10,
  blogimg12,
  blogimg2,
  blogimg5,
  blogimg6,
  blogimg7,
  blogimg8,
  imagesend,
  pdficon,
  pdficon2,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
} from "../imagepath";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { loadingService } from "../../helper/observer";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import { useNavigate } from "react-router-dom";

const Expenses = () => {
  const navigate = useNavigate();
  const [dropdownValue, setDropdownValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [expenselist, setExpenseList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [employee, setEmployee] = useState([
    { value: 1, label: "Select Employee Name" },
    { value: 2, label: "Bernardo James" },
    { value: 3, label: "Galaviz Lalema" },
    { value: 4, label: "Tarah Williams" },
  ]);
  const [payment, setPayment] = useState([
    { value: 1, label: "Select Paid by" },
    { value: 2, label: "PayPal" },
    { value: 3, label: "Cheque" },
    { value: 4, label: "Debit Card" },
  ]);
  const onChange = (date, dateString) => {};

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleDropdownChange = (value) => {
    setDropdownValue(value);
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };

  // To delete existing Entry
  const handleDelete = async () => {
    if (!deleteId) return;

    loadingService.next(true);
    try {
      const response = await AuthApi.delete(`${Api.deleteexpense}/${deleteId}`);
      loadingService.next(false);

      if (response) {
        toast.success("Deleted Successfully");
        setShowDelete(false);
        fetchData(); // Refresh doctor list
      }
    } catch (error) {
      loadingService.next(false);
      toast.error("An error occurred while deleting");
    }
  };

  const fetchData = async () => {
    try {
      const udata = localStorage.getItem("userdata");
      const data = JSON.parse(udata);
      loadingService.next(true);  

      const response = await AuthApi.get(
        `${Api.expenselist}/${data.user.organization_id}`);
      
      loadingService.next(false);
      
      const { data: responseData, status } = response.data;
    
      console.log("Response Data:", responseData); // Check the format of responseData

      setExpenseList(responseData.data);
    } catch (error) {
      console.error("Error fetching form list:", error);
      setExpenseList([]);
    }
  };
  const filteredExpenseList =Array.isArray(expenselist) 
    ? expenselist.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchQuery)
        )
      )
    : [];

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "Item",
      dataIndex: "item_name",
      sorter: (a, b) => a.itemName.length - b.itemName.length,
    },
    {
      title: "Purchase Date",
      dataIndex: "purchase_date",
      sorter: (a, b) => a.purchaseDate.length - b.purchaseDate.length,
    },
    {
      title: "Purchase From",
      dataIndex: "purchase_from",
    },
    {
      title: "Age",
      dataIndex: "age",
      sorter: (a, b) => a.age.length - b.age.length,
    },
    {
      title: "Purchase By",
      dataIndex: "purchase_by",
      sorter: (a, b) => a.purchaseBy.length - b.purchaseBy.length,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount.length - b.amount.length,
    },
    {
      title: "Paid By",
      dataIndex: "paid_by",
      sorter: (a, b) => a.paidBy.length - b.paidBy.length,
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
      sorter: (a, b) => a.paymentStatus.length - b.paymentStatus.length,
    },
    {
      title: "Other Info",
      dataIndex: "other_info",
      sorter: (a, b) => a.otherInfo.length - b.otherInfo.length,
    },
    {
      title: "Receipt",
      dataIndex: "receipt",
      sorter: (a, b) => a.receipt.length - b.receipt.length,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      // key: 'status',
      render: (text, record) => (
        <div>
          <Dropdown onSelect={handleDropdownChange}>
            {text === "Approved" && (
              <span className="custom-badge status-green">
                {text}
                <Dropdown.Toggle variant="">
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="New">New</Dropdown.Item>
                  <Dropdown.Item eventKey="Pending">Pending</Dropdown.Item>
                  <Dropdown.Item eventKey="Approved">Approved</Dropdown.Item>
                  <Dropdown.Item eventKey="Declined" >Declined</Dropdown.Item>
                </Dropdown.Menu>
              </span>
            )}
          </Dropdown>
          <Dropdown onSelect={handleDropdownChange}>
            {text === "Pending" && (
              <span className="custom-badge status-orange">
                {text}
                <Dropdown.Toggle variant="">
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="New">New</Dropdown.Item>
                  <Dropdown.Item eventKey="Pending">Pending</Dropdown.Item>
                  <Dropdown.Item eventKey="Approved">Approved</Dropdown.Item>
                  <Dropdown.Item eventKey="Declined" >Declined</Dropdown.Item>
                </Dropdown.Menu>
              </span>
            )}
          </Dropdown>
          <Dropdown onSelect={handleDropdownChange}>
            {text === "Declined" && (
              <span className="custom-badge status-pink">
                {text}
                <Dropdown.Toggle variant="">
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="New">New</Dropdown.Item>
                  <Dropdown.Item eventKey="Pending">Pending</Dropdown.Item>
                  <Dropdown.Item eventKey="Approved">Approved</Dropdown.Item>
                  <Dropdown.Item eventKey="Declined" >Declined</Dropdown.Item>
                </Dropdown.Menu>
              </span>
            )}
          </Dropdown>
          <Dropdown onSelect={handleDropdownChange}>
            {text === "New" && (
              <span className="custom-badge status-purple">
                {text}
                <Dropdown.Toggle variant="">
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="New">New</Dropdown.Item>
                  <Dropdown.Item eventKey="Pending">Pending</Dropdown.Item>
                  <Dropdown.Item eventKey="Approved">Approved</Dropdown.Item>
                  <Dropdown.Item eventKey="Declined" >Declined</Dropdown.Item>
                </Dropdown.Menu>
              </span>
            )}
          </Dropdown>
          <Dropdown onSelect={handleDropdownChange}>
            {text === "Rejected" && (
              <span className="custom-badge status-pink ">
                {text}
                <Dropdown.Toggle variant="">
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="New">New</Dropdown.Item>
                  <Dropdown.Item eventKey="Pending">Pending</Dropdown.Item>
                  <Dropdown.Item eventKey="Approved">Approved</Dropdown.Item>
                  <Dropdown.Item eventKey="Declined" >Declined</Dropdown.Item>
                </Dropdown.Menu>
              </span>
            )}
          </Dropdown>

        </div>
      ),
    },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (text, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  className="dropdown-item"
                  to={`/edit-expenses/${record.id}`}
                >
                  <i className="far fa-edit me-2" />
                  Edit
                </Link>

                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#delete_patient"
                  onClick={() => {
                    setShowDelete(true);
                    setDeleteId(record.id);
                  }}
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Header />
      <Sidebar id="menu-item7" id1="menu-items7" activeClassName="expenses" />
      <>
        <div>
          <div className="page-wrapper">
            <div className="content">
              {/* Page Header */}
              <div className="page-header">
                <div className="row">
                  <div className="col-sm-12">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#">Accounts</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <i className="feather-chevron-right">
                          <FeatherIcon icon="chevron-right" />
                        </i>
                      </li>
                      <li className="breadcrumb-item active">Expenses</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              <div className="row">
                <div className="col-sm-12">
                  <div className="card card-table show-entire">
                    <div className="card-body">
                      {/* Table Header */}
                      <div className="page-table-header mb-2">
                        <div className="row align-items-center">
                          <div className="col">
                            <div className="doctor-table-blk">
                              <h3>Expenses List</h3>
                              <div className="doctor-search-blk">
                                <div className="top-nav-search table-search-blk">
                                  <form>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search here"
                                      onChange={handleSearch}
                                    />
                                    <Link className="btn">
                                      <img src={searchnormal} alt="#" />
                                    </Link>
                                  </form>
                                </div>
                                <div className="add-group">
                                  <Link
                                    to="/add-expense"
                                    className="btn btn-primary add-pluss ms-2"
                                  >
                                    <img src={plusicon} alt="#" />
                                  </Link>
                                  <Link
                                    to="#"
                                    className="btn btn-primary doctor-refresh ms-2"
                                  >
                                    <img src={refreshicon} alt="#" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-auto text-end float-end ms-auto download-grp">
                            <Link to="#" className=" me-2">
                              <img src={pdficon} alt="#" />
                            </Link>
                            <Link to="#" className=" me-2">
                              <img src={pdficon2} alt="#" />
                            </Link>
                            <Link to="#" className=" me-2">
                              <img src={pdficon3} alt="#" />
                            </Link>
                            <Link to="#">
                              <img src={pdficon4} alt="#" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      {/* /Table Header */}
                      <div className="staff-search-table">
                        <form>
                          <div className="row">
                            <div className="col-12 col-md-6 col-xl-4">
                              <div className="form-group local-forms">
                                <label>Item Name</label>
                                <input className="form-control" type="text" />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4">
                              <div className="form-group local-forms">
                                <label>Purchased by</label>
                                <Select
                                  defaultValue={selectedOption}
                                  onChange={setSelectedOption}
                                  options={employee}
                                  menuPortalTarget={document.body}
                                  id="search-commodity"
                                  components={{
                                    IndicatorSeparator: () => null,
                                  }}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor: state.isFocused
                                        ? "none"
                                        : "2px solid rgba(46, 55, 164, 0.1);",
                                      boxShadow: state.isFocused
                                        ? "0 0 0 1px #2e37a4"
                                        : "none",
                                      "&:hover": {
                                        borderColor: state.isFocused
                                          ? "none"
                                          : "2px solid rgba(46, 55, 164, 0.1)",
                                      },
                                      borderRadius: "10px",
                                      fontSize: "14px",
                                      minHeight: "45px",
                                    }),
                                    dropdownIndicator: (base, state) => ({
                                      ...base,
                                      transform: state.selectProps.menuIsOpen
                                        ? "rotate(-180deg)"
                                        : "rotate(0)",
                                      transition: "250ms",
                                      width: "35px",
                                      height: "35px",
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4">
                              <div className="form-group local-forms">
                                <label>Paid by</label>
                                <Select
                                  defaultValue={selectedOption}
                                  onChange={setSelectedOption}
                                  options={payment}
                                  menuPortalTarget={document.body}
                                  id="search-commodity"
                                  components={{
                                    IndicatorSeparator: () => null,
                                  }}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor: state.isFocused
                                        ? "none"
                                        : "2px solid rgba(46, 55, 164, 0.1);",
                                      boxShadow: state.isFocused
                                        ? "0 0 0 1px #2e37a4"
                                        : "none",
                                      "&:hover": {
                                        borderColor: state.isFocused
                                          ? "none"
                                          : "2px solid rgba(46, 55, 164, 0.1)",
                                      },
                                      borderRadius: "10px",
                                      fontSize: "14px",
                                      minHeight: "45px",
                                    }),
                                    dropdownIndicator: (base, state) => ({
                                      ...base,
                                      transform: state.selectProps.menuIsOpen
                                        ? "rotate(-180deg)"
                                        : "rotate(0)",
                                      transition: "250ms",
                                      width: "35px",
                                      height: "35px",
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4">
                              <div className="form-group local-forms cal-icon">
                                <label>From</label>
                                <DatePicker
                                  className="form-control datetimepicker"
                                  onChange={onChange}
                                  suffixIcon={null}
                                  // placeholder='24/11/2022'
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4">
                              <div className="form-group local-forms cal-icon">
                                <label>To</label>
                                <DatePicker
                                  className="form-control datetimepicker"
                                  onChange={onChange}
                                  suffixIcon={null}
                                  // placeholder='24/11/2022'
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4">
                              <div className="doctor-submit">
                                <button
                                  type="submit"
                                  className="btn btn-primary submit-list-form me-2"
                                >
                                  Search
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="table-responsive">
                        <Table
                          className="table table-stripped table-hover datatable thead-light "
                          pagination={{
                            total: filteredExpenseList.length,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            // showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          columns={columns}
                          dataSource={filteredExpenseList}
                          rowSelection={rowSelection}
                          rowKey={(record) => record.id}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal show={showDelete} onHide={() => setShowDelete(false)}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h3>Are you sure want to delete this ?</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <img src={imagesend} alt="#" width={50} height={46} />
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setShowDelete(false)}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleDelete}
              >
                Send
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    </>
  );
};

export default Expenses;
