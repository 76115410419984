import React from "react";

class Api extends React.Component {

  static version = "v1";  // Keep versioning
  // Use the environment variable from the .env file
  static baseUrl = `${process.env.REACT_APP_API_URL}/${this.version}`;
  // static baseUrl = 'https://stagingapi.rxcarepath.com'; // Adjusted base URL to include "/api"
  

  // login 
  static loginUrl = `${this.baseUrl}/login`;
  static register = `${this.baseUrl}/register`;
  static forgetpassword = `${this.baseUrl}/forgetpassword`;
  

   // patient
   static addpatient = `${this.baseUrl}/patient`;
   static patientlist = `${this.baseUrl}/patients`;
   static patientInfo = `${this.baseUrl}/patient`;
   static patientDelete = `${this.baseUrl}/patient`;
   static patientUpdate = `${this.baseUrl}/patientupdate`;

    // Appointments 
    static appointmentlist = `${this.baseUrl}/appointments`;
    static addappointment = `${this.baseUrl}/appointment`;
    static editappointment = `${this.baseUrl}/appointment`;
    static appointmentInfo = `${this.baseUrl}/appointment`;
    static deleteappointment = `${this.baseUrl}/appointment`;
    
    // Appointments 
    // static deleteappointment = `${this.baseUrl}/deleteappointment`;
    // static custommessagesend = `${this.baseUrl}/custommessagesend`;

    //Addons
    static AddonsList = `${this.baseUrl}/addon`;
    static Addonpage = `${this.baseUrl}/addon`;
    static deleteaddons = `${this.baseUrl}/addon`;
    static updateaddon = `${this.baseUrl}/addon`;
    static addonsinfo = `${this.baseUrl}/addon`;
    // Treatment Master 
    static treatmentmasterlist = `${this.baseUrl}/treatmentmasterlist`;
    static addtreatmentmaster = `${this.baseUrl}/addtreatmentmaster`;
    static infotreatmentmaster = `${this.baseUrl}/treatmentmasterinfo`;
    static updatetreatmentmaster = `${this.baseUrl}/updatetreatmentmaster`;
    static deletetreatmentmaster = `${this.baseUrl}/deletetreatmentmaster`;

    // Medication Master 
    static medicationmasterlist = `${this.baseUrl}/medicationmasterlist`;
    static medicationmasterinfo = `${this.baseUrl}/medicationmasterinfo`;
    static deletemedicationmaster = `${this.baseUrl}/deletemedicationmaster`;
    static updatemedicationmaster = `${this.baseUrl}/updatemedicationmaster`;
    static addmedicationmaster = `${this.baseUrl}/addmedicationmaster`;

    // Medication Category Master
    static medicinecategorylist = `${this.baseUrl}/medicinecategorylist`;
    static medicinecategoryinfo = `${this.baseUrl}/medicinecategoryinfo`;
    static deletemedicinecategory = `${this.baseUrl}/deletemedicinecategory`;
    static updatemedicinecategory = `${this.baseUrl}/updatemedicinecategory`;
    static addmedicinecategory = `${this.baseUrl}/addmedicinecategory`;
    
    // Medication Category Master
    static consentlist = `${this.baseUrl}/consentlist`;
    static consentinfo = `${this.baseUrl}/consentinfo`;
    static deleteconsent = `${this.baseUrl}/deleteconsent`;
    static updateconsent = `${this.baseUrl}/updateconsent`;
    static addconsent = `${this.baseUrl}/addconsent`;

   // Doctor
   static doctorlist = `${this.baseUrl}/doctorlist`;
   static doctorInfo = `${this.baseUrl}/doctorinfo`;
   static doctorDelete = `${this.baseUrl}/deletedoctor`;
   static doctorUpdate = `${this.baseUrl}/updatedoctor`;
   static adddoctor = `${this.baseUrl}/adddoctor`;

   // Doctor
   static expenselist = `${this.baseUrl}/expenses`;  
   static expenseinfo = `${this.baseUrl}/expense`;
   static deleteexpense = `${this.baseUrl}/deleteexpense`;
   static updateexpense = `${this.baseUrl}/updateexpense`;
   static addexpense = `${this.baseUrl}/expense`;

   // Permissions
   static permissionslist = `${this.baseUrl}/permissions`;
   static permissionsinfo = `${this.baseUrl}/permissions`;
   static deletepermissions = `${this.baseUrl}/permissions`;
   static updatepermissions = `${this.baseUrl}/permissions`;
   static addpermissions = `${this.baseUrl}/permissions`;

   // Roles
   static roleslist = `${this.baseUrl}/role`;
   static rolesinfo = `${this.baseUrl}/role`;
   static deleteroles = `${this.baseUrl}/role`;
   static updateroles = `${this.baseUrl}/role`;
   static addroles = `${this.baseUrl}/role`;

   // Organization
   static organizationlist = `${this.baseUrl}/organization`;
   static organizationinfo = `${this.baseUrl}/organization`;
   static deleteorganization = `${this.baseUrl}/organization`;
   static updateorganization = `${this.baseUrl}/organization`;
   static addorganization = `${this.baseUrl}/organization`;

   // Users
   static userslist = `${this.baseUrl}/users`;
   static usersinfo = `${this.baseUrl}/users`;
   static deleteusers = `${this.baseUrl}/users`;
   static updateusers = `${this.baseUrl}/users`;
   static addusers = `${this.baseUrl}/users`;

   // branch
   static branchlist = `${this.baseUrl}/branchlist`;
   static branchinfo = `${this.baseUrl}/branch`;
   static deletebranch = `${this.baseUrl}/branch`;
   static updatebranch = `${this.baseUrl}/branch`;
   static addbranch = `${this.baseUrl}/branch`;
   
   static prescriptions = `${this.baseUrl}/prescriptions`;



  
}

export default Api;
