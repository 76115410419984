/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { DatePicker } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import toast from "react-hot-toast";
import { loadingService } from "../../helper/observer";

const AddPatients = () => {
  const navigate = useNavigate();
  const [first_name, setFirst_name] = useState("");
  const [middle_name, setMiddle_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [contact_number, setContact_Number] = useState("");
  const [email, setEmail] = useState("");
  const [date_of_birth, setDate_of_birth] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [patient_image, setPatient_image] = useState("");
  const [patientName, setPatientName] = useState("");
  const [whatsapp_notification, setWhatsapp_Notification] = useState("");
  const [userData, setUserData] = useState(null);
  const [branchid, setBranchID] = useState(null);
  const [checkbookAppointment, setCheckBookAppointment] = useState(false);
  const [bookAppointment, setBookAppointment] = useState("");
  const [DateOfBirth, setDateOfBirth] = useState("");
  const [imagePreview, setImagePreview] = useState(""); // For new image preview

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    setCheckBookAppointment(event.target.checked);
  };

  // Handle file selection and preview
  const handleFileChange = (setter, nameSetter, previewSetter) => (event) => {
    const file = event.target.files[0];
    setter(file);
    nameSetter(file ? file.name : "");

    // Create preview URL
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        previewSetter(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      previewSetter("");
    }
  };
  
  const SubmitForm = async (e) => {
    e.preventDefault();

    if (!first_name) {
      toast.error("Please fill out all required fields.");
      return;
    }

    const formData = new FormData();
    formData.append("branch_id", branchid);
    formData.append("first_name", first_name);
    formData.append("middle_name", middle_name);
    formData.append("last_name", last_name);
    formData.append("contact_number", contact_number);
    formData.append("email", email);
    formData.append("date_of_birth", date_of_birth);
    formData.append("age", age);
    formData.append("gender", gender);
    formData.append("address", address);
    formData.append("patient_image", patient_image);
    formData.append("whatsapp_notification", whatsapp_notification);

    try {
      loadingService.next(true);

      const { data } = await AuthApi.post(Api.addpatient, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      loadingService.next(false);

      if (data) {
        toast.success("Success");
        navigate("/patientslist");
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);

    setUserData(data.data);
  }, []);

  const fetchBranchId = async () => {
      const udata = localStorage.getItem("userdata");
      const data = JSON.parse(udata);
      setBranchID(data.user.branch_id); // Update branchID state
  };
  
  // useEffect to call fetchBranchId when the component mounts
  useEffect(() => {
    fetchBranchId();
  }, []);
  
  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="add-patient"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Patients </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Patient</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={SubmitForm}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Patinets Details</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              First Name <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="first_name"
                              placeholder=""
                              value={first_name}
                              onChange={(e) => setFirst_name(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Middle Name <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="middle_name"
                              placeholder=""
                              value={middle_name}
                              onChange={(e) => setMiddle_name(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Last Name <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="last_name"
                              placeholder=""
                              value={last_name}
                              onChange={(e) => setLast_name(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Mobile No.<span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="contact_number"
                              placeholder=""
                              maxLength={10}
                              value={contact_number}
                              onChange={(e) =>
                                setContact_Number(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Email <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="email"
                              name="email"
                              placeholder=""
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="form-group local-forms cal-icon">
                            <label>
                              Date Of Birth{" "}
                              <span className="login-danger"></span>
                            </label>
                            <DatePicker
                              className="form-control datetimepicker"
                              format="DD-MMM-YYYY"
                              selected={DateOfBirth}
                              onChange={(date) => setDateOfBirth(date)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Age <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="age"
                              placeholder=""
                              value={age}
                              onChange={(e) => setAge(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="form-group select-gender">
                            <label className="gen-label">
                              Gender <span className="login-danger"></span>
                            </label>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  className="form-check-input"
                                  value="male"
                                  checked={gender === "male"}
                                  onChange={(e) => setGender(e.target.value)}
                                />
                                Male
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  className="form-check-input"
                                  value="female"
                                  checked={gender === "female"}
                                  onChange={(e) => setGender(e.target.value)}
                                />
                                Female
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12">
                          <div className="form-group local-forms">
                            <label>
                              Address <span className="login-danger"></span>
                            </label>
                            <textarea
                              className="form-control"
                              rows={3}
                              cols={30}
                              name="address"
                              defaultValue={""}
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-top-form">
                            <label className="local-top">Patient Image</label>
                            <div className="settings-btn upload-files-avator">
                              <input
                                type="file"
                                accept="image/*"
                                name="patient_image"
                                id="patient_image"
                                className="hide-input"
                                onChange={handleFileChange(
                                  setPatient_image,
                                  setPatientName,
                                  setImagePreview
                                )}
                              />
                              <label htmlFor="patient_image" className="upload">
                                Choose File
                              </label>
                            </div>
                            {/* Display file name */}
                            <p>
                              <strong>Image:</strong>{" "}
                              {patientName || "No file chosen"}
                            </p>

                            {/* Conditionally show the image */}
                            {imagePreview ? (
                              <div className="image-preview">
                                <img
                                  src={imagePreview}
                                  alt="Selected Image Preview"
                                  style={{
                                    maxWidth: "15%",
                                    height: "auto",
                                    marginTop: "10px",
                                  }}
                                />
                              </div>
                            ) : (
                              <p>No image uploaded yet.</p>
                            )}
                          </div>
                        </div>
                        
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group select-gender">
                            <label className="gen-label">
                              WhatsApp Notification{" "}
                              <span className="login-danger"></span>
                            </label>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="whatsapp"
                                  value="Yes"
                                  className="form-check-input"
                                  checked={whatsapp_notification === "Yes"}
                                  onChange={(e) =>
                                    setWhatsapp_Notification(e.target.value)
                                  }
                                />
                                Yes
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="whatsapp"
                                  value="No"
                                  className="form-check-input"
                                  checked={whatsapp_notification === "No"}
                                  onChange={(e) =>
                                    setWhatsapp_Notification(e.target.value)
                                  }
                                />
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group select-gender">
                              <label className="gen-label">
                                Book Appointment{" "}
                                <span className="login-danger"></span>
                              </label>
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    type="checkbox"
                                    name="book_appointment"
                                    className="form-check-input"
                                    checked={checkbookAppointment}
                                    onChange={handleCheckboxChange}
                                  />
                                  Click Here
                                </label>
                              </div>
                            </div>
                          </div>
                          {/* Conditionally render the address field based on checkbox state */}
                          {checkbookAppointment && (
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Book Appointment{" "}
                                  <span className="login-danger"></span>
                                </label>

                                <DatePicker
                                  className="form-control datetimepicker"
                                  format="DD-MMM-YYYY"
                                  selected={bookAppointment}
                                  onChange={(date) => setBookAppointment(date)}
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              onClick={() => navigate("/patientslist")}
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default AddPatients;
