/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React from "react";
import { useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useState } from "react"; 
import { Link } from "react-router-dom";
import {
  blogimg10,
  blogimg12,
  blogimg2,
  blogimg6,
  blogimg8,
  imagesend,
  pdficon,
  pdficon2,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
} from "../imagepath";
import { Table } from "antd";
import { loadingService } from "../../helper/observer";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import { DatePicker, Space } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {onShowSizeChange,itemRender}from  '../Pagination'
import { Modal } from "react-bootstrap";

// list  on organazation function
const BranchList = () => {
  const navigate = useNavigate();
  const [showDelete, setShowDelete] = useState(false);
  const [showOrganisationid, setShowOrganisationid] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [branchlist, setbranchlist] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [fromDate, setFromDate] = useState(null); // State for "from" date
  const [toDate, setToDate] = useState(null); // State for "to" date

  
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };
  
  const handleDelete = async () => {
    if (!showOrganisationid) return;

    loadingService.next(true);
    try {
      const response = await AuthApi.delete(
        `${Api.deletebranch}/${showOrganisationid}`
      );
      loadingService.next(false);

      if (response) {
        toast.success("Deleted Successfully");
        setShowDelete(false);
        fetchData();
      }
    } catch (error) {
      loadingService.next(false);
      toast.error("An error occurred while deleting");
    }
  };
  const applyFilter = () => {
    if (!fromDate && !toDate) return;
    // fetchData(true);
  };

  const resetFilter = () => {
    setFromDate(null);
    setToDate(null);
    // fetchData();
  };

  const columns = [
    {
      title: "Sr No.",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Branch Name",
      dataIndex: "branch_name",
      sorter: (a, b) => a.branch_name.length - b.branch_name.length
    },
    {
      title: "Mobile No",
      dataIndex: "phone_number",
      sorter: (a, b) => a.phone_number.length - b.phone_number.length,
    },
    {
        title: "default_wp_number",
        dataIndex: "phone_number",
        sorter: (a, b) => a.phone_number.length - b.phone_number.length,
      },
      {
        title: "Organization ID",
        dataIndex: "organization_id",
        sorter: (a, b) => a.organization_id.length - b.organization_id.length,
      },
      {
        title: "Address 1",
        dataIndex: "address_line1",
        sorter: (a, b) => a.address_line1.length - b.address_line1.length,
      },
      {
        title: "Address 2",
        dataIndex: "address_line2",
        sorter: (a, b) => a.address_line2.length - b.address_line2.length,
      },
      {
        title: "City",
        dataIndex: "city",
        sorter: (a, b) => a.city.length - b.city.length,
      },
      {
        title: "State",
        dataIndex: "state",
        sorter: (a, b) => a.state.length - b.state.length,
      },
      {
        title: "Postal Code",
        dataIndex: "postal_code",
        sorter: (a, b) => a.postal_code.length - b.postal_code.length,
      },
      {
        title: "Country",
        dataIndex: "country",
        sorter: (a, b) => a.country.length - b.country.length,
      },
    {
      title: "Actions",
      dataIndex: "",
      render: (text, record) => (
        <>
        <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle ms-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link className="dropdown-item" 
                to={`/editbranch/${record.id}`}>
                  <i className="far fa-edit me-2" />
                  Edit
                </Link>
                <Link
                className="dropdown-item"
                  onClick={() => {
                    setShowDelete(true);
                    setShowOrganisationid(record.id);
                  }}
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
              </div>
            </div>
          </div>

        </>
      ),
    },
  ];
  // fetch data

  const fetchData = async () => {
    try {
      const udata = localStorage.getItem("userdata");
      const data = JSON.parse(udata);
      const response = await AuthApi.get(`${Api.branchlist}/${data.user.organization_id}`);
  
      const { data: responseData, message, status } = response.data;
  
      console.log("Response Data:", responseData); // Check the format of responseData
  
  
      setbranchlist(responseData.data);
    } catch (error) {
      console.error("Error fetching branch list:", error);
      setbranchlist([]); // Reset on error as well
    }
  };
  const filteredbranchlist = Array.isArray(branchlist)
  ? branchlist.filter((item) =>
      Object.values(item).some((value) => {
        // Ensure that both the value and searchQuery are strings
        return (
          typeof value === "string" &&
          typeof searchQuery === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase()) // Ignore case
        );
      })
    )
  : [];

useEffect(() => {
  fetchData();
}, []);



  return (
    <>
      <Header />
      <Sidebar
        id="menu-item7"
        id1="menu-items7"
        activeClassName="invoice-list"
      />
      <>
        <div>
          <div className="page-wrapper">
            <div className="content">
              {/* Page Header */}
              <div className="page-header">
                <div className="row">
                  <div className="col-sm-12">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                       <Link to="#">Branches</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <i className="feather-chevron-right">
                          <FeatherIcon icon="chevron-right" />
                        </i>
                      </li>
                      <li className="breadcrumb-item active">Add Branch</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              <div className="row">
                <div className="col-sm-12">
                  <div className="card card-table show-entire">
                    <div className="card-body">
                      {/* Table Header */}
                      <div className="page-table-header mb-2">
                        <div className="row align-items-center">
                          <div className="col">
                            <div className="doctor-table-blk">
                              <h3>Branches</h3>
                              <div className="doctor-search-blk">
                                <div className="top-nav-search table-search-blk">
                                  <form>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search here"
                                      onChange={handleSearch}
                                    />
                                   <Link className="btn">
                                      <img src={searchnormal} alt="#" />
                                    </Link>
                                  </form>
                                </div>
                                <div className="add-group">
                                  <Link
                                    to="/addbranch"
                                    className="btn btn-primary add-pluss ms-2"
                                  >
                                    <img src={plusicon} alt="#" />
                                  </Link>
                                 <Link
                                    to="#"
                                    className="btn btn-primary doctor-refresh ms-2"
                                  >
                                    <img src={refreshicon} alt="#" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-auto text-end float-end ms-auto download-grp">
                           <Link to="#" className=" me-2">
                              <img src={pdficon} alt="#"/>
                            </Link>
                           <Link to="#" className=" me-2">
                              <img src={pdficon2} alt="#" />
                            </Link>
                           <Link to="#" className=" me-2">
                              <img src={pdficon3} alt="#" />
                            </Link>
                           <Link to="#">
                              <img src={pdficon4} alt="#" />
                            </Link>
                          </div>
                          
                        </div>
                      </div>
                      {/* <div className="row">
                      <div className="col-md-6 d-inline d-md-flex">
                        <div className="date-picker-container d-inline d-md-flex">
                          <DatePicker
                            selected={fromDate}
                            onChange={(date) => setFromDate(date)}
                            placeholder="Select From Date"
                            className="date-picker-input ms-3 me-3"
                          />
                          <DatePicker
                            selected={toDate}
                            onChange={(date) => setToDate(date)}
                            placeholder="Select To Date"
                            className="date-picker-input"
                          />
                        </div>
                        <div className="col ms-2 d-md-none"></div>{" "}
                        {/* Add this to force the button to next line on mobile */}
                        {/* <div className="col-md-6 d-md-inline d-flex justify-content-md-start justify-content-center align-items-md-center mt-2 mt-md-0 ms-2">
                          <button
                            className="btn btn-primary"
                            style={{ marginRight: "10px" }}
                            onClick={applyFilter}
                            type="button"
                          >
                            Apply
                          </button>
                          <button
                            className="btn btn-light"
                            onClick={resetFilter}
                            type="button"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div> */} 
                      {/* /Table Header */}
                      
                      <div className="table-responsive">
                      <Table
                        pagination={{
                          total: filteredbranchlist.length,
                          showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          // showSizeChanger: true,
                           onShowSizeChange: onShowSizeChange,
                           itemRender: itemRender,
                        }}
                        columns={columns}
                        dataSource={filteredbranchlist}
                        rowSelection={rowSelection}
                        rowKey={(record) => record.id}
                      />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <Modal show={showDelete} onHide={() => setShowDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Are you sure want to delete this ?</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={imagesend} alt="#" width={50} height={46} />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setShowDelete(false)}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleDelete} // Call the delete function here
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BranchList;
