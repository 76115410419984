/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Header from "../Header";
import GeneralSettingsHeader from "../GeneralSettingsHeader";
import OrganisationDropdown from "../OrganisationDropdown";
import Sidebar from "../Sidebar";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { favicon } from "../imagepath";
import Select from "react-select";

const AppSettings = () => {
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [option, setOption] = useState([
    { value: 1, label: "Select City" },
    { value: 2, label: "California" },
    { value: 3, label: "Tasmania" },
    { value: 4, label: "Auckland" },
    { value: 5, label: "Marlborough" },
  ]);
  const [country, setCountry] = useState([
    { value: 1, label: "Select City" },
    { value: 2, label: "India" },
    { value: 3, label: "London" },
    { value: 4, label: "USA" },
    { value: 5, label: "French" },
  ]);
  return (
    <div>
      <Header />
      <Sidebar activeClassName="setting" />

      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="index.html">Dashboard </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">App Settings</li>
                </ul>
              </div>
            </div>
          </div>
          <OrganisationDropdown/>
          <GeneralSettingsHeader />{" "}
          {/* Header for General Settings Page only */}
          <div className="row">
            <div className="col-md-8">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">App Settings</h5>
                </div>
                <div className="card-body pt-0">
                  <form>
                    <div className="settings-form">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Company Name <span className="star-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Company Name"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <p className="settings-label">
                              Logo <span className="star-red">*</span>
                            </p>
                            <div className="settings-btn">
                              <input
                                type="file"
                                accept="image/*"
                                name="image"
                                id="file"
                                // onchange="loadFile(event)"
                                className="hide-input"
                              />
                              <label htmlFor="file" className="upload">
                                <i className="feather-upload">
                                  <FeatherIcon icon="upload" />
                                </i>
                              </label>
                            </div>
                            <h6 className="settings-size">
                              Recommended image size is{" "}
                              <span>150px x 150px</span>
                            </h6>
                            <div
                              className="upload-images"
                              style={{ display: show ? "none" : "" }}
                            >
                              <img src={favicon} alt="Image" />
                              <Link to="#" className="btn-icon logo-hide-btn">
                                <i
                                  className="feather-x-circle crossmark"
                                  onClick={() => setShow((s) => !s)}
                                >
                                  <FeatherIcon icon="x-circle" />
                                </i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Contact <span className="star-red"></span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Contact"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Email <span className="star-red"></span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Email"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-0">
                        <div className="settings-btns">
                          <button
                            type="submit"
                            className="border-0 btn btn-primary btn-gradient-primary btn-rounded"
                          >
                            Update
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-secondary btn-rounded"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppSettings;
