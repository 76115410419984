import React from 'react'
import { Link } from "react-router-dom";

const ClinicSettingsHeader = () => {
  return (
    <div>
      {/* /Page Header */}
      <div className="settings-menu-links">
            <ul className="nav nav-tabs menu-tabs">
              <li className="nav-item active">
                <Link className="nav-link " to="/clinicsettings">
                  General Settings
                </Link>
              </li>
              <li className="nav-item ">
                <Link className="nav-link" to="/clinicsetting_userlist">
                  User Management
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/clinicsetting_addpermission">
                  Roles & Permissions
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/ClinicSetting_whatsappservices">
                Communication Settings
                </Link>
              </li>
            </ul>
          </div>
    </div>
  )
}

export default ClinicSettingsHeader
