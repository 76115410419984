/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
} from "../imagepath";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { loadingService } from "../../helper/observer";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";

const PatientsList = () => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [patientlist, setPatientList] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [PatientId, setPatientId] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1, // Start at page 1
    pageSize: 10, // Number of items per page
    total: 0, // This will be set based on the total patients from the server
  });
  
  // Define search and ordering states
  const [searchTerm, setSearchTerm] = useState(''); // Initialize search term
  const [orderColumn, setOrderColumn] = useState('first_name'); // Default order column
  const [orderDirection, setOrderDirection] = useState('asc'); // Default order direction

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const handleDelete = async () => {
    if (!PatientId) return;

    loadingService.next(true);
    try {
      const response = await AuthApi.delete(`${Api.patientDelete}/${PatientId}`);
      loadingService.next(false);

      if (response) {
        toast.success("Deleted Successfully");
        setShowDelete(false);
        fetchData(); // Refresh doctor list
      }
    } catch (error) {
      loadingService.next(false);
      toast.error("An error occurred while deleting");
    }
  };

  const fetchData = async (page, pageSize) => {
    try {
      const udata = localStorage.getItem("userdata");
      const data = JSON.parse(udata);
      loadingService.next(true);
  
  // Default order direction; can be changed as per requirement
      const organizationid  = data.user.organization_id;
  
      // Construct the URL with all parameters
      const response = await AuthApi.get(`${Api.patientlist}/${organizationid }?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}&orderColumn=${orderColumn}&orderDirection=${orderDirection}`);
      
      loadingService.next(false);
  
      const { data: responseData, status } = response.data;
      if (responseData) {
        const { data: patients, total, current_page, per_page } = responseData;
  
        // Update the table's pagination state
        setPagination({
          current: current_page,
          pageSize: per_page,
          total: total,
        });
      } else {
        if (status === 401) navigate("/");
        return;
      }
  
      const patients = Array.isArray(responseData.data) ? responseData.data : [];
      
      // Filter active and non-deleted patients
      const filteredPatients = patients.filter(patient => patient.is_active && !patient.is_deleted);
      
      setPatientList(filteredPatients);
    } catch (error) {
      console.error("Error fetching patient list:", error);
      loadingService.next(false);
    }
  };
  
  // Example of how to use fetchData with dynamic parameters
  useEffect(() => {
    fetchData(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize, searchTerm, orderColumn, orderDirection]);

  

  // Handle pagination changes
  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    fetchData(current, pageSize);
  };

  // Ensure doctorlist is available before applying filter
  const filteredPatientList = patientlist
    ? patientlist.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchQuery)
        )
      )
    : [];

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      sorter: (a, b) => a.first_name.length - b.first_name.length,
    },
    {
      title: "Last Name",
      dataIndex: "middle_name",
      sorter: (a, b) => a.middle_name.length - b.middle_name.length,
    },
    {
      title: "Mobile No.",
      dataIndex: "last_name",
      sorter: (a, b) => a.last_name.length - b.last_name.length,
    },
    {
      title: "Contact Number",
      dataIndex: "contact_number",
      sorter: (a, b) => a.contact_number.length - b.contact_number.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Date of Birth",
      dataIndex: "dob",
      sorter: (a, b) => a.dob.length - b.dob.length,
    },
    {
      title: "Age",
      dataIndex: "age",
      sorter: (a, b) => a.age.length - b.age.length,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: (a, b) => a.gender.length - b.gender.length,
    },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (text, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  className="dropdown-item"
                  to={`/editpatients/${record.id}`}
                >
                  <i className="far fa-edit me-2" />
                  Edit
                </Link>
                <Link
                  className="dropdown-item"
                  onClick={() => {
                    setShowDelete(true);
                    setPatientId(record.id);
                  }}
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="patient-list"
      />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Patients </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">Patients List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Patients List</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              <form>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                  onChange={handleSearch}
                                />
                                <Link className="btn">
                                  <img src={searchnormal} alt="#" />
                                </Link>
                              </form>
                            </div>
                            <div className="add-group">
                              <Link
                                to="/addpatients"
                                className="btn btn-primary add-pluss ms-2"
                              >
                                <img src={plusicon} alt="#" />
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        <Link to="#" className=" me-2">
                          <img src={pdficon} alt="#" />
                        </Link>
                        <Link to="#" className=" me-2"></Link>
                        <Link to="#" className=" me-2">
                          <img src={pdficon3} alt="#" />
                        </Link>
                        <Link to="#">
                          <img src={pdficon4} alt="#" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      pagination={{
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: pagination.total,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      }}
                      onChange={handleTableChange}
                      columns={columns}
                      dataSource={filteredPatientList}
                      rowSelection={rowSelection}
                      rowKey={(record) => record.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showDelete} onHide={() => setShowDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Are you sure want to delete this ?</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={imagesend} alt="#" width={50} height={46} />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setShowDelete(false)}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleDelete} // Call the delete function here
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PatientsList;
