/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import GeneralSettingsHeader from "../../GeneralSettingsHeader";
import OrganisationDropdown from "../../OrganisationDropdown";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

const EditUser = () => {
  const navigate = useNavigate();
  const [permissionsList, setPermissionsList] = useState([]); // This would eventually hold permissions from the API
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [name, setName] = useState("");
  const [showpermissions, setShowPermissions] = useState(false);
  const [userData, setUserData] = useState(null);

  const handlePermissionsCheckbox = (event) => {
    setShowPermissions(event.target.checked);
  };

  const handleCheckboxChange = (e, permission) => {
    if (e.target.checked) {
      setSelectedPermissions([...selectedPermissions, permission.id]);
    } else {
      setSelectedPermissions(selectedPermissions.filter(id => id !== permission.id));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Collect data from form inputs and selected permissions
    const formData = {
      name,
      selectedPermissions,
      // Additional data can be added here, e.g., email, role, etc.
    };
    console.log(formData);
    // Submit form logic goes here (e.g., sending data to an API)
  };

  return (
    <div>
      <Header />
      <Sidebar activeClassName="usermanagement" />

      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="index.html">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Settings</li>
                </ul>
              </div>
            </div>
          </div>
          <OrganisationDropdown /> {/* Fetches organisation Name */}
          <GeneralSettingsHeader /> {/* Header for General Settings Page only */}
          
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body p-4">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title">Edit User</h5>
                        </div>
                        <div className="card-body pt-0">
                          <form onSubmit={handleSubmit}>
                            <div className="settings-form">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      User Name <span className="star-red">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter User Name"
                                      value={name}
                                      onChange={(e) => setName(e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      Email <span className="star-red">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Email"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Mobile Number</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Mobile Number"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Password</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Password"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Role</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Role"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Organisation</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Organisation"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="card-header d-flex justify-content-between align-items-center">
                                    <h5 className="card-title">WhatsApp Services</h5>
                                    <div className="status-toggle d-flex justify-content-between align-items-center">
                                      <input
                                        type="checkbox"
                                        id="whatsapp_status"
                                        className="check"
                                        defaultChecked={true}
                                      />
                                      <label htmlFor="whatsapp_status" className="checktoggle">checkbox</label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="card-header d-flex justify-content-between align-items-center">
                                    <h5 className="card-title">Is Active</h5>
                                    <div className="status-toggle d-flex justify-content-between align-items-center">
                                      <input
                                        type="checkbox"
                                        id="is_active_status"
                                        className="check"
                                        defaultChecked={true}
                                      />
                                      <label htmlFor="is_active_status" className="checktoggle">checkbox</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="page-table-header mb-2">
                                <div className="row align-items-center">
                                  <div className="col-md-4">
                                    <div className="d-flex align-items-center mb-4">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="selectAllPermissions"
                                        style={{ marginRight: "16px" }}
                                        checked={showpermissions}
                                        onChange={handlePermissionsCheckbox}
                                      />
                                      <h5 className="card-title mb-0">Permissions Customization</h5>
                                    </div>
                                    {showpermissions && (
                                      <div className="form-group form-placeholder">
                                        <label>
                                          Role Name <span className="star-red">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder=""
                                          value={name}
                                          onChange={(e) => setName(e.target.value)}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {showpermissions && (
                                <div className="page-table-header mb-2">
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="form-group select-gender">
                                        <div className="row">
                                          <div className="col-2">
                                            <div className="form-check">
                                              <label className="form-check-label">
                                                <input
                                                  type="checkbox"
                                                  name="permission1"
                                                  className="form-check-input"
                                                  checked={selectedPermissions.includes(1)} // Example selected state
                                                  onChange={(e) =>
                                                    handleCheckboxChange(e, {
                                                      id: 1,
                                                      name: "Permission 1",
                                                    })
                                                  }
                                                />
                                                Permission 1
                                              </label>
                                            </div>
                                          </div>
                                          {/* Add more permissions as needed */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="form-group mb-0">
                                <div className="settings-btns">
                                  <button
                                    type="submit"
                                    className="border-0 btn btn-primary btn-gradient-primary btn-rounded"
                                  >
                                    Update
                                  </button>
                                  &nbsp;&nbsp;
                                  <button
                                    type="button"
                                    className="btn btn-secondary btn-rounded"
                                    onClick={() => navigate(-1)} // Redirect on cancel
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    {/* End of form */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End of card */}
        </div>
        {/* End of content */}
      </div>
    </div>
  );
};

export default EditUser;
