/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link , useParams} from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { favicon, imagesend } from "../imagepath";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { DatePicker } from "antd";
import Select from "react-select";
import toast from "react-hot-toast";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";


const EditDoctor = () => {
  const { id } = useParams(); 
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [mob_no, setMob_no] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [qualifications, setQualifications] = useState("");
  const [color, setColor] = useState("");
  const [address, setAddress] = useState("");
  const [signature, setSignature] = useState(null);
  const [stamp, setStamp] = useState(null);
  const [signatureName, setSignatureName] = useState("");
  const [stampName, setStampName] = useState("");
  const [signaturepreview, setSignaturepreview] = useState("");
  const [stamppreview, setstampPreview] = useState("");
  const [userData, setUserData] = useState(null);


  // Handle file selection and preview
  const handleFileChange = (setter, nameSetter, previewSetter) => (event) => {
    const file = event.target.files[0];
    setter(file);
    nameSetter(file ? file.name : "");

    // Create preview URL
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        previewSetter(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      previewSetter("");
    }
  };
  
  const getDoctorInfo = async () => {
    try {
      const response = await AuthApi.get(`${Api.doctorInfo}/${id}`);
      const { data, message, status } = response;
      if (!data) {
        toast.success(message);
        if (status === 401) navigate("/login");
        return;
      }

      const doctor = data.data;
      setName(doctor.name);
      setMob_no(doctor.mob_no);
      setGender(doctor.gender.charAt(0).toUpperCase() + doctor.gender.slice(1)); // Capitalizes first letter
      setAge(doctor.age);
      setQualifications(doctor.qualifications);
      setColor(doctor.color);
      setAddress(doctor.address);
      setSignature(doctor.signature);
      setStamp(doctor.stamp);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDoctorInfo();
  }, []);

  const handleSubmit = async (e) => {
  e.preventDefault();

  const formData = new FormData();
  formData.append('name', name);
  formData.append('mob_no', mob_no);
  formData.append('gender', gender);
  formData.append('age', age);
  formData.append('qualifications', qualifications);
  formData.append('color', color);
  formData.append('address', address);

  // Append files only if they are selected
  if (signature) formData.append('signature', signature);
  if (stamp) formData.append('stamp', stamp);

  try {
    const { status } = await AuthApi.post(`${Api.doctorUpdate}/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (status === 200) {
      toast.success("Doctor updated successfully");
      navigate("/doctorlist");
    }
  } catch (error) {
    toast.error("An error occurred while submitting the form.");
    console.error("Error occurred while submitting form:", error);
  }
};


  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item1"
        id1="menu-items1"
        activeClassName="edit-doctor"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Doctors </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Edit Doctor</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Doctor Details</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Name <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Mobile No. <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              maxLength={10}
                              value={mob_no}
                              onChange={(e) => setMob_no(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group select-gender">
                            <label className="gen-label">
                              Gender<span className="login-danger"></span>
                            </label>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  value="Male"
                                  className="form-check-input"
                                  checked={gender === "Male"}
                                  onChange={(e) => setGender(e.target.value)}
                                />
                                Male
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  value="Female"
                                  className="form-check-input"
                                  checked={gender === "Female"}
                                  onChange={(e) => setGender(e.target.value)}
                                />
                                Female
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Age <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={age}
                              onChange={(e) => setAge(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Degree <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={qualifications}
                              onChange={(e) => setQualifications(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Color <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="color"
                              value={color}
                              onChange={(e) => setColor(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-sm-12">
                          <div className="form-group local-forms">
                            <label>
                              Address <span className="login-danger"></span>
                            </label>
                            <textarea
                              className="form-control"
                              rows={3}
                              cols={30}
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-top-form">
                            <label className="local-top">Signature</label>
                            <div className="settings-btn upload-files-avator">
                              <input
                                type="file"
                                accept="image/*"
                                name="signature"
                                id="signature"
                                className="hide-input"
                                onChange={handleFileChange(
                                  setSignature,
                                  setSignatureName,
                                  setSignaturepreview
                                )}
                              />
                              <label htmlFor="patient_image" className="upload">
                                Choose File
                              </label>
                            </div>
                            {/* Display file name */}
                            <p>
                              <strong>Image:</strong>{" "}
                              {signatureName || "No file chosen"}
                            </p>

                            {/* Conditionally show the image */}
                            {signaturepreview ? (
                              <div className="image-preview">
                                <img
                                  src={signaturepreview}
                                  alt="Selected Image Preview"
                                  style={{
                                    maxWidth: "15%",
                                    height: "auto",
                                    marginTop: "10px",
                                  }}
                                />
                              </div>
                            ) : (
                              <p>No image uploaded yet.</p>
                            )}
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-top-form">
                            <label className="local-top">Stamp</label>
                            <div className="settings-btn upload-files-avator">
                              <input
                                type="file"
                                accept="image/*"
                                name="stamp"
                                id="stamp"
                                className="hide-input"
                                onChange={handleFileChange(
                                  setStamp,
                                  setStampName,
                                  setstampPreview
                                )}
                              />
                              <label htmlFor="patient_image" className="upload">
                                Choose File
                              </label>
                            </div>
                            {/* Display file name */}
                            <p>
                              <strong>Image:</strong>{" "}
                              {stampName || "No file chosen"}
                            </p>

                            {/* Conditionally show the image */}
                            {stamppreview ? (
                              <div className="image-preview">
                                <img
                                  src={stamppreview}
                                  alt="Selected Image Preview"
                                  style={{
                                    maxWidth: "15%",
                                    height: "auto",
                                    marginTop: "10px",
                                  }}
                                />
                              </div>
                            ) : (
                              <p>No image uploaded yet.</p>
                            )}
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary cancel-form"
                              onClick={() => navigate("/doctorlist")}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default EditDoctor;
