/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import GeneralSettingsHeader from "../../GeneralSettingsHeader";
import OrganisationDropdown from "../../OrganisationDropdown";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { favicon } from "../../imagepath";
import Select from "react-select";
import { Table } from "antd";
import { itemRender, onShowSizeChange } from "../../Pagination";
import { invoice_list_data } from "../../GlobalData/TableData";
import {
  invoice_icon1,
  invoice_icon2,
  invoice_icon3,
  invoice_icon4,
  invoiceicon5,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
} from "../../imagepath";
import { DatePicker } from "antd";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Api from "../../../helper/api";
import AuthApi from "../../../helper/authApi";
import { loadingService } from "../../../helper/observer";
import { Modal } from "react-bootstrap";

const RolesList = () => {
  const navigate = useNavigate();
  //list
  const [showDelete, setShowDelete] = useState(false);
  const [rolesId, setRolesId] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [rolesList, setRolesList] = useState([]);

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Sr No.",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Role Name",
      dataIndex: "name",
      sorter: (a, b) => a.Invoice_Id.length - b.Invoice_Id.length,
    },
    {
      title: "Action",
      dataIndex: "",
      render: (text, record) => (
        <div className="text-end">
          <Link
            className="btn btn-sm btn-white text-success me-2"
            to={`/editrole/${record.id}`}
          >
            <i className="far fa-edit me-1" />
            Edit
          </Link>
          <Link className="btn btn-sm btn-white text-danger" to="#"
          
          onClick={() => {
            setShowDelete(true);
            setRolesId(record.id);
          }}
          >
            <i className="far fa-trash-alt me-1" />
            Delete
          </Link>
        </div>
      ),
    },
  ];


  // To Get List in the Table
  const fetchData = async () => {
    try {
      const udata = localStorage.getItem("userdata");
      const data = JSON.parse(udata);
      loadingService.next(true);
      const response = await AuthApi.get(`${Api.roleslist}`);
      loadingService.next(false);

      const { data: responseData, message, status } = response.data;
      if (!responseData) {
        if (status === 401) navigate("/");
        // Handle other error cases
        return;
      }
      setRolesList(responseData);
    } catch (error) {
      console.error("Error fetching form list:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Ensure treatmentList is available before applying filter
  const filteredrolesList = rolesList
    ? rolesList.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchQuery)
        )
      )
    : [];

  useEffect(() => {
    fetchData();
  }, []);

  //to Delete permissions
  const handleDelete = async () => {
    if (!rolesId) return;

    loadingService.next(true);
    try {
      const response = await AuthApi.delete(
        `${Api.deleteroles}/${rolesId}`
      );
      loadingService.next(false);

      if (response) {
        toast.success("Deleted Successfully");
        setShowDelete(false);
        fetchData(); // Refresh list
      }
    } catch (error) {
      loadingService.next(false);
      toast.error("An error occurred while deleting");
    }
  };

  return (
    <>
    <div>
      <Header />
      <Sidebar activeClassName="appsettings" />

      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="index.html">Dashboard </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Settings</li>
                </ul>
              </div>
            </div>
          </div>
          <OrganisationDropdown/>
          <GeneralSettingsHeader />{" "}
          {/* Header for General Settings Page only */}
          {/* Clinic Name Start */}
          
          {/* Clinic Name End */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body p-4">
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                    <div className="card invoices-tabs-card">
                        <div className="card-body card-body pt-0 pb-0">
                            <div className="permissionsnroles-main-tabs">
                                <div className="row align-items-center">
                                    <div className="col-lg-8 col-md-8">
                                        <div className="invoices-tabs">
                                            <ul>
                                                <li><Link to="/addpermission">Permissions</Link></li>
                                                <li><Link to="/roleslist" className="active">Roles</Link></li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Roles List</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              <form>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                  onChange={handleSearch}
                                />
                                <Link className="btn">
                                  <img src={searchnormal} alt="#" />
                                </Link>
                              </form>
                            </div>
                            <div className="add-group">
                              <Link
                                to="/addroles"
                                className="btn btn-primary add-pluss ms-2"
                              >
                                <img src={plusicon} alt="#" />
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        <Link to="#" className=" me-2">
                          <img src={pdficon} alt="#" />
                        </Link>
                        <Link to="#" className=" me-2"></Link>
                        <Link to="#" className=" me-2">
                          <img src={pdficon3} alt="#" />
                        </Link>
                        <Link to="#">
                          <img src={pdficon4} alt="#" />
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <Table
                      pagination={{
                        total: filteredrolesList.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        // showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={filteredrolesList}
                      rowSelection={rowSelection}
                      rowKey={(record) => record.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal show={showDelete} onHide={() => setShowDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Are you sure want to delete this ?</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={imagesend} alt="#" width={50} height={46} />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setShowDelete(false)}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleDelete} // Call the delete function here
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RolesList;
