import React from 'react';
import HttpService from '../httpservice';

class AuthApi extends React.Component {


  // Utility function to create headers with authorization token
  createHeaders = () => {
    const udata = localStorage.getItem('userdata'); // Adjust the key if needed
    const userData = JSON.parse(udata);
    const token = userData.access_token;
    return {
      Authorization: `Bearer ${token}`,
    };
  };

  get = async (url, isExport = false, isM3u8 = false) => {
    try {
      const headers = this.createHeaders();
      const res = await HttpService.get(url, headers);
      let output = null;
      let resStatus = res.status === 200;
      
      if (!isExport) {
        output = res.data && res.data ? res.data : null;
      } else {
        output = res.data;
      }

      if (isM3u8) {
        resStatus = !res.data.includes('doctype html');
      }

      const response = {
        data: resStatus ? output : null,
        message: resStatus ? null : res.data.message,
        status: resStatus,
        result: res.data ? res.data.result : 400
      };

      return response;
    } catch (err) {
      console.log('err', err);
      let res = err.response ? err.response.data : '{}';
      return { ...JSON.parse(res), status: err.response ? err.response.status : 500 };
    }
  };

  post = async (url, data, options = {}) => {
    try {
      const headers = { ...this.createHeaders(), ...options.headers };
      const res = await HttpService.post(url, data, headers);
      const response = {
        data: res.status === 200 || res.status === 201 ? res.data : null,
        message: res.data.message,
        status: res.status,
        result: res.data ? res.data.result : 400
      };
      return response;
    } catch (err) {
      console.log('errr', err);
      let resp = err.response ? err.response.data : null;
      return { status: err.response ? err.response.status : 500, data: null, message: resp ? resp.message : 'Something went wrong' };
    }
  };

   loginpost = async (url, data, options = {}) => {
    try {
      const res = await HttpService.post(url, data, options);
      const response = {
        data: res.status === 200 || res.status === 201 ? res.data : null,
        message: res.data.message,
        status: res.status,
        result: res.data ? res.data.result : 400
      };
      return response;
    } catch (err) {
      console.log('errr', err);
      let resp = err.response ? err.response.data : null;
      return { status: err.response ? err.response.status : 500, data: null, message: resp ? resp.message : 'Something went wrong' };
    }
  };

  put = async (url, data, options = {}) => {
    try {
      const headers = { ...this.createHeaders(), ...options.headers };
      const res = await HttpService.put(url, data, headers);
      const response = {
        data: res.status === 200 || res.status === 201 ? res.data : null,
        message: res.data.message,
        status: res.status,
        result: res.data ? res.data.result : 400
      };
      return response;
    } catch (err) {
      console.log('err', err);
      return { status: err.response ? err.response.status : 500, data: null, message: err.response ? err.response.data.message : 'Something went wrong' };
    }
  };

  delete = async (url) => {
    try {
      const headers = this.createHeaders();
      const res = await HttpService.delete(url, headers);
      const response = {
        data: res.status === 200 || res.status === 201 ? res.data : null,
        message: res.data.message,
        status: res.status
      };
      return response;
    } catch (err) {
      console.log('err', err);
      return { status: err.response ? err.response.status : 500, data: null, message: err.response ? err.response.data.message : 'Something went wrong' };
    }
  };
}

export default new AuthApi();