/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { DatePicker } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Select from "react-select";
import { Link } from "react-router-dom";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import toast from "react-hot-toast";
import { loadingService } from "../../helper/observer";
import GeneralSettingsHeader from "../GeneralSettingsHeader";
import OrganisationDropdown from "../OrganisationDropdown";

const NewRegister = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile_number, setMobile_number] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [clinic_name, setClinic_name] = useState("");
  const [clinic_address, setClinic_address] = useState("");
  const [clinic_phone_number, setClinic_phone_number] = useState("");
  const [branch_name, setBranch_name] = useState("");
  const [userData, setUserData] = useState(null);


  const SubmitForm = async (e) => {
    e.preventDefault();

    if (!name) {
      toast.error("Please fill out all required fields.");
      return;
    }
    let payload = {
        name: name,
        email: email,
        mobile_number: mobile_number,
        password: password,
        password_confirmation: password_confirmation,
        clinic_name: clinic_name,
        clinic_address: clinic_address,
        clinic_phone_number: clinic_phone_number,
        branch_name: branch_name,
        
      };
  
      try {
        loadingService.next(true);
  
        const { data } = await AuthApi.post(Api.register, payload);
        loadingService.next(false);
  
        if (data) {
          toast.success("Success");
          
        }
      } catch (error) {
        console.error("Error occurred while submitting form:", error);
        // Handle error - display error message or perform appropriate actions
        toast.error(
          "An error occurred while submitting the form. Please try again later."
        );
      }
    };
  
    useEffect(() => {
      // set default styling
      let udata = localStorage.getItem("userdata");
      let data = JSON.parse(udata);
  
      setUserData(data.data);
    }, []);


  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="add-patient"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">App Settings </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Registeration</li>
                  </ul>
                </div>
              </div>
            </div>
            <OrganisationDropdown/>
            <GeneralSettingsHeader />
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={SubmitForm}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>New Registeration</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Name <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="name"
                              placeholder=""
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                             Email <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="email"
                              placeholder=""
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                             Mobile Number <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="mobile_number"
                              placeholder=""
                              value={mobile_number}
                              onChange={(e) => setMobile_number(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                            Clinic Name <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="clinic_name"
                              placeholder=""
                              value={clinic_name}
                              onChange={(e) => setClinic_name(e.target.value)}
                            />
                          </div>
                        </div>
                        
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                             Password<span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="password"
                              placeholder=""
                              maxLength={10}
                              value={password}
                              onChange={(e) =>
                                setPassword(e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                            Confirm Password <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="email"
                              name="password_confirmation"
                              placeholder=""
                              value={password_confirmation}
                              onChange={(e) => setPassword_confirmation(e.target.value)}
                            />
                          </div>
                        </div>
                        
                        
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                            Clinic Address <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="clinic_address"
                              placeholder=""
                              value={clinic_address}
                              onChange={(e) => setClinic_address(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="form-group local-forms">
                          <label>
                            Clinic Phone Number <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              name="clinic_phone_number"
                              defaultValue={""}
                              value={clinic_phone_number}
                              onChange={(e) => setClinic_phone_number(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="form-group local-forms">
                          <label>
                            Branch_Name <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              name="branch_name"
                              defaultValue={""}
                              value={branch_name}
                              onChange={(e) => setBranch_name(e.target.value)}
                            />
                          </div>
                        </div>
                        
                        
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Save
                            </button>
                            <button
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default NewRegister;
