/* eslint-disable no-const-assign */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { DatePicker } from "antd";
import Select from "react-select";
import { TextField } from "@mui/material";
import { Modal } from "react-bootstrap";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import { loadingService } from "../../helper/observer";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Calender = () => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const onChange = (date, dateString) => {
    // console.log(date, dateString);
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [SelectedPatient, setSelectedPatient] = useState(null);
  const [PatientList, setPatientList] = useState([
    // { value: 1, label: "Select Doctor" },
    { value: 2, label: "Dr.Bernardo James" },
    { value: 3, label: "Dr.Andrea Lalema" },
    { value: 4, label: "Dr.William Stephin" },
  ]);
  const [SelectedConsultDoctor, setSelectedConsultDoctor] = useState(null);
  const [ConsultDoctor, setConsultDoctor] = useState([
    // { value: 1, label: "Select Doctor" },
    { value: 2, label: "Dr.Bernardo James" },
    { value: 3, label: "Dr.Andrea Lalema" },
    { value: 4, label: "Dr.William Stephin" },
  ]);
  const [SelectedRefDoctor, setSelectedRefDoctor] = useState(null);
  const [RefDoctor, setRefDoctor] = useState([
    // { value: 1, label: "Select Doctor" },
    { value: 2, label: "Dr.Bernardo James" },
    { value: 3, label: "Dr.Andrea Lalema" },
    { value: 4, label: "Dr.William Stephin" },
  ]);
  const [isClicked, setIsClicked] = useState(false);
  const [dateOfAppointment, setDateOfAppointment] = useState("");
  const [editdateOfAppointment, setEditDateOfAppointment] = useState("");
  const [editstartTime, setEditStartTime] = useState();
  const [editendTime, setEditEndTime] = useState();
  const [editSelectedPatient, setEditSelectedPatient] = useState(null);
  const [editSelectedConsultDoctor, setEditSelectedConsultDoctor] =
    useState(null);
  const [editSelectedRefDoctor, setEditSelectedRefDoctor] = useState(null);
  const [showAddAppointment, setShowAddAppointment] = useState(false);
  const [showeditAppointment, setShowEditAppointment] = useState(false);
  const [consultdoctorList, setConsultDoctorList] = useState([]);
  const [refdoctorList, setRefDoctorList] = useState([]);
  const [Patientlist, setPatientlist] = useState([]);
  const [appointmentist, setAppointmentList] = useState([
    {
      title: "Event Name 4",
      start: Date.now() + 148000000,
      className: "bg-purple",
    },
    {
      title: "Test Event 1",
      start: Date.now(),
      end: Date.now(),
      className: "bg-success",
    },
    {
      title: "Test Event 2",
      start: Date.now() + 168000000,
      className: "bg-info",
    },
    {
      title: "Test Event 3",
      start: Date.now() + 338000000,
      className: "bg-primary",
    },
  ]);

  useEffect(() => {
    let elements = Array.from(
      document.getElementsByClassName("react-datepicker-wrapper")
    );
    elements.map((element) => element.classList.add("width-100"));
  }, []);

  const fetchData = async () => {
    try {
      const udata = localStorage.getItem("userdata");
      const data = JSON.parse(udata);
      loadingService.next(true);

      const response = await AuthApi.get(
        `${Api.appointmentlist}/${data.data.user_id}`
      );
      loadingService.next(false);

      const { data: responseData, status } = response.data;
      if (!responseData) {
        if (status === 401) navigate("/");
        return;
      }
      const { appointmentlist } = responseData;

      setAppointmentList(appointmentlist);
    } catch (error) {
      console.error("Error fetching form list:", error);
    }
  };
  const getpatientlistData = async () => {
    try {
      const udata = localStorage.getItem("userdata");
      const data = JSON.parse(udata);
      loadingService.next(true);

      const response = await AuthApi.get(
        `${Api.patientlist}/${data.data.user_id}`
      );
      loadingService.next(false);

      const { data: responseData, status } = response.data;
      if (!responseData) {
        if (status === 401) navigate("/");
        return;
      }
      const { patientlist } = responseData;

      setPatientlist(patientlist);
    } catch (error) {
      console.error("Error fetching form list:", error);
    }
  };

  const fetchdoctorData = async () => {
    try {
      const udata = localStorage.getItem("userdata");
      const data = JSON.parse(udata);
      loadingService.next(true);

      const response = await AuthApi.get(
        `${Api.doctorlist}/${data.data.user_id}`
      );
      loadingService.next(false);

      const { data: responseData, status } = response.data;
      if (!responseData) {
        if (status === 401) navigate("/");
        return;
      }
      const { consultdoctorlist } = responseData;
      const { refdoctorlist } = responseData;

      setConsultDoctorList(consultdoctorlist);
      setRefDoctorList(refdoctorlist);
    } catch (error) {
      console.error("Error fetching form list:", error);
    }
  };

  useEffect(() => {
    // fetchData();
    // fetchdoctorData();
    // getpatientlistData();
  }, []);

  return (
    <>
      <div className="main-wrapper">
        <Header />
        <Sidebar />
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* Page Header */}
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/admindashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Events</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-lg-12">
                <div className="py-3 d-flex justify-content-end">
                  <Link to="/appoinmentlist" className="invoices-links active">
                    <i data-feather="list">
                      <FeatherIcon icon="list" />
                    </i>
                  </Link>
                  <Link to="/calender" className="invoices-links">
                    <i data-feather="grid">
                      <FeatherIcon icon="grid" />
                    </i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col" />
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div id="calendar">
                      <FullCalendar
                        plugins={[
                          dayGridPlugin,
                          timeGridPlugin,
                          interactionPlugin,
                        ]}
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,timeGridWeek,timeGridDay",
                        }}
                        initialView="timeGridDay"
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        initialEvents={appointmentist} // alternatively, use the `events` setting to fetch from a feed
                        select={(clickInfo) => setShowAddAppointment(clickInfo)}
                        eventClick={(clickInfo) =>
                          setShowEditAppointment(clickInfo)
                        }
                          // Limit the time shown from 8:00 AM to 11:30 PM
                        slotMinTime="08:00:00"
                        slotMaxTime="24:00:00"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Footer */}
        </div>
      </div>
      {/* /Main Wrapper */}
      <Modal
        show={showAddAppointment}
        onHide={() => setShowAddAppointment(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-12">
                <div className="form-heading">
                  <h4>Patient Details</h4>
                </div>
              </div>
              <div className="col-12 col-md-12 col-xl-12">
                <div className="form-group local-forms">
                  <label>
                    Patient<span className="login-danger">*</span>
                  </label>
                  <Select
                    defaultValue={SelectedPatient}
                    onChange={setSelectedPatient}
                    options={PatientList}
                    menuPortalTarget={document.body}
                    id="search-commodity"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused
                          ? "none"
                          : "2px solid rgba(46, 55, 164, 0.1);",
                        boxShadow: state.isFocused
                          ? "0 0 0 1px #2e37a4"
                          : "none",
                        "&:hover": {
                          borderColor: state.isFocused
                            ? "none"
                            : "2px solid rgba(46, 55, 164, 0.1)",
                        },
                        borderRadius: "10px",
                        fontSize: "14px",
                        minHeight: "45px",
                      }),
                      dropdownIndicator: (base, state) => ({
                        ...base,
                        transform: state.selectProps.menuIsOpen
                          ? "rotate(-180deg)"
                          : "rotate(0)",
                        transition: "250ms",
                        width: "35px",
                        height: "35px",
                      }),
                    }}
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="form-heading">
                  <h4>Appointment Details</h4>
                </div>
              </div>
              <div className="col-12 col-md-12 col-xl-12">
                <div className="form-group local-forms cal-icon">
                  <label>
                    Date of Appointment <span className="login-danger">*</span>
                  </label>
                  <DatePicker
                    className="form-control datetimepicker"
                    selected={dateOfAppointment}
                    onChange={(date) => setDateOfAppointment(date)}
                    suffixIcon={null}
                    style={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: isClicked
                          ? "#2E37A4"
                          : "2px solid rgba(46, 55, 164, 0.1)",
                        "&:hover": {
                          borderColor: state.isFocused ? "none" : "none",
                        },
                      }),
                    }}
                  />
                  {/* <input
                        className="form-control datetimepicker"
                        type="text"
                      /> */}
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-6">
                <div className="form-group local-forms">
                  <label>
                    From <span className="login-danger">*</span>
                  </label>
                  <div className="">
                    <TextField
                      className="form-control"
                      id="outlined-controlled"
                      type="time"
                      value={startTime}
                      onChange={(event) => {
                        setStartTime(event.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-6">
                <div className="form-group local-forms">
                  <label>
                    To <span className="login-danger">*</span>
                  </label>
                  <div className="">
                    <TextField
                      className="form-control"
                      id="outlined-controlled"
                      type="time"
                      value={endTime}
                      onChange={(event) => {
                        setEndTime(event.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-6">
                <div className="form-group local-forms">
                  <label>
                    Consulting Doctor
                    <span className="login-danger">*</span>
                  </label>
                  <Select
                    defaultValue={SelectedConsultDoctor}
                    onChange={setSelectedConsultDoctor}
                    options={ConsultDoctor}
                    menuPortalTarget={document.body}
                    id="search-commodity"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused
                          ? "none"
                          : "2px solid rgba(46, 55, 164, 0.1);",
                        boxShadow: state.isFocused
                          ? "0 0 0 1px #2e37a4"
                          : "none",
                        "&:hover": {
                          borderColor: state.isFocused
                            ? "none"
                            : "2px solid rgba(46, 55, 164, 0.1)",
                        },
                        borderRadius: "10px",
                        fontSize: "14px",
                        minHeight: "45px",
                      }),
                      dropdownIndicator: (base, state) => ({
                        ...base,
                        transform: state.selectProps.menuIsOpen
                          ? "rotate(-180deg)"
                          : "rotate(0)",
                        transition: "250ms",
                        width: "35px",
                        height: "35px",
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-6">
                <div className="form-group local-forms">
                  <label>Refrence Doctor</label>
                  <Select
                    defaultValue={SelectedRefDoctor}
                    onChange={setSelectedRefDoctor}
                    options={RefDoctor}
                    menuPortalTarget={document.body}
                    id="search-commodity"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused
                          ? "none"
                          : "2px solid rgba(46, 55, 164, 0.1);",
                        boxShadow: state.isFocused
                          ? "0 0 0 1px #2e37a4"
                          : "none",
                        "&:hover": {
                          borderColor: state.isFocused
                            ? "none"
                            : "2px solid rgba(46, 55, 164, 0.1)",
                        },
                        borderRadius: "10px",
                        fontSize: "14px",
                        minHeight: "45px",
                      }),
                      dropdownIndicator: (base, state) => ({
                        ...base,
                        transform: state.selectProps.menuIsOpen
                          ? "rotate(-180deg)"
                          : "rotate(0)",
                        transition: "250ms",
                        width: "35px",
                        height: "35px",
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setShowAddAppointment(false)}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              /* Handle save event */
            }}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showeditAppointment}
        onHide={() => setShowEditAppointment(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-12">
                <div className="form-heading">
                  <h4>Patient Details</h4>
                </div>
              </div>
              <div className="col-12 col-md-12 col-xl-12">
                <div className="form-group local-forms">
                  <label>
                    Patient<span className="login-danger">*</span>
                  </label>
                  <Select
                    defaultValue={editSelectedPatient}
                    onChange={setEditSelectedPatient}
                    options={PatientList}
                    menuPortalTarget={document.body}
                    id="search-commodity"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused
                          ? "none"
                          : "2px solid rgba(46, 55, 164, 0.1);",
                        boxShadow: state.isFocused
                          ? "0 0 0 1px #2e37a4"
                          : "none",
                        "&:hover": {
                          borderColor: state.isFocused
                            ? "none"
                            : "2px solid rgba(46, 55, 164, 0.1)",
                        },
                        borderRadius: "10px",
                        fontSize: "14px",
                        minHeight: "45px",
                      }),
                      dropdownIndicator: (base, state) => ({
                        ...base,
                        transform: state.selectProps.menuIsOpen
                          ? "rotate(-180deg)"
                          : "rotate(0)",
                        transition: "250ms",
                        width: "35px",
                        height: "35px",
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-heading">
                  <h4>Appointment Details</h4>
                </div>
              </div>
              <div className="col-12 col-md-12 col-xl-12">
                <div className="form-group local-forms cal-icon">
                  <label>
                    Date of Appointment <span className="login-danger">*</span>
                  </label>
                  <DatePicker
                    className="form-control datetimepicker"
                    selected={editdateOfAppointment}
                    onChange={(date) => setEditDateOfAppointment(date)}
                    suffixIcon={null}
                    style={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: isClicked
                          ? "#2E37A4"
                          : "2px solid rgba(46, 55, 164, 0.1)",
                        "&:hover": {
                          borderColor: state.isFocused ? "none" : "none",
                        },
                      }),
                    }}
                  />
                  {/* <input
                        className="form-control datetimepicker"
                        type="text"
                      /> */}
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-6">
                <div className="form-group local-forms">
                  <label>
                    From <span className="login-danger">*</span>
                  </label>
                  <div className="">
                    <TextField
                      className="form-control"
                      id="outlined-controlled"
                      type="time"
                      value={editstartTime}
                      onChange={(event) => {
                        setEditStartTime(event.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-6">
                <div className="form-group local-forms">
                  <label>
                    To <span className="login-danger">*</span>
                  </label>
                  <div className="">
                    <TextField
                      className="form-control"
                      id="outlined-controlled"
                      type="time"
                      value={editendTime}
                      onChange={(event) => {
                        setEditEndTime(event.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-6">
                <div className="form-group local-forms">
                  <label>
                    Consulting Doctor
                    <span className="login-danger">*</span>
                  </label>
                  <Select
                    defaultValue={editSelectedConsultDoctor}
                    onChange={setEditSelectedConsultDoctor}
                    options={ConsultDoctor}
                    menuPortalTarget={document.body}
                    id="search-commodity"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused
                          ? "none"
                          : "2px solid rgba(46, 55, 164, 0.1);",
                        boxShadow: state.isFocused
                          ? "0 0 0 1px #2e37a4"
                          : "none",
                        "&:hover": {
                          borderColor: state.isFocused
                            ? "none"
                            : "2px solid rgba(46, 55, 164, 0.1)",
                        },
                        borderRadius: "10px",
                        fontSize: "14px",
                        minHeight: "45px",
                      }),
                      dropdownIndicator: (base, state) => ({
                        ...base,
                        transform: state.selectProps.menuIsOpen
                          ? "rotate(-180deg)"
                          : "rotate(0)",
                        transition: "250ms",
                        width: "35px",
                        height: "35px",
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-6">
                <div className="form-group local-forms">
                  <label>Refrence Doctor</label>
                  <Select
                    defaultValue={editSelectedRefDoctor}
                    onChange={setEditSelectedRefDoctor}
                    options={RefDoctor}
                    menuPortalTarget={document.body}
                    id="search-commodity"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused
                          ? "none"
                          : "2px solid rgba(46, 55, 164, 0.1);",
                        boxShadow: state.isFocused
                          ? "0 0 0 1px #2e37a4"
                          : "none",
                        "&:hover": {
                          borderColor: state.isFocused
                            ? "none"
                            : "2px solid rgba(46, 55, 164, 0.1)",
                        },
                        borderRadius: "10px",
                        fontSize: "14px",
                        minHeight: "45px",
                      }),
                      dropdownIndicator: (base, state) => ({
                        ...base,
                        transform: state.selectProps.menuIsOpen
                          ? "rotate(-180deg)"
                          : "rotate(0)",
                        transition: "250ms",
                        width: "35px",
                        height: "35px",
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => setShowEditAppointment(false)}
          >
            Delete
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setShowEditAppointment(false)}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              /* Handle save event */
            }}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Calender;
