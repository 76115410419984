/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import ClinicSettingsHeader from "../../ClinicSettingsHeader";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { favicon } from "../../imagepath";
import Select from "react-select";
import { Table } from "antd";
import { itemRender, onShowSizeChange } from "../../Pagination";
import { invoice_list_data } from "../../GlobalData/TableData";
import {
  invoice_icon1,
  invoice_icon2,
  invoice_icon3,
  invoice_icon4,
  invoiceicon5,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
} from "../../imagepath";

const Whatsappservices = () => {
  const [show, setShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [selectedOption, setSelectedOption] = useState(null);
  const [option, setOption] = useState([
    { value: 1, label: "Select City" },
    { value: 2, label: "California" },
    { value: 3, label: "Tasmania" },
    { value: 4, label: "Auckland" },
    { value: 5, label: "Marlborough" },
  ]);
  const [country, setCountry] = useState([
    { value: 1, label: "Select City" },
    { value: 2, label: "India" },
    { value: 3, label: "London" },
    { value: 4, label: "USA" },
    { value: 5, label: "French" },
  ]);

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Sr No.",
      dataIndex: "Invoice_Id",
      sorter: (a, b) => a.Invoice_Id.length - b.Invoice_Id.length,
    },
    {
      title: "User Name",
      dataIndex: "Invoice_Id",
      sorter: (a, b) => a.Invoice_Id.length - b.Invoice_Id.length,
    },
    {
      title: "Default No.",
      dataIndex: "",
      render: (text, record) => (
        <div className="text-end">
          <label className="form-check-label">
            <input
              type="checkbox"
              id="status_2"
              className="check"
              defaultChecked="false"
            />
            <label htmlFor="status_2" className="checktoggle">
              checkbox
            </label>
          </label>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      render: (text, record) => (
        <div className="text-end">
          <Link
            className="btn btn-sm btn-white text-success me-2"
            to="/edit-invoice"
          >
            <i className="far fa-edit me-1" />
            Edit
          </Link>
          <Link className="btn btn-sm btn-white text-danger" to="#">
            <i className="far fa-trash-alt me-1" />
            Delete
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Header />
      <Sidebar activeClassName="appsettings" />

      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="index.html">Dashboard </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Clinic Settings</li>
                </ul>
              </div>
            </div>
          </div>
          <ClinicSettingsHeader />{/* Header for Clinic Settings Page only */}
          <div className="row">
            <div className="col-md-8">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Whatsapp Services</h5>
                </div>
                <div className="nav-menu-tabs">
                  <div className="settings-menu-links pt-0">
                    <ul className="nav nav-tabs menu-tabs">
                      <li className="nav-item">
                        <Link className="nav-link " to="/ClinicSetting_emailSettings">
                          Email Setting
                        </Link>
                      </li>
                      <li className="nav-item active">
                        <Link className="nav-link" to="/ClinicSetting_whatsappservices">
                          WhatsApp Services
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-body pt-0">
                <form>
                    <div className="settings-form">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              WhatsApp Number <span className="star-red"></span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="WhatsApp Number"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              Set as a Default Number{" "}
                              <span className="star-red"></span>
                            </label>
                            <br />
                            <label className="form-check-label">
                              <input
                                type="checkbox"
                                id="status_1"
                                className="check"
                                defaultChecked={true}
                              />
                              <label htmlFor="status_1" className="checktoggle">
                                checkbox
                              </label>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              Click here to Add
                              <span className="star-red"></span>
                            </label>
                            <br />
                              <button
                                type="submit"
                                className="border-0 btn btn-primary btn-gradient-primary btn-rounded"
                              >
                                Add
                              </button>
                            
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              Price
                              <span className="star-red"></span>
                            </label>
                            <br />
                            <label>
                              Rs 4999/-
                              <span className="star-red"></span>
                            </label>
                          </div>
                        </div>
                      
                      </div>
                    </div>
                  </form>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card card-table">
                      <div className="card-body p-4">
                        <div className="page-table-header mb-2">
                          <div className="row align-items-center">
                            <div className="col">
                              <div className="doctor-table-blk">
                                <h3>WhatsApp Services</h3>
                                <div className="doctor-search-blk">
                                  <div className="top-nav-search table-search-blk">
                                    <form>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search here"
                                        onChange={handleSearch}
                                      />
                                      <Link className="btn">
                                        <img src={searchnormal} alt="#" />
                                      </Link>
                                    </form>
                                  </div>
                                  <div className="add-group">
                                    <Link
                                      to="/addpatients"
                                      className="btn btn-primary add-pluss ms-2"
                                    >
                                      <img src={plusicon} alt="#" />
                                    </Link>
                                    <Link
                                      to="#"
                                      className="btn btn-primary doctor-refresh ms-2"
                                    >
                                      <img src={refreshicon} alt="#" />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-auto text-end float-end ms-auto download-grp">
                              <Link to="#" className=" me-2">
                                <img src={pdficon} alt="#" />
                              </Link>
                              <Link to="#" className=" me-2"></Link>
                              <Link to="#" className=" me-2">
                                <img src={pdficon3} alt="#" />
                              </Link>
                              <Link to="#">
                                <img src={pdficon4} alt="#" />
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="table-responsive">
                          <Table
                            pagination={{
                              total: invoice_list_data.length,
                              showTotal: (total, range) =>
                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                              // showSizeChanger: true,
                              onShowSizeChange: onShowSizeChange,
                              itemRender: itemRender,
                            }}
                            columns={columns}
                            dataSource={invoice_list_data}
                            rowSelection={rowSelection}
                            rowKey={(record) => record.id}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whatsappservices;
