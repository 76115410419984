/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link , useParams} from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { favicon, imagesend } from "../imagepath";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { DatePicker } from "antd";
import Select from "react-select";
import toast from "react-hot-toast";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";


const EditOrg = () => {
  const navigate = useNavigate();
  const { id } = useParams(); 
  const [name, setName] = useState("");
  const [owner_name, setOwner_name] = useState("");
  const [owner_id, setOwner_id] = useState("");
  const [mobile_number, setMobile_number] = useState("");
  const [default_wp_number, setdefault_wp_number] = useState(false);
  const [address_line1, setaddress_line1] = useState("");
  const [address_line2, setaddress_line2] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [pincode, setpincode] = useState("");
  const [country, setcountry] = useState("");
  
  useEffect(() => {
    getEditInfo();
  }, []);

  const getEditInfo = async () => {
    try {
      const response = await AuthApi.get(`${Api.organizationinfo}/${id}`);
      console.log('API Response:', response); // Log the response
      const { data, message, status } = response;
  
      // Check if the status is 401 and ensure it's defined
      if (status === 401) {
        toast.error("Unauthorized. Redirecting to login...");
        navigate("/login");
        return;
      }
  
      // Check if data exists
      if (!data || !data.data) {
        toast.error(message || "An error occurred.");
        console.log('Data not found:', data); 
        return; // Exit the function if no data is found
      }
  
      const org = data.data;
  
      // Set state values with a fallback for missing fields
      setName(org.name || "");
      setOwner_name(org.owner_name || "");
      setOwner_id(org.owner_id || "");
      setMobile_number(org.mobile_number || "");
      setdefault_wp_number(org.default_wp_number || ""); // Corrected
      setaddress_line1(org.address_line1 || "");
      setaddress_line2(org.address_line2 || "");
      setcity(org.city || "");
      setstate(org.state || "");
      setpincode(org.pincode || "");
      setcountry(org.country || "");
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while fetching the information.");
    }
  };
  

  const SubmitForm = async (e) => {
  e.preventDefault();

  if (!name) {
    toast.error("Please fill out all required fields.");
    return;
  }

  let payload={
    name:name,
    owner_name:owner_name,
    owner_id:owner_id,
    mobile_number:mobile_number,
    default_wp_number: default_wp_number,
    address_line1: address_line1,
    address_line2: address_line2,
    city: city,
    state: state,
    pincode: pincode,
    country: country,
  };
  try {
    const { status } = await AuthApi.put(
        `${Api.updateorganization}/${id}`,payload);
    
    if (status) {
      toast.success("successfully");
      navigate("/orglist");
    }
  } catch (error) {
    toast.error("An error occurred while submitting the form.");
    console.error("Error occurred while submitting form:", error);
  }
};


  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item1"
        id1="menu-items1"
        activeClassName="edit-doctor"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Organazation</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Edit Organazation</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={SubmitForm}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Edit Organazation </h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                            Organazation Edit <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                            Owner Name <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={owner_name}
                              onChange={(e) => setOwner_name(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                            Mobile Number <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={mobile_number}
                              onChange={(e) => setMobile_number(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                            Owner ID <span className="login-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={owner_id}
                              onChange={(e) => setOwner_id(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group">
                            <label className="local-forms">
                              Do you want us to use this number for WhatsApp
                              Update?
                            </label>
                            <div className="status-toggle">
                              <input
                                type="checkbox"
                                id="status_1"
                                className="check"
                                checked={default_wp_number}
                                onChange={(e) =>
                                  setdefault_wp_number(e.target.checked)
                                } // Use e.target.checked for boolean
                              />
                              <label htmlFor="status_1" className="checktoggle">
                                checkbox
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Address Line 1{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={address_line1}
                              onChange={(e) => setaddress_line1(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Address Line 2{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={address_line2}
                              onChange={(e) => setaddress_line2(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              City <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={city}
                              onChange={(e) => setcity(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              State <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={state}
                              onChange={(e) => setstate(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Postal Code{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={pincode}
                              onChange={(e) => setpincode(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Country <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={country}
                              onChange={(e) => setcountry(e.target.value)}
                            />
                          </div>
                        </div>
                        
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary cancel-form"
                              onClick={() => navigate("/orglist")}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default EditOrg;
