/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import GeneralSettingsHeader from "../../GeneralSettingsHeader";
import OrganisationDropdown from "../../OrganisationDropdown";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { favicon } from "../../imagepath";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Addonservices = () => {
  const [show, setShow] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [description, setDescription] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [option, setOption] = useState([
    { value: 1, label: "Select City" },
    { value: 2, label: "California" },
    { value: 3, label: "Tasmania" },
    { value: 4, label: "Auckland" },
    { value: 5, label: "Marlborough" },
  ]);
  const [country, setCountry] = useState([
    { value: 1, label: "Select City" },
    { value: 2, label: "India" },
    { value: 3, label: "London" },
    { value: 4, label: "USA" },
    { value: 5, label: "French" },
  ]);
  return (
    <div>
      <Header />
      <Sidebar activeClassName="appsettings" />

      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="index.html">Dashboard </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Settings</li>
                </ul>
              </div>
            </div>
          </div>
          <OrganisationDropdown/>
          <GeneralSettingsHeader /> {/* Header for General Settings Page only */}
          <div className="row">
            <div className="col-md-8">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Subscription & Billing</h5>
                </div>
                <div className="nav-menu-tabs">
                  <div className="settings-menu-links pt-0">
                    <ul className="nav nav-tabs menu-tabs">
                      <li className="nav-item">
                        <Link className="nav-link " to="/packagemanagement">
                          Package Management
                        </Link>
                      </li>
                      <li className="nav-item active">
                        <Link className="nav-link" to="/addonservices">
                          Add-On-Services
                        </Link>
                      </li>
                      <li className="nav-item ">
                        <Link className="nav-link" to="/whatsappservices">
                          WhatsApp Services
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <form>
                    <div className="settings-form">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Package Name <span className="star-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Package Name"
                            />
                            <label className="form-check-label">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={showDescription}
                                onChange={(e) =>
                                  setShowDescription(e.target.checked)
                                }
                              />
                              Click Here to See Description Box
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Price <span className="star-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Price"
                            />
                          </div>
                        </div>
                        {showDescription && (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Description <span className="star-red">*</span>
                              </label>
                              <textarea className="form-control" />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Description{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <CKEditor
                              editor={ClassicEditor}
                              data={description} // Set initial data to CKEditor
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setDescription(data); // Update description state with editor content
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Apply To Package{" "}
                              <span className="star-red"></span>
                            </label>
                            <Select
                              defaultValue={selectedOption}
                              onChange={setSelectedOption}
                              options={option}
                              id="search-commodity"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "hsl(0, 0%, 80%)"
                                    : "#86b7fe", // Change to navy blue (#000080)
                                  border: state.isFocused
                                    ? "2px solid rgba(46, 55, 164, 0.1)"
                                    : "2px solid rgba(46, 55, 164, 0.1)",
                                  boxShadow: "none",
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-0">
                        <div className="settings-btns">
                          <button
                            type="submit"
                            className="border-0 btn btn-primary btn-gradient-primary btn-rounded"
                          >
                            Update
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-secondary btn-rounded"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addonservices;
