/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Select from "react-select";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { loadingService } from "../../helper/observer";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";

const EditMedication = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Assumes the medication ID is passed in the route parameters
  const [isClicked, setIsClicked] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [before_after, setBefore_After] = useState("");
  const [content, setContent] = useState("");
  const [no_of_times, setNo_of_Times] = useState("");
  const [userData, setUserData] = useState(null);
  const [no_of_time, setTimeOfDay] = useState("");

  const [option, setOption] = useState([
    { value: 1, label: "Select Category" },
    { value: 2, label: "Antibiotics" },
    { value: 3, label: "Analgesics" },
    { value: 4, label: "Antacid" },
  ]);

  const [options, setOptions] = useState([
    { value: 1, label: "Select Category" },
    { value: 2, label: "After Meal" },
    { value: 3, label: "Before Meal" },
  ]);

  useEffect(() => {
    getMedicationInfo();
  }, []);

  const getMedicationInfo = async () => {
    try {
      const response = await AuthApi.get(`${Api.medicationmasterinfo}/${id}`);
      const { data, message, status } = response;

      if (!data) {
        toast.success(message);
        if (status === 401) navigate("/login");
        return;
      }

      const medication = data.data;
      setName(medication.name);
      setCategory(medication.category);
      setBefore_After(medication.before_after);
      setContent(medication.content);
      setNo_of_Times(medication.no_of_times);
    } catch (error) {
      console.error(error);
    }
  };

  const SubmitForm = async (e) => {
    e.preventDefault();

    if (!name) {
      toast.error("Please fill out all required fields.");
      return;
    }

    let payload = {
      name,
      category,
      before_after,
      content,
      no_of_times,
    };

    try {
      const { status } = await AuthApi.put(
        `${Api.updatemedicationmaster}/${id}`,
        payload
      );
      if (status) {
        toast.success("Success");
        navigate("/medicationlist");
      }
    } catch (error) {
      toast.error(error);
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item23"
        id1="menu-items23"
        activeClassName="add-medication"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Medication </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Medication</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={SubmitForm}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Medication Details</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Name Of Medicine{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              value={name}
                              placeholder=""
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Category <span className="login-danger">*</span>
                            </label>
                            <Select
                              menuPortalTarget={document.body}
                              defaultValue={option[0]} // Default selected option
                              onChange={(selectedOption) =>
                                setCategory(selectedOption.value)
                              } // Update category state
                              options={option}
                              val
                              id="search-commodity"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "none"
                                    : "2px solid rgba(46, 55, 164, 0.1);",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "2px solid rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Before/After{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <Select
                              menuPortalTarget={document.body}
                              defaultValue={options[0]}
                              onChange={(setSelectedOption) =>
                                setBefore_After(setSelectedOption.value)
                              }
                              options={options}
                              id="search-commodity"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "none"
                                    : "2px solid rgba(46, 55, 164, 0.1);",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "2px solid rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-8 col-xl-8">
                          <div className="form-group local-forms">
                            <label>
                              Content Of Medicine{" "}
                              <span className="login-danger"></span>
                            </label>
                            <textarea
                              className="form-control"
                              type="text"
                              rows={3}
                              value={content}
                              onChange={(e) => setContent(e.target.value)}
                              defaultValue={""}
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="form-group select-gender">
                            <label className="gen-label">
                              Number Of Times
                              <span className="login-danger">*</span>
                            </label>

                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="no_of_time"
                                  value="Morning"
                                  onChange={(e) =>
                                    setNo_of_Times(e.target.value)
                                  } // Update no_of_times state
                                  className="form-check-input"
                                />
                                Morning
                              </label>
                            </div>

                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="no_of_time"
                                  value="Afternoon"
                                  onChange={(e) =>
                                    setNo_of_Times(e.target.value)
                                  } // Update no_of_times state
                                  className="form-check-input"
                                />
                                Afternoon
                              </label>
                            </div>

                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="no_of_time"
                                  value="Evening"
                                  onChange={(e) =>
                                    setNo_of_Times(e.target.value)
                                  } // Update no_of_times state
                                  className="form-check-input"
                                />
                                Evening
                              </label>
                            </div>

                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="no_of_time"
                                  value="If it hurts"
                                  onChange={(e) =>
                                    setNo_of_Times(e.target.value)
                                  } // Update no_of_times state
                                  className="form-check-input"
                                />
                                If it hurts
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                navigate("/medicationlist");
                              }}
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default EditMedication;
