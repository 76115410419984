import React, { useState, useEffect } from "react";
import Select from "react-select";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import AuthApi from "../helper/authApi";
import Api from "../helper/api";

const OrganisationDropdown = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState(null);
  const [options, setOptions] = useState([
    { value: "", label: "Select Organisation" }, // Default option
  ]);
  const [categorybranch, setCategorybranch] = useState(null);
  const [optionsbranch, setbranchOptions] = useState([
    { value: "", label: "Select branch" }, // Default option
  ]);

  const getOrganisationInfo = async () => {
    try {
      const response = await AuthApi.get(`${Api.organizationlist}`);
      const { data, status } = response;

      if (!data) {  
        // Show an error message instead of a success message
        toast.error("Failed to load data.");
        if (status === 401) navigate("/login");
        return;
      }

      const organisations = data.data; // Assuming data.data contains an array of organizations

      // Map the organisations to the format required by react-select options
      const organisationOptions = organisations.map((org) => ({
        value: org.id, // Assuming each organisation has an id
        label: org.name, // Assuming each organisation has a name
      }));

      setOptions([{ value: "", label: "Select Organisation" }, ...organisationOptions]);
    } catch (error) {
      console.error(error);
      toast.error("Failed to load organizations");
    }
  };

  useEffect(() => {
    getOrganisationInfo();
  }, []);

//branchlist
  const getbranchlistInfo = async () => {
    try {
      const response = await AuthApi.get(`${Api.branchlist}`);
      const { data, status } = response;

      if (!data) {  
        // Show an error message instead of a success message
        toast.error("Failed to load data.");
        if (status === 401) navigate("/login");
        return;
      }

      const branchinfo = data.data; // Assuming data.data contains an array of branch

      // Map the branch to the format required by react-select options
      const branchinfoOptions = branchinfo.map((branch) => ({
        value: branch.id, // Assuming each organisation has an id
        label: branch.branch_name, // Assuming each branch has a name
      }));

      setbranchOptions([{ value: "", label: "Select branch" }, ...branchinfoOptions]);
    } catch (error) {
      console.error(error);
      toast.error("Failed to load branch");
    }
  };

  useEffect(() => {
    getbranchlistInfo();
  }, []);

  return (
    <div className="row">
      <div className="col-12 col-md-6 col-xl-4">
        <div className="form-group local-forms">
          <label>
            Organisation <span className="login-danger">*</span>
          </label>
          <Select
            menuPortalTarget={document.body}
            onChange={(selectedOption) => setCategory(selectedOption.value)} // Update category state
            options={options} // Use dynamic options
            value={options.find((opt) => opt.value === category)} // Set value based on state
            id="search-commodity"
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused
                  ? "none"
                  : "2px solid rgba(46, 55, 164, 0.1);",
                boxShadow: state.isFocused ? "0 0 0 1px #2e37a4" : "none",
                "&:hover": {
                  borderColor: state.isFocused
                    ? "none"
                    : "2px solid rgba(46, 55, 164, 0.1)",
                },
                borderRadius: "10px",
                fontSize: "14px",
                minHeight: "45px",
              }),
              dropdownIndicator: (base, state) => ({
                ...base,
                transform: state.selectProps.menuIsOpen
                  ? "rotate(-180deg)"
                  : "rotate(0)",
                transition: "250ms",
                width: "35px",
                height: "35px",
              }),
            }}
          />
        </div>
      </div>
      <div className="col-12 col-md-6 col-xl-4">
        <div className="form-group local-forms">
          <label>
          Branch List <span className="login-danger">*</span>
          </label>
          <Select
            menuPortalTarget={document.body}
            onChange={(selectedOption) => setCategorybranch(selectedOption.value)} // Update category state
            options={optionsbranch} // Use dynamic options
            value={optionsbranch.find((opt) => opt.value === categorybranch)} // Set value based on state
            id="search-commodity"
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused
                  ? "none"
                  : "2px solid rgba(46, 55, 164, 0.1);",
                boxShadow: state.isFocused ? "0 0 0 1px #2e37a4" : "none",
                "&:hover": {
                  borderColor: state.isFocused
                    ? "none"
                    : "2px solid rgba(46, 55, 164, 0.1)",
                },
                borderRadius: "10px",
                fontSize: "14px",
                minHeight: "45px",
              }),
              dropdownIndicator: (base, state) => ({
                ...base,
                transform: state.selectProps.menuIsOpen
                  ? "rotate(-180deg)"
                  : "rotate(0)",
                transition: "250ms",
                width: "35px",
                height: "35px",
              }),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OrganisationDropdown;
