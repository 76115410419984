/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import GeneralSettingsHeader from "../../GeneralSettingsHeader";
import OrganisationDropdown from "../../OrganisationDropdown";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { favicon } from "../../imagepath";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EmailSettings = () => {
  const [show, setShow] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [description, setDescription] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [option, setOption] = useState([
    { value: 1, label: "Select City" },
    { value: 2, label: "California" },
    { value: 3, label: "Tasmania" },
    { value: 4, label: "Auckland" },
    { value: 5, label: "Marlborough" },
  ]);
  const [country, setCountry] = useState([
    { value: 1, label: "Select City" },
    { value: 2, label: "India" },
    { value: 3, label: "London" },
    { value: 4, label: "USA" },
    { value: 5, label: "French" },
  ]);
  return (
    <div>
      <Header />
      <Sidebar activeClassName="appsettings" />

      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="index.html">Dashboard </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Settings</li>
                </ul>
              </div>
            </div>
          </div>
          <OrganisationDropdown/>
          <GeneralSettingsHeader /> {/* Header for General Settings Page only */}
          <div className="row">
            <div className="col-md-8">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Email Settings </h5>
                </div>
                <div className="nav-menu-tabs">
                  <div className="settings-menu-links pt-0">
                    <ul className="nav nav-tabs menu-tabs">
                      <li className="nav-item active">
                        <Link className="nav-link" to="/emailsettings">
                        Email Settings
                        </Link>
                      </li>
                      <li className="nav-item ">
                        <Link className="nav-link" to="/whatsappservice">
                          WhatsApp Services
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <form>
                    <div className="settings-form">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              SMTP Server <span className="star-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="SMTP Server"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              PORT <span className="star-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="PORT"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              User Name <span className="star-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="User Name"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Password <span className="star-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Password"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              From Name <span className="star-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="From Name"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              From Email <span className="star-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="From Email"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Reply-to-Email <span className="star-red">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Reply-to-Email"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-0">
                        <div className="settings-btns">
                          <button
                            type="submit"
                            className="border-0 btn btn-primary btn-gradient-primary btn-rounded"
                          >
                            Update
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="submit"
                            className="btn btn-secondary btn-rounded"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSettings;
