import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import {
  cameraicon,
  doctor,
  imagesend,
  menuicon16,
  
  demopatient,
} from "../imagepath";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

const PatientsProfile = () => {
  return (
    <div>
      <>
        <Header />
        <Sidebar id="menu-item2" id1="menu-items2" activeClassName="patient" />
        <>
          <div className="page-wrapper">
            <div className="content">
              {/* Page Header */}
              <div className="page-header">
                <div className="row">
                  <div className="col-sm-12">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#">Patients </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <i className="feather-chevron-right">
                          <FeatherIcon icon="chevron-right" />
                        </i>
                      </li>
                      <li className="breadcrumb-item active">
                        Patient Profile
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="about-info">
                            <h4>
                              Patients Profile{" "}
                              <span>
                                <Link to="#">
                                  <i className="feather-more-vertical">
                                    <FeatherIcon icon="more-vertical" />
                                  </i>
                                </Link>
                              </span>
                            </h4>
                          </div>
                          <div className="doctor-profile-head">
                            {/* <div className="profile-bg-img">
                              <img src={profilebg} alt="Profile" />
                            </div> */}
                            <div className="row">
                              <div className="col-lg-4 col-md-4">
                                <div className="profile-user-box">
                                  <div className="profile-user-img">
                                    <img src={demopatient} alt="demopatient" />
                                    <div className="form-group doctor-up-files profile-edit-icon mb-0">
                                      <div className="uplod d-flex">
                                        <label className="file-upload profile-upbtn mb-0">
                                          <img src={cameraicon} alt="Profile" />
                                          <input type="file" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="names-profiles">
                                    <h4>Smith Bruklin</h4>
                                    <h5>Patient</h5>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 d-flex align-items-center">
                                <div className="follow-group">
                                  <div className="doctor-follows">
                                    <h5>Gender</h5>
                                    <h5>Male</h5>
                                  </div>
                                  <div className="doctor-follows">
                                    <h5>Age</h5>
                                    <h5>28</h5>
                                  </div>
                                  <div className="doctor-follows">
                                    <h5>Status</h5>
                                    <h5>Active</h5>
                                  </div>
                                  <div className="doctor-follows">
                                    <h5>Mobile No.</h5>
                                    <h5>987987654</h5>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="col-lg-4 col-md-4 d-flex align-items-center">
                                <div className="follow-btn-group">
                                  <button
                                    type="submit"
                                    className="btn btn-info follow-btns"
                                  >
                                    Follow
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn btn-info message-btns"
                                  >
                                    Message
                                  </button>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">
                    </div>
                    <div className="col-lg-12">
                      <div className="doctor-personals-grp">
                        <div className="card">
                          <div className="card-body">
                            <div className="tab-content-set">
                              <ul className="nav">
                                <li>
                                  <Link to="/patient-treatment">
                                    <span className="set-about-icon me-2">
                                      <img src={menuicon16} alt="" />
                                    </span>
                                    Treatment
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/patient-prescription"
                                  >
                                    <span className="set-about-icon me-2">
                                      <img src={menuicon16} alt="" />
                                    </span>
                                    Prescription
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/patient-payment"
                                  className="active">
                                    <span className="set-about-icon me-2">
                                      <img src={menuicon16} alt="" />
                                    </span>
                                    Payments
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/patientsprofile"
                                  >
                                    <span className="set-about-icon me-2">
                                      <img src={doctor} alt="" />
                                    </span>
                                    About me
                                  </Link>
                                </li>
                              </ul>
                            </div>
                        <div className="card">
                          <div className="card-header">
                                <div className="row">
                                  <div className="col-md-6">
                                    <h4 className="card-title">Payment</h4>
                                  </div>
                                  <div className="col-md-6 text-end">
                                    <button
                                      type="submit"
                                      className="btn btn-info add-button"
                                    >
                                      Add Payment
                                    </button>
                                  </div>
                                </div>
                          </div>
                          <div className="card-body p-0 table-dash">
                            <div className="table-responsive">
                              <table className="table mb-0 border-0 datatable custom-table patient-profile-table">
                                <thead>
                                  <tr>
                                    <th>
                                      <div className="form-check check-tables">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          defaultValue="something"
                                        />
                                      </div>
                                    </th>
                                    <th>Date</th>
                                    <th>Doctor</th>
                                    <th>Treatment</th>
                                    <th>Charges ($)</th>
                                    <th />
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div className="form-check check-tables">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          defaultValue="something"
                                        />
                                      </div>
                                    </td>
                                    <td>29/09/2022 </td>
                                    <td>Dr.Jenny Smith</td>
                                    <td>Check up</td>
                                    <td>$ 60</td>
                                    <td className="text-end">
                                      <div className="dropdown dropdown-action">
                                        <Link
                                          to="#"
                                          className="action-icon dropdown-toggle"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i className="fa fa-ellipsis-v" />
                                        </Link>
                                        <div className="dropdown-menu dropdown-menu-end">
                                          <Link
                                            className="dropdown-item"
                                            to="/editappoinments"
                                          >
                                            <i className="fa-solid fa-pen-to-square m-r-5" />{" "}
                                            Edit
                                          </Link>
                                          <Link
                                            className="dropdown-item"
                                            to="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#delete_patient"
                                          >
                                            <i className="fa fa-trash-alt m-r-5"></i>{" "}
                                            Delete
                                          </Link>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="form-check check-tables">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          defaultValue="something"
                                        />
                                      </div>
                                    </td>
                                    <td>19/09/2022 </td>
                                    <td>Andrea Lalema</td>
                                    <td>Blood Test </td>
                                    <td>$ 50</td>
                                    <td className="text-end">
                                      <div className="dropdown dropdown-action">
                                        <Link
                                          to="#"
                                          className="action-icon dropdown-toggle"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i className="fa fa-ellipsis-v" />
                                        </Link>
                                        <div className="dropdown-menu dropdown-menu-end">
                                          <Link
                                            className="dropdown-item"
                                            to="/editappoinments"
                                          >
                                            <i className="fa-solid fa-pen-to-square m-r-5" />{" "}
                                            Edit
                                          </Link>
                                          <Link
                                            className="dropdown-item"
                                            to="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#delete_patient"
                                          >
                                            <i className="fa fa-trash-alt m-r-5"></i>{" "}
                                            Delete
                                          </Link>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="form-check check-tables">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          defaultValue="something"
                                        />
                                      </div>
                                    </td>
                                    <td>20/09/2022 </td>
                                    <td>Dr.William Stephin</td>
                                    <td>Blood Pressure</td>
                                    <td>$ 30</td>
                                    <td className="text-end">
                                      <div className="dropdown dropdown-action">
                                        <Link
                                          to="#"
                                          className="action-icon dropdown-toggle"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i className="fa fa-ellipsis-v" />
                                        </Link>
                                        <div className="dropdown-menu dropdown-menu-end">
                                          <Link
                                            className="dropdown-item"
                                            to="/editappoinments"
                                          >
                                            <i className="fa-solid fa-pen-to-square m-r-5" />{" "}
                                            Edit
                                          </Link>
                                          <Link
                                            className="dropdown-item"
                                            to="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#delete_patient"
                                          >
                                            <i className="fa fa-trash-alt m-r-5"></i>{" "}
                                            Delete
                                          </Link>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="notification-box">
              <div className="msg-sidebar notifications msg-noti">
                <div className="topnav-dropdown-header">
                  <span>Messages</span>
                </div>
                <div className="drop-scroll msg-list-scroll" id="msg_list">
                  <ul className="list-box">
                    <li>
                      <Link to="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">R</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">
                              Richard Miles{" "}
                            </span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="chat.html">
                        <div className="list-item new-message">
                          <div className="list-left">
                            <span className="avatar">J</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">John Doe</span>
                            <span className="message-time">1 Aug</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">T</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">
                              {" "}
                              Tarah Shropshire{" "}
                            </span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">M</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">Mike Litorus</span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">C</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">
                              {" "}
                              Catherine Manseau{" "}
                            </span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">D</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">
                              {" "}
                              Domenic Houston{" "}
                            </span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">B</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">
                              {" "}
                              Buster Wigton{" "}
                            </span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">R</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">
                              {" "}
                              Rolland Webber{" "}
                            </span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">C</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">
                              {" "}
                              Claire Mapes{" "}
                            </span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">M</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">
                              Melita Faucher
                            </span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">J</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">
                              Jeffery Lalor
                            </span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">L</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">Loren Gatlin</span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">T</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">
                              Tarah Shropshire
                            </span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="topnav-dropdown-footer">
                  <Link to="chat.html">See all messages</Link>
                </div>
              </div>
            </div>
          </div>
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link
                      to="#"
                      className="btn btn-white me-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button type="submit" className="btn btn-danger">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    </div>
  );
};

export default PatientsProfile;
