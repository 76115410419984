/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { DatePicker } from "antd";
import Select from "react-select";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Api from "../../../helper/api";
import AuthApi from "../../../helper/authApi";
import { loadingService } from "../../../helper/observer";

const AddExpenses = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [item_name, setItemName] = useState("");
  const [purchase_date, setPurchaseDate] = useState("");
  const [purchase_from, setPurchaseFrom] = useState("");
  const [age, setAge] = useState("");
  const [purchase_by, setPurchaseBy] = useState("");
  const [amount, setAmount] = useState("");
  const [paid_by, setPaidBy] = useState("");
  const [payment_status, setPaymentStatus] = useState("");
  const [other_info, setOtherInfo] = useState("");
  const [receipt, setReceipt] = useState("");
  const [userData, setUserData] = useState(null);

  const [status, setStatus] = useState([
    { value: 1, label: "Select statue" },
    { value: 2, label: "Approved" },
    { value: 3, label: "Rejected" },
    { value: 4, label: "Pending" },
  ]);
  const selectedStatus = status.find(
    (option) => option.value === payment_status
  );

  const [paymentMethod, setPaymentmethod] = useState([
    { value: 1, label: "Select Card" },
    { value: 2, label: "Cheque" },
    { value: 3, label: "Debit Card" },
    { value: 4, label: "Pay Pal " },
  ]);
  const selectedPaymentmethod = paymentMethod.find(
    (option) => option.value === paid_by
  );

  const [purchased, setPurchased] = useState([
    { value: 2, label: "Bernardo James" },
    { value: 3, label: "Galaviz Lalema" },
    { value: 4, label: "Tarah Williams" },
  ]);
  const selectedPurchased = purchased.find(
    (option) => option.value === purchase_by
  );
  const loadFile = (event) => {
    // Handle file loading logic here
  };

  const SubmitForm = async (e) => {
    e.preventDefault();

    if (!item_name) {
      toast.error("Please fill out all required fields.");
      return;
    }

    let payload = {
      item_name: item_name,
      purchase_date: purchase_date,
      purchase_from: purchase_from,
      age: age,
      purchase_by: purchase_by,
      amount: amount,
      paid_by: paid_by,
      payment_status: payment_status,
      other_info: other_info,
      receipt: receipt,
    };

    try {
      loadingService.next(true);

      const { data } = await AuthApi.post(Api.addexpense, payload);
      loadingService.next(false);

      if (data) {
        toast.success("Success");
        navigate("/expenses");
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);

    setUserData(data.data);
  }, []);

  return (
    <>
      <Header />
      <Sidebar />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/expense-Report">Accounts </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Expense</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={SubmitForm}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Add Expenses</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Item Name <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={item_name}
                              onChange={(e) => setItemName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Purchase From{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={purchase_from}
                              onChange={(e) => setPurchaseFrom(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms cal-icon">
                            <label>
                              Purchase Date{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <DatePicker
                              className="form-control datetimepicker"
                              selected={purchase_date}
                              onChange={(date) => setPurchaseDate(date)}
                              placeholderText="Select date"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Purchased By{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <Select
                              defaultValue={purchase_by}
                              value={selectedPurchased}
                              onChange={(option) => setPurchaseBy(option.value)}
                              options={purchased}
                              id="search-commodity"
                              components={{ IndicatorSeparator: () => null }}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "none"
                                    : "2px solid rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "2px solid rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Amount <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={amount}
                              onChange={(e) => setAmount(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Paid By <span className="login-danger">*</span>
                            </label>
                            <Select
                              defaultValue={paid_by}
                              value={selectedPaymentmethod}
                              onChange={(option) => setPaidBy(option.value)}
                              options={paymentMethod}
                              id="search-commodity"
                              components={{ IndicatorSeparator: () => null }}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "none"
                                    : "2px solid rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "2px solid rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Payment Status{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <Select
                              defaultValue={payment_status}
                              value={selectedStatus}
                              onChange={(option) =>
                                setPaymentStatus(option.value)
                              }
                              options={status}
                              id="search-commodity"
                              components={{ IndicatorSeparator: () => null }}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "none"
                                    : "2px solid rgba(46, 55, 164, 0.1)",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "2px solid rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12">
                          <div className="form-group local-forms">
                            <label>
                              Other Information{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <textarea
                              className="form-control"
                              rows={3}
                              cols={30}
                              value={other_info}
                              onChange={(e) => setOtherInfo(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Create Expense
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary cancel-form"
                              onClick={() => navigate("/expenses")}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default AddExpenses;
