/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import GeneralSettingsHeader from "../../GeneralSettingsHeader";
import OrganisationDropdown from "../../OrganisationDropdown";
import { DatePicker } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { favicon } from "../../imagepath";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Api from "../../../helper/api";
import toast from "react-hot-toast";
import AuthApi from "../../../helper/authApi";
import { loadingService } from "../../../helper/observer";

const AddUser = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile_number, setMobile_number] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [is_active, setIs_Active] = useState("");



    // to ADD Role
  const SubmitForm = async (e) => {
    e.preventDefault();

    if (!name) {
      toast.error("Please fill out all required fields.");
      return;
    }

    let payload = {
      name: name,
      email: email,
      mobile_number: mobile_number,
      password: password,
      role: role,
      organisation: organisation,
    };

    try {
      loadingService.next(true);

      const { data } = await AuthApi.post(Api.addusers, payload);
      loadingService.next(false);

      if (data) {
        toast.success("Success");
        navigate("/userslist");
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);

    setUserData(data.data);
  }, []);


    return (
      <div>
        <Header />
        <Sidebar activeClassName="setting" />
  
        <div className="page-wrapper">
          {/* Page Content */}
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="index.html">Dashboard </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Settings</li>
                  </ul>
                </div>
              </div>
            </div>
            <OrganisationDropdown/>
            <GeneralSettingsHeader />{" "}
            {/* Header for General Settings Page only */}
            <div className="row">
              <div className="col-md-8">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Add User</h5>
                  </div>
                  <div className="card-body pt-0">
                    <form onSubmit={SubmitForm}>
                      <div className="settings-form">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                User Name <span className="star-red">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter User Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Email <span className="star-red">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.email)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                              Mobile Number <span className="star-red"></span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Mobile Number"
                                value={mobile_number}
                                onChange={(e) => setMobile_number(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                               Password <span className="star-red"></span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                              Role <span className="star-red"></span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Role"
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                               Organisation <span className="star-red"></span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Organisation"
                                value={organisation}
                                onChange={(e) => setOrganisation(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <label>WhatsApp Services</label>
                                    <div className="status-toggle d-flex justify-content-between align-items-center">
                                    <input
                                        type="checkbox"
                                        id="status_6"
                                        className="check"
                                        defaultChecked="true"
                                    />
                                    <label htmlFor="status_6" className="checktoggle">
                                        checkbox
                                    </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h5 className="card-title">Is Active</h5>
                                    <div className="status-toggle d-flex justify-content-between align-items-center">
                                    <input
                                        type="checkbox"
                                        id="status_6"
                                        className="check"
                                        defaultChecked="true"
                                    />
                                    <label htmlFor="status_6" className="checktoggle">
                                        checkbox
                                    </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group mb-0">
                          <div className="settings-btns">
                            <button
                              type="submit"
                              className="border-0 btn btn-primary btn-gradient-primary btn-rounded"
                            >
                              Update
                            </button>
                            &nbsp;&nbsp;
                            <button
                              type="submit"
                              className="btn btn-secondary btn-rounded"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default AddUser;
