import React, { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Table } from "antd";

import {
  cameraicon,
  doctor,
  imagesend,
  menuicon16,
  demopatient,
} from "../imagepath";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

const PatientsProfile = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedPatientId, setSelectedPatientId] = useState(null);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  // Sample data (replace this with your actual data fetching logic)
  const [data, setData] = useState([
    {
      id: 1,
      treatment_date: "29/09/2022",
      treatment: "Dr.Jenny Smith",
      diagnosis: "Check up",
    },
    {
      id: 2,
      treatment_date: "30/09/2022",
      treatment: "Dr.John Doe",
      diagnosis: "Follow-up",
    },
  ]);
  const columns = [
    {
      title: "Sr No",
      dataIndex: "srNo",
      render: (index) => index + 1,
    },
    {
      title: "Treatment Date",
      dataIndex: "treatment_date",
      sorter: (a, b) => new Date(a.treatment_date) - new Date(b.treatment_date),
    },
    {
      title: "Treatment",
      dataIndex: "treatment",
      sorter: (a, b) => a.treatment.length - b.treatment.length,
    },
    {
      title: "Diagnosis",
      dataIndex: "diagnosis",
      sorter: (a, b) => a.diagnosis.length - b.diagnosis.length,
    },
    {
      title: "Action",
      dataIndex: "id",
      render: () => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle ms-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                {/* <Link
                  className="dropdown-item"
                  to={`/edittreatment/${record.id}`}
                >
                  <i className="far fa-edit me-2" />
                  Edit
                </Link> */}
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#delete_patient"
                  onClick={() => setSelectedPatientId()}
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <>
        <Header />
        <Sidebar id="menu-item2" id1="menu-items2" activeClassName="patient" />
        <>
          <div className="page-wrapper">
            <div className="content">
              {/* Page Header */}
              <div className="page-header">
                <div className="row">
                  <div className="col-sm-12">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#">Patients </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <i className="feather-chevron-right">
                          <FeatherIcon icon="chevron-right" />
                        </i>
                      </li>
                      <li className="breadcrumb-item active">
                        Patient Profile
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="about-info">
                            <h4>
                              Patients Profile{" "}
                              <span>
                                <Link to="#">
                                  <i className="feather-more-vertical">
                                    <FeatherIcon icon="more-vertical" />
                                  </i>
                                </Link>
                              </span>
                            </h4>
                          </div>
                          <div className="doctor-profile-head">
                            {/* <div className="profile-bg-img">
                              <img src={profilebg} alt="Profile" />
                            </div> */}
                            <div className="row">
                              <div className="col-lg-4 col-md-4">
                                <div className="profile-user-box">
                                  <div className="profile-user-img">
                                    <img src={demopatient} alt="demopatient" />
                                    <div className="form-group doctor-up-files profile-edit-icon mb-0">
                                      <div className="uplod d-flex">
                                        <label className="file-upload profile-upbtn mb-0">
                                          <img src={cameraicon} alt="Profile" />
                                          <input type="file" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="names-profiles">
                                    <h4>Smith Bruklin</h4>
                                    <h5>Patient</h5>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 d-flex align-items-center">
                                <div className="follow-group">
                                  <div className="doctor-follows">
                                    <h5>Gender</h5>
                                    <h5>Male</h5>
                                  </div>
                                  <div className="doctor-follows">
                                    <h5>Age</h5>
                                    <h5>28</h5>
                                  </div>
                                  <div className="doctor-follows">
                                    <h5>Status</h5>
                                    <h5>Active</h5>
                                  </div>
                                  <div className="doctor-follows">
                                    <h5>Mobile No.</h5>
                                    <h5>987987654</h5>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="col-lg-4 col-md-4 d-flex align-items-center">
                                <div className="follow-btn-group">
                                  <button
                                    type="submit"
                                    className="btn btn-info follow-btns"
                                  >
                                    Follow
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn btn-info message-btns"
                                  >
                                    Message
                                  </button>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-12">
                      <div className="doctor-personals-grp">
                        <div className="card">
                          <div className="card-body">
                            <div className="tab-content-set">
                              <ul className="nav">
                                <li>
                                  <Link
                                    to="/patient-treatment"
                                    className="active"
                                  >
                                    <span className="set-about-icon me-2">
                                      <img src={menuicon16} alt="" />
                                    </span>
                                    Treatment
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/patient-prescription">
                                    <span className="set-about-icon me-2">
                                      <img src={menuicon16} alt="" />
                                    </span>
                                    Prescription
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/patient-payment">
                                    <span className="set-about-icon me-2">
                                      <img src={menuicon16} alt="" />
                                    </span>
                                    Payments
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/patientsprofile">
                                    <span className="set-about-icon me-2">
                                      <img src={doctor} alt="" />
                                    </span>
                                    About me
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="card">
                              <div className="card-header">
                                <div className="row">
                                  <div className="col-md-6">
                                    <h4 className="card-title">Treatment</h4>
                                  </div>
                                  <div className="col-md-6 text-end">
                                    <Link
                                      type="submit"
                                      className="btn btn-info add-button"
                                      to="/treatment_planlist"
                                    >
                                      Add Treatment
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body p-0 table-dash">
                                <div className="table-responsive">
                                  <Table
                                    className="table table-stripped table-hover datatable thead-light"
                                    pagination={{
                                      total: data.length,
                                      showTotal: (total, range) =>
                                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                    }}
                                    columns={columns}
                                    dataSource={data}
                                    rowSelection={rowSelection}
                                    rowKey={(record) => record.id}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link
                      to="#"
                      className="btn btn-white me-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        handleDelete(selectedPatientId);
                        setSelectedPatientId(null); // Reset selected patient ID
                        document
                          .querySelector('[data-bs-dismiss="modal"]')
                          .click(); // Close modal
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    </div>
  );
};

export default PatientsProfile;
