/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { favicon } from "../imagepath";
import Select from "react-select";
import {
  // blogimg10,
  // blogimg12,
  // blogimg2,
  // blogimg6,
  // blogimg8,
  // imagesend,
  pdficon,
  pdficon2,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
} from "../imagepath";
import { Table } from "antd";
import { loadingService } from "../../helper/observer";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";

import { DatePicker, Space } from "antd";
//   import FeatherIcon from "feather-icons-react/build/FeatherIcon";
//   import Select from "react-select";
import { onShowSizeChange, itemRender } from "../Pagination";
import { useNavigate } from "react-router-dom";

const AddonsList = () => {
  const navigate = useNavigate();
  const [showDelete, setShowDelete] = useState(false);
  const [showOrganisationid, setShowOrganisationid] = useState(false);
  const [Addons, setAddons] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [fromDate, setFromDate] = useState(null); // State for "from" date
  const [toDate, setToDate] = useState(null); // State for "to" date

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };
  

  const handleDelete = async () => {
    if (!showOrganisationid) return;

    loadingService.next(true);
    try {
      const response = await AuthApi.delete(
        `${Api.deleteaddons}/${showOrganisationid}`
      );
      loadingService.next(false);

      if (response) {
        toast.success("Deleted Successfully");
        setShowDelete(false);
        fetchData();
        // Optionally refresh the list or perform other UI updates
      }
    } catch (error) {
      loadingService.next(false);
      toast.error("An error occurred while deleting");
    }
  };
  const applyFilter = () => {
    if (!fromDate && !toDate) return;
    // fetchData(true);
  };

  const resetFilter = () => {
    setFromDate(null);
    setToDate(null);
    // fetchData();
  };

  //record

  const columns = [
    {
      title: "Sr No.",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length
    },
      
    {
      title: "Description ",
      dataIndex: "description",
      sorter: (a, b) => a.description.length - b.description.length,
    },
    
      {
        title: "Price",
        dataIndex: "price",
        sorter: (a, b) => a.price.length - b.price.length,
      },
      {
        title: "is_per_branch",
        dataIndex: "is_per_branch",
        sorter: (a, b) => a.is_per_branch.length - b.is_per_branch.length,
      },
      {
        title: "is_active",
        dataIndex: "is_active",
        sorter: (a, b) => a.is_active.length - b.is_active.length,
      },
    {
      title: "Actions",
      dataIndex: "",
      render: (text, record) => (
        <>
        <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle ms-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link className="dropdown-item" 
                to={`/editons/${record.id}`}>
                  <i className="far fa-edit me-2" />
                  Edit
                </Link>
                <Link
                className="dropdown-item"
                  onClick={() => {
                    setShowDelete(true);
                    setShowOrganisationid(record.id);
                  }}
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
              </div>
            </div>
          </div>

        </>
      ),
    },
  ];

  
  const fetchData = async () => {
    try {
      // You may not need this line unless you're using user data later
      const udata = localStorage.getItem("userdata");
      const userData = JSON.parse(udata);
  
      // Fetch the addons list from the API
      const response = await AuthApi.get(Api.AddonsList);
      console.log("API response received:", response); // Debug point
  
      // Check if response structure is as expected
      if (response.data && response.data.status === 'success') {
        const responseData = response.data.data;
        console.log("Parsed response data:", responseData); // Debug point
        setAddons(responseData); // Assuming responseData is an array
      } else {
        console.error("Error in API response:", response.data.message);
        setAddons([]); // Handle unexpected structure
      }
    } catch (error) {
      console.error("Error fetching addons list:", error.message);
      toast.error("An error occurred while fetching addons list");
      setAddons([]); // Set to an empty array on failure
    }
  };

const filteredAddons = Array.isArray(Addons)
? Addons.filter((item) =>
    Object.values(item).some((value) =>
      value && typeof value === "string" && 
      value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  )
: [];
  useEffect(() => {
    fetchData();
  }, []);


  return (
    <div>
      <Header />
      <Sidebar activeClassName="setting" />

      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="index.html">Dashboard </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Settings</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          
          {/* user list */}

          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Add Ons</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              <form>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                  onChange={handleSearch}
                                />
                                <Link className="btn">
                                  <img src={searchnormal} alt="#" />
                                </Link>
                              </form>
                            </div>
                            <div className="add-group">
                              <Link
                                to="/Addonspage"
                                className="btn btn-primary add-pluss ms-2"
                              >
                                <img src={plusicon} alt="#" />
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        <Link to="#" className=" me-2">
                          <img src={pdficon} alt="#" />
                        </Link>
                        <Link to="#" className=" me-2">
                          <img src={pdficon2} alt="#" />
                        </Link>
                        <Link to="#" className=" me-2">
                          <img src={pdficon3} alt="#" />
                        </Link>
                        <Link to="#">
                          <img src={pdficon4} alt="#" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}

                  <div className="table-responsive">
                    <Table
                      pagination={{
                        total: filteredAddons.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        // showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={filteredAddons}
                      rowSelection={rowSelection}
                      rowKey={(record) => record.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
       
        {/* delete show button */}
        <Modal show={showDelete} onHide={() => setShowDelete(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Are you sure want to delete this ?</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setShowDelete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleDelete} // Call the delete function here
            >
              Delete
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
    
  );
};

export default AddonsList;
