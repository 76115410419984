/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { loadingService } from "../../../helper/observer";
import AuthApi from "../../../helper/authApi";
import Api from "../../../helper/api";

const EditTreatment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [detail, setDetail] = useState("");

  const getTreatmenttInfo = async () => {
    try {
      const response = await AuthApi.get(`${Api.infotreatmentmaster}/${id}`);
      const { data, message, status } = response;

      if (!data) {
        toast.success(message);
        if (status === 401) navigate("/login");
        return;
      }

      const treatment = data.data;
      setName(treatment.name);
      setDetail(treatment.detail);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTreatmenttInfo();
  }, []);

  const SubmitForm = async (e) => {
    e.preventDefault();

    if (!name || !detail) {
      toast.error("Please fill out all required fields.");
      return;
    }

    let payload = {
      name: name,
      detail: detail,
    };

    try {
      const { status } = await AuthApi.put(
        `${Api.updatetreatmentmaster}/${id}`,
        payload
      );
      if (status) {
        toast.success("Success");
        navigate("/treatment_list");
      }
    } catch (error) {
      toast.error(error);
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item22"
        id1="menu-items22"
        activeClassName="edit-treatment"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Treatment</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Edit Treatment</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={SubmitForm}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Treatment Details</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Name of Treatment{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              placeholder="Enter treatment name"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Detail <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              value={detail}
                              onChange={(e) => setDetail(e.target.value)}
                              placeholder="Enter Detail"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary cancel-form"
                              onClick={() => navigate("/treatment_list")}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default EditTreatment;
