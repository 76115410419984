import React from 'react'
import Header from '../Header'
import Sidebar from '../Sidebar'
import { logodark, signature } from '../imagepath'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import toast from "react-hot-toast";
import { loadingService } from "../../helper/observer";

const Prescription = () => {

// Function to generate and download the PDF Blob
const generateAndDownloadPDF = async () => {
    const input = document.getElementById('prescription'); // Make sure this ID matches the correct element

    // Create a canvas from the HTML element
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    const imgWidth = 190; // Adjust this to fit your PDF page width
    const pageHeight = pdf.internal.pageSize.height;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    let position = 0;

    // Add the image to the PDF
    pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
    }

    // Create a Blob from the PDF
    const pdfBlob = pdf.output('blob');

    // Download the PDF locally
    const downloadLink = document.createElement('a');
    const url = URL.createObjectURL(pdfBlob);
    downloadLink.href = url;
    downloadLink.download = 'prescription.pdf'; // The name of the downloaded file
    downloadLink.click();
    URL.revokeObjectURL(url); // Clean up the URL object

    console.log('PDF downloaded and ready to be sent to the backend');

    return pdfBlob; // Return the Blob for sending to the backend
};

// Function to send the PDF Blob to the backend
const sendPDFToBackend = async (pdfBlob) => {
    const formData = new FormData();
    formData.append('prescription', pdfBlob, 'prescription.pdf');

    // Log FormData contents
    console.log('FormData contents:');
    for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1] instanceof File ? pair[1].name : pair[1]}`);
    }

    try {
        loadingService.next(true);
        const { response } = await AuthApi.post(Api.prescriptions, formData); // Send formData to backend
        loadingService.next(false);

        if (!response.ok) {
            toast.error('Network response was not ok');
        }

        const data = await response.json();
        console.log('PDF uploaded successfully:', data);
    } catch (error) {
        console.error('Error uploading PDF:', error);
    }
};

// Example usage
const handleDownloadAndUploadPDF = async () => {
    const pdfBlob = await generateAndDownloadPDF(); // Generate the PDF Blob and download it locally
    sendPDFToBackend(pdfBlob); // Send the Blob to your backend
};




    
    return (
        <>
            <Header />
            <Sidebar id='menu-item13' id1='menu-items13' activeClassName='invoice-details'/>
            <>
                <div className="page-wrapper">
                    {/* Page Content */}
                    <div className="content container-fluid" id="prescription">
                        <div className="row justify-content-center">
                            <div className="col-xl-10">
                                <div className="card invoice-info-card">
                                    <div className="card-body">
                                        <div className="invoice-item invoice-item-one">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="invoice-logo">
                                                        <img src={logodark} alt="logo" />
                                                        <button onClick={handleDownloadAndUploadPDF} className="btn btn-primary">Download PDF</button>                                                    </div>
                                                    <div className="invoice-head">
                                                        <h2>Clinic Name</h2>
                                                        <p>Clinic Registration Number : In983248782</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="invoice-info">
                                                        <strong className="customer-text-one">Dr Name</strong>
                                                        <h6 className="invoice-name">Dr Speciallisation</h6>
                                                        <p className="invoice-details"> Dr Qualification</p><br />
                                                        <p className="invoice-details">
                                                            082/45RD/12456
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Invoice Item */}
                                        <div className="invoice-item invoice-item-two">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="invoice-info">
                                                        {/* <strong className="customer-text-one">Billed to</strong> */}
                                                        
                                                        <h5 className="invoice-name">Patient Name: <span>Test-ADMsoft</span></h5>
                                                        <p className="invoice-details invoice-details-two"><span>Age: 25</span></p><br />
                                                        <p className="invoice-details invoice-details-two"><span>Sex: Male</span></p><br />
                                                        <p className="invoice-details invoice-details-two"><span>mobile: 9878654565</span></p><br />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="invoice-info invoice-info2">
                                                        <strong className="customer-text-one">Date: 23-10-2024</strong>
                                                        <p className="invoice-details">
                                                        <h6 className="invoice-name">Patient ID: <span>BH-123</span></h6>
                                                        </p>
                                                        {/* <div className="invoice-item-box">
                                                            <p>Recurring : 15 Months</p>
                                                            <p className="mb-0">PO Number : 54515454</p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div><br/>
                                        <div className="invoice-item black-border">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="invoice-info">
                                                    <img src={'/rximage.png'} alt="My Image" className="rximg" width="50px" />
                                                    <h6 className="invoice-name">Symptoms: <span> RIGHT EAR PAIN WITH BLOCK, PRECEEDING COLD+</span></h6><br />
                                                    <h6 className="invoice-name">Findings: <span> NA</span></h6><br />
                                                    <h6 className="invoice-name">Prdocedure: <span> NA</span></h6><br />

                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="invoice-info invoice-info2">
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* /Invoice Item */}
                                        {/* Invoice Item */}
                                                {/* <div className="invoice-issues-box">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4">
                                                            <div className="invoice-issues-date">
                                                                <p>Issue Date : 27 Jul 2022</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4">
                                                            <div className="invoice-issues-date">
                                                                <p>Due Date : 27 Aug 2022</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4">
                                                            <div className="invoice-issues-date">
                                                                <p>Due Amount : ₹ 1,54,22 </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                        {/* /Invoice Item */}
                                        {/* Invoice Item */}
                                        <div className="invoice-item invoice-table-wrap">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="table-responsive">
                                                        <table className="invoice-table table table-center mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sr No.</th>
                                                                    <th>Medicine</th>
                                                                    <th>Frequency</th>
                                                                    <th>Notes</th>
                                                                    <th>Duration</th>
                                                                    {/* <th className="text-end">Amount</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>1</td>
                                                                    <td>Tab. Lanzol Junior 30</td>
                                                                    <td>1-0-0</td>
                                                                    <td>Before Meal</td>
                                                                    <td>6 DAYS</td>
                                                                    {/* <td className="text-end">$400</td> */}
                                                                </tr>
                                                                <tr>
                                                                    <td>3</td>
                                                                    <td>T-Minic Syrup</td>
                                                                    <td>1-3-0</td>
                                                                    <td>After Meal</td>
                                                                    <td>5 DAYS</td>
                                                                    {/* <td className="text-end">$3,000</td> */}
                                                                </tr>
                                                                <tr>
                                                                    <td>4</td>
                                                                    <td>T-Minic Syrup</td>
                                                                    <td>1-2-1</td>
                                                                    <td>After Meal</td>
                                                                    <td>2 DAYS</td>
                                                                    {/* <td className="text-end">$3,000</td> */}
                                                                </tr>
                                                                <tr>
                                                                    <td>5</td>
                                                                    <td>T-Minic Syrup</td>
                                                                    <td>1-1-1</td>
                                                                    <td>After Meal</td>
                                                                    <td>4 DAYS</td>
                                                                    {/* <td className="text-end">$3,000</td> */}
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* /Invoice Item */}
                                        <div className="row align-items-center justify-content-center">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="invoice-terms">
                                                    <h6>Instructions:</h6>
                                                    <p className="mb-0">Enter customer notes or any other details</p>
                                                </div>
                                                <div className="invoice-terms">
                                                    <h6>Next Visit:</h6>
                                                    <p className="mb-0">Enter customer notes or any other details</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="doctor-sign text-end">
                                                    <img className="img-fluid d-inline-block" src={signature} alt="sign" />
                                                    <span className="doctor-signature-label">Signature</span>
                                                    <div className="doctordetails">
                                                    <h6 className="doctor-name">Dr Name Here</h6>
                                                    <h5 className="doctor-name">OralSpeciality Surgeon</h5>
                                                    <h5 className="doctor-name">Mbbs (MD) Dentology</h5>
                                                    <h5 className="doctor-name">Reg. No.: 202123122</h5>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {/* <div className="invoice-sign text-end">
                                            
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /Page Content */}
                </div>
            </>
        </>
    )
}

export default Prescription
