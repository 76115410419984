/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
  doctor,
  menuicon16  
} from "../imagepath";
import { onShowSizeChange, itemRender } from "../Pagination";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { DatePicker, Space } from "antd";
import Select from "react-select";
import { TextField } from "@mui/material";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import { loadingService } from "../../helper/observer";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { Payment } from "@mui/icons-material";

const AppoinmentList = () => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedContact, setSelectedContact] = useState([]);
  // const [content, setContent] = useState("");


  const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedContact(newSelectedRows);
  };
  console.log("selectedRowKeys changed: ", selectedRowKeys);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // -------------------------------
  const [isClicked, setIsClicked] = useState(false);
  
  
  const [SelectedConsultDoctor, setSelectedConsultDoctor] = useState(null);
  
  const [SelectedRefDoctor, setSelectedRefDoctor] = useState(null);
  
  const [editdateOfAppointment, setEditDateOfAppointment] = useState("");
  const [editstartTime, setEditStartTime] = useState();
  const [editendTime, setEditEndTime] = useState();
  const [editSelectedPatient, setEditSelectedPatient] = useState(null);
  const [editSelectedConsultDoctor, setEditSelectedConsultDoctor] =
    useState(null);
  const [editSelectedRefDoctor, setEditSelectedRefDoctor] = useState(null);
  const [fromDate, setFromDate] = useState(null); // State for "from" date
  const [toDate, setToDate] = useState(null); // State for "to" date
  const [dropdownValue, setDropdownValue] = useState("");
  const [CustomMessage, setCustomMessage] = useState("");
  const [image_file, setImage_file] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [appointmentist, setAppointmentList] = useState([]);
  // const [Patientlist, setPatientlist] = useState([]);
  const [consultdoctorList, setConsultDoctorList] = useState([]);
  const [refdoctorList, setRefDoctorList] = useState([]);
  const [showAddAppointment, setShowAddAppointment] = useState(false);
  const [showeditAppointment, setShowEditAppointment] = useState(false);
  const [showSendMessage, setShowSendMessage] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [paymentmethod, setPaymentmethod] = useState(false);
  const [paymentoption, setPaymentoption] = useState([
    { value: "", label: "No Charge" },
    { value: "1", label: "Cash" },
    { value: "2", label: "Credit Card/Debit Card" },
    { value: "3", label: "Net Banking" },
    { value: "4", label: "UPI(Online)" },
  ]);
  const [amount, setAmount] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [receipt, setReceipt] = useState("");
  
  //Add Appointement
  const [patientList, setPatientList] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState("");
  const [dateOfAppointment, setDateOfAppointment] = useState("");
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [branchid, setBranchid] = useState("");
  const [ConsultDoctor, setConsultDoctor] = useState([
    // { value: 1, label: "Select Doctor" },
    { value: 2, label: "Dr.Bernardo James" },
    { value: 3, label: "Dr.Andrea Lalema" },
    { value: 4, label: "Dr.William Stephin" },
  ]);
  const [refdoctor, setRefDoctor] = useState("");
  const [activeTab, setActiveTab] = useState("normal"); // default tab is "normal"

  // Start appointlist fetching//
  const [pagination, setPagination] = useState({
    current: 1, // Start at page 1
    pageSize: 10, // Number of items per page
    total: 0, // This will be set based on the total patients from the server
  });
  
  // Define search and ordering states
  const [searchTerm, setSearchTerm] = useState(''); // Initialize search term
  const [orderColumn, setOrderColumn] = useState('first_name'); // Default order column
  const [orderDirection, setOrderDirection] = useState('asc'); 
// End appointlist fetching//

  const [category, setCategory] = useState(null);
  const [options, setOptions] = useState([
    { value: "", label: "Select Organisation" }, // Default option
  ]);

  const getPatientInfo = async () => {
    try {
      const response = await AuthApi.get(`${Api.patientlist}`);
      const { data, status } = response;
  
      if (!data || !data.data || !data.data.data) {  
        toast.error("Failed to load data.");
        if (status === 401) navigate("/login");
        return;
      }
  
      const patients = data.data.data; // This is your array of patients
  
      // Map the patients to the format required by react-select options
      const patientOptions = patients.map((patient) => ({
        value: patient.id, // Assuming each patient has an id
        label: `${patient.first_name} ${patient.last_name}`, // Assuming patient has first_name and last_name
      }));
  
      setPatientList([{ value: "", label: "Select Patient" }, ...patientOptions]);
    } catch (error) {
      console.error(error);
      toast.error("Failed to load patients");
    }
  };
  

  useEffect(() => {
    getPatientInfo();
  }, []);

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const columns = [
    {
      title: "",
      dataIndex: "action",
      render: (text, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                {/* <Link
                  className="dropdown-item"
                  onClick={() => setShow(record.id)}
                  
                >
                  <i className="far fa-edit me-2" />
                  Edit
                </Link> */}
                <Link className="dropdown-item" to="#">
                  <i className="fa fa-print-alt m-r-5"></i> Print Invoive
                </Link>
                <Link className="dropdown-item" to="#">
                  <i className="fa fa-print-alt m-r-5"></i> Print Prescription
                </Link>
                <Link
                  className="dropdown-item"
                  to="#"
                  onClick={() => setShowEditAppointment(record.id)}
                >
                  <i className="fa fa-print-alt m-r-5"></i> Reschedule
                  Appointment
                </Link>
                <Link
                  className="dropdown-item"
                  to="#"
                  onClick={() => setShowDelete(record.id)} // Set the selected ID here
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Sr No",
      dataIndex: "srNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Patient Name",
      dataIndex: "patient",
      sorter: (a, b) => a.patient.length - b.patient.length,
    },
    {
      title: "Age",
      dataIndex: "age",
      sorter: (a, b) => a.age.length - b.age.length,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: (a, b) => a.gender.length - b.gender.length,
    },
    {
      title: "Contact No",
      dataIndex: "contact_no",
      sorter: (a, b) => a.contact_no.length - b.contact_no.length,
      render: (contact_no) => (
        <Link to={`tel:${contact_no}`}>{contact_no}</Link>
      ),
    },
    {
      title: "Date Of Appointment",
      dataIndex: "date_of_appointment",
      sorter: (a, b) =>
        a.date_of_appointment.length - b.date_of_appointment.length,
      render: (date_of_appointment, record) => {
        // Convert the date of birth to a Date object
        const dob = new Date(record.date_of_appointment);

        // Define an array to map month numbers to their respective names
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        // Format the date in the desired format
        const formattedDate = `${dob.getDate()}-${
          monthNames[dob.getMonth()]
        }-${dob.getFullYear()}`;

        if (date_of_appointment) {
          // Return the formatted date
          return formattedDate;
        } else {
          return "";
        }
      },
    },
    {
      title: "From Time",
      dataIndex: "from_time",
      sorter: (a, b) => a.from_time.length - b.from_time.length,
    },
    {
      title: "To Time",
      dataIndex: "to_time",
      sorter: (a, b) => a.to_time.length - b.to_time.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status, record) => {
        const statusOptions = {
          1: "Complete",
          2: "Pending",
          3: "Cancelled",
        };
        const badgeClass =
          status === "1"
            ? "custom-badge status-green"
            : status === "2"
            ? "custom-badge status-orange"
            : "custom-badge status-pink";

        return (
          <Dropdown onSelect={(e) => handleDropdownChange(record.id, e)}>
            <span className={badgeClass}>
              {statusOptions[status]}
              <Dropdown.Toggle variant=""></Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="1">Complete</Dropdown.Item>
                <Dropdown.Item eventKey="2">Pending</Dropdown.Item>
                <Dropdown.Item eventKey="3">Cancelled</Dropdown.Item>
              </Dropdown.Menu>
            </span>
          </Dropdown>
        );
      },
    },
    {
      title: "",
      dataIndex: null, // Or set this to an empty string if required
      render: (text, record) => {
        return (
          <>
            <a
              className="custom-badge status-green me-2"
              onClick={() => setShowPayment(record.id)}
            >
              Take Payment
            </a>
            <br></br>
            <Link
              to={`/add-invoice/${record.id}`}
              className="custom-badge status-blue mt-2"
              href="#"
            >
              Send Invoive
            </Link>
            <br></br>
            <Link
              to={`/create-prescription/${record.id}`}
              className="custom-badge status-purple mt-2"
              href="#"
            >
              Send Prescription
            </Link>
          </>
        );
      },
    },
    
  ];

  const handleDelete = async () => {
    if (!showDelete) return;
    console.log(showDelete);

    loadingService.next(true);
    try {
      const response = await AuthApi.post(
        `${Api.deleteappointment}/${showDelete}`
      );
      loadingService.next(false);

      if (response) {
        toast.success("Deleted Successfully");
        // Optionally refresh the list or perform other UI updates
      }
    } catch (error) {
      loadingService.next(false);
      toast.error("An error occurred while deleting");
    }
  };

  const applyFilter = () => {
    if (!fromDate && !toDate) return;
    // fetchData(true);
  };

  const resetFilter = () => {
    setFromDate(null);
    setToDate(null);
    // fetchData();
  };

  const handleDropdownChange = (value) => {
    setDropdownValue(value);
  };

  const handleRescheduleAppointment = (id) => {
    // Fetch the information using the id
    console.log("Rescheduling appointment for ID:", id);
    // Add logic to fetch or handle appointment details here
  };

  const onFileUpload = (e) => {
    console.log("e.target.files[0]", e.target.files[0]);
    setImage_file(e.target.files[0]);
  };
  const onFilePaymentUpload = (e) => {
    console.log("e.target.files[0]", e.target.files[0]);
    setReceipt(e.target.files[0]);
  };

  const handleSendData = async (event) => {
    event.preventDefault();

    let selectednumber = [];
    selectedContact.map((n) => {
      selectednumber.push(n.contact_no);
    });
    console.log("selectednumber", selectednumber);

    const payload = new FormData();
    payload.append("CustomMessage", CustomMessage);
    payload.append("selectednumber", JSON.stringify(selectednumber));
    if (image_file) {
      payload.append("image_file", image_file, image_file.name);
    } else {
      payload.append("image_file", "");
    }

    try {
      // Send a POST request to your Laravel backend
      loadingService.next(true);

      const { data, message, status } = await AuthApi.post(
        Api.custommessagesend,
        payload,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      loadingService.next(false);

      if (status) {
        toast.success("Success", { position: "top-right" });
        setSelectedRowKeys([]);
        setImage_file("");
      } else {
        toast.error("Error", { position: "top-right" });
      }
    } catch (error) {
      // Handle error
      console.error("Error adding the patient:", error);
    }
  };

  const filteredData = appointmentist.filter((data) =>
    Object.values(data).some(
      (value) =>
        typeof value === "string" && value.toLowerCase().includes(searchQuery)
    )
  );

  const fetchAppointmentData = async (page, pageSize) => {
    try {
      const udata = localStorage.getItem("userdata");
      const data = JSON.parse(udata);
      loadingService.next(true);
  
  // Default order direction; can be changed as per requirement
      const organizationid  = data.user.organization_id;
      const Branchid  = data.user.branch_id;
  
      // Construct the URL with all parameters
      const response = await AuthApi.get(`${Api.patientlist}/${organizationid }?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}&orderColumn=${orderColumn}&orderDirection=${orderDirection}`);
      
      loadingService.next(false);
  
      const { data: responseData, status } = response.data;
      if (responseData) {
        const { data: patients, total, current_page, per_page } = responseData;
  
        // Update the table's pagination state
        setPagination({
          current: current_page,
          pageSize: per_page,
          total: total,
        });
        setBranchid(Branchid);
      } else {
        if (status === 401) navigate("/");
        return;
      }
  
      const appointment = Array.isArray(responseData.data) ? responseData.data : [];
      
      // Filter active and non-deleted patients
      const appointmentlist = appointment.filter(appointment => appointment.is_active && !appointment.is_deleted);
      
      setAppointmentList(appointmentlist);
    } catch (error) {
      console.error("Error fetching patient list:", error);
      loadingService.next(false);
    }
  };
  
  // Example of how to use fetchData with dynamic parameters
  useEffect(() => {
    fetchAppointmentData(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize, searchTerm, orderColumn, orderDirection]);

  

  // Handle pagination changes
  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    fetchAppointmentData(current, pageSize);
  };



  // const fetchData = async () => {
  //   try {
  //     // Fetch userdata from localStorage
  //     const udata = localStorage.getItem("userdata");
  //     console.log("User data from localStorage:", udata); // Debug: print userdata as a string
  
  //     if (!udata) {
  //       console.error("No userdata found in localStorage.");
  //       return;
  //     }
  
  //     // Parse the userdata JSON
  //     const data = JSON.parse(udata); // Parse only if valid JSON is found
  //     loadingService.next(true); // Show loading state
  
  //     // Fetch appointments based on organization_id
  //     const response = await AuthApi.get(`${Api.appointmentlist}/${data.user.organization_id}`);
  //     loadingService.next(false); // Hide loading state
  
  //     console.log("API response:", response); // Debug: print the full response
  
  //     // Check response structure
  //     const { data: responseData, status } = response.data;
  //     if (status === 401) {
  //       console.error("Unauthorized access. Navigating to login.");
  //       navigate("/"); // Redirect if unauthorized
  //       return;
  //     }
  
  //     // Ensure responseData is paginated and data array exists
  //     if (responseData && Array.isArray(responseData.data)) {
  //       console.log("Fetched appointments:", responseData.data); // Debug: print fetched appointments
  
  //       // Filter active and non-deleted patients
  //       const filteredPatients = responseData.data.filter(
  //         (appointment) => appointment.is_active && !appointment.is_deleted
  //       );
  
  //       console.log("Filtered appointments:", filteredPatients); // Debug: print filtered appointments
  //       setAppointmentList(filteredPatients); // Store patients in state
  //     } else {
  //       console.warn("Response data is not in expected format:", responseData); // Debug: warn if format is unexpected
  //     }
  //   } catch (error) {
  //     console.error("Error fetching patient list:", error); // Log any error that occurs
  //     loadingService.next(false); // Ensure loading state is cleared
  //   }
  // };
  
  

  // useEffect(() => {
  //   // fetchData();
  // }, []);

  

  // const getpatientlistData = async () => {
  //   try {
  //     const udata = localStorage.getItem("userdata");
  //     const data = JSON.parse(udata);
  //     loadingService.next(true);

  //     const response = await AuthApi.get(
  //       `${Api.patientlist}/${data.data.user_id}`
  //     );
  //     loadingService.next(false);

  //     const { data: responseData, status } = response.data;
  //     if (!responseData) {
  //       if (status === 401) navigate("/");
  //       return;
  //     }
  //     const { patientlist } = responseData;

  //     setPatientlist(patientlist);
  //   } catch (error) {
  //     console.error("Error fetching form list:", error);
  //   }
  // };

  const fetchdoctorData = async () => {
    try {
      const udata = localStorage.getItem("userdata");
      const data = JSON.parse(udata);
      loadingService.next(true);

      const response = await AuthApi.get(
        `${Api.doctorlist}/${data.data.user_id}`
      );
      loadingService.next(false);

      const { data: responseData, status } = response.data;
      if (!responseData) {
        if (status === 401) navigate("/");
        return;
      }
      const { consultdoctorlist } = responseData;
      const { refdoctorlist } = responseData;

      setConsultDoctorList(consultdoctorlist);
      setRefDoctorList(refdoctorlist);
    } catch (error) {
      console.error("Error fetching form list:", error);
    }
  };

  useEffect(() => {
    // fetchData();
    // fetchdoctorData();
    // getpatientlistData();
  }, []);



  const handleDateChange = (date) => {
    const formattedDate = date.toISOString().split('T')[0]; // Extracts 'YYYY-MM-DD'
    setDateOfAppointment(formattedDate);
  };
  

  const SubmitForm = async (e) => {
    e.preventDefault();
  
    // Validate required fields before sending request
    if (!dateOfAppointment || !startTime || !endTime || !SelectedConsultDoctor) {
      toast.error("Please fill out all required fields.");
      return;
    }
  
    let payload = {
      patient: selectedPatient, // Ensure this is the selected patient
      date_of_appointment: dateOfAppointment,
      from_time: startTime,
      to_time: endTime,
      consult_doc: 24,
      ref_doc: refdoctor,
      branch_id: branchid,
      
    };
  
    try {
      loadingService.next(true);
  
      // Ensure correct API endpoint and request method
      const { data } = await AuthApi.post(Api.addappointment, payload);
      
      loadingService.next(false);
  
      if (data) {
        toast.success("Appointment successfully added.");
        navigate("/medicationlist"); // Ensure navigate works as expected
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      toast.error("An error occurred while submitting the form. Please try again later.");
    }
  };
  
  return (
    <>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="appoinment-list"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-6">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Appoinment</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Appoinment List</li>
                  </ul>
                </div>
                <div className="col-sm-6">
                  <div className="d-flex justify-content-end">
                    <Link to="/appoinmentlist" className="invoices-links active">
                      <i data-feather="list">
                        <FeatherIcon icon="list" />
                      </i>
                    </Link>
                    <Link to="/calender" className="invoices-links">
                      <i data-feather="grid">
                        <FeatherIcon icon="grid" />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* Table Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Appoinment List</h3>
                            <div className="doctor-search-blk">
                              <div className="top-nav-search table-search-blk">
                                <form>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search here"
                                    onChange={handleSearch}
                                  />
                                  <Link className="btn">
                                    <img src={searchnormal} alt="#" />
                                  </Link>
                                </form>
                              </div>
                              <div className="add-group">
                                <a
                                  className="btn btn-primary add-pluss ms-2"
                                  onClick={() => setShowAddAppointment(true)}
                                >
                                  <img src={plusicon} alt="#" />
                                </a>
                                <Link
                                  to="#"
                                  className="btn btn-primary doctor-refresh ms-2"
                                >
                                  <img src={refreshicon} alt="#" />
                                </Link>
                                <button
                                  // className="custom-badge status-green"
                                  className="btn btn-sm btn-success"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => setShowSendMessage(true)}
                                  disabled={selectedRowKeys.length == 0} // Disable if no rows are selected
                                >
                                  Send Message
                                </button>
                                <button
                                  // className="custom-badge status-green"
                                  className="btn btn-sm btn-info"
                                  style={{ marginLeft: "10px" }}
                                >
                                  Todays Appointments
                                </button>
                                <button
                                  // className="custom-badge status-green"
                                  className="btn btn-sm btn-warning"
                                  style={{ marginLeft: "10px" }}
                                >
                                  Tommorows Appointments
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto text-end float-end ms-auto download-grp">
                          <Link to="#" className=" me-2">
                            <img src={pdficon} alt="#" />
                          </Link>
                          <Link to="#" className=" me-2"></Link>
                          <Link to="#" className=" me-2">
                            <img src={pdficon3} alt="#" />
                          </Link>
                          <Link to="#">
                            <img src={pdficon4} alt="#" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 d-inline d-md-flex">
                        <div className="date-picker-container d-inline d-md-flex">
                          <DatePicker
                            selected={fromDate}
                            onChange={(date) => setFromDate(date)}
                            placeholder="Select From Date"
                            className="date-picker-input ms-3 me-3"
                          />
                          <DatePicker
                            selected={toDate}
                            onChange={(date) => setToDate(date)}
                            placeholder="Select To Date"
                            className="date-picker-input"
                          />
                        </div>
                        <div className="col ms-2 d-md-none"></div>{" "}
                        {/* Add this to force the button to next line on mobile */}
                        <div className="col-md-6 d-md-inline d-flex justify-content-md-start justify-content-center align-items-md-center mt-2 mt-md-0 ms-2">
                          <button
                            className="btn btn-primary"
                            style={{ marginRight: "10px" }}
                            onClick={applyFilter}
                            type="button"
                          >
                            Apply
                          </button>
                          <button
                            className="btn btn-light"
                            onClick={resetFilter}
                            type="button"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                    <br></br>
                    {/* /Table Header */}
                    <div className="table-responsive doctor-list">
                      <Table
                        pagination={{
                          current: pagination.current,
                          pageSize: pagination.pageSize,
                          total: pagination.total,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        }}
                        onChange={handleTableChange}
                        columns={columns}
                        dataSource={filteredData}
                        rowSelection={rowSelection}
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showDelete} onHide={() => setShowDelete(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Are you sure want to delete this ?</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={imagesend} alt="#" width={50} height={46} />
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setShowDelete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleDelete} // Call the delete function here
            >
              Send
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={showAddAppointment} onHide={() => setShowAddAppointment(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Add Appointment</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <form onSubmit={SubmitForm}>
      <div className="row">
        <div className="col-12">
          <div className="form-heading">
            <h4>Patient Details</h4>
          </div>
        </div>
        <div className="col-12 col-md-12 col-xl-12">
          <div className="form-group local-forms">
            <label>
              Patient<span className="login-danger">*</span>
            </label>
            <Select
      value={patientList.find((patient) => patient.value === selectedPatient)} // Ensure the selected value is displayed
      onChange={(option) => setSelectedPatient(option.value)}  // Convert value to string
      options={patientList} // Provide patient options here
      menuPortalTarget={document.body}
      id="search-patient"
      components={{ IndicatorSeparator: () => null }}
      styles={{
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999,
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? "none" : "2px solid rgba(46, 55, 164, 0.1)",
          boxShadow: state.isFocused ? "0 0 0 1px #2e37a4" : "none",
          "&:hover": {
            borderColor: state.isFocused ? "none" : "2px solid rgba(46, 55, 164, 0.1)",
          },
          borderRadius: "10px",
          fontSize: "14px",
          minHeight: "45px",
        }),
        dropdownIndicator: (base, state) => ({
          ...base,
          transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
          transition: "250ms",
          width: "35px",
          height: "35px",
        }),
      }}
    />

          </div>
        </div>

        <div className="col-12">
          <div className="form-heading">
            <h4>Appointment Details</h4>
          </div>
        </div>
        <div className="col-12 col-md-12 col-xl-12">
  <div className="form-group local-forms cal-icon">
    <label>
      Date of Appointment <span className="login-danger">*</span>
    </label>
    <DatePicker
  className="form-control datetimepicker"
  selected={new Date(dateOfAppointment)}
  onChange={(date) => handleDateChange(date)}
  dateFormat="yyyy-MM-dd"
  placeholderText="YYYY-MM-DD"
/>

  </div>
</div>

        <div className="col-12 col-md-6 col-xl-6">
          <div className="form-group local-forms">
            <label>
              From <span className="login-danger">*</span>
            </label>
            <TextField
              className="form-control"
              id="outlined-controlled"
              type="time"
              value={startTime}
              onChange={(event) => {
                setStartTime(event.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-6">
          <div className="form-group local-forms">
            <label>
              To <span className="login-danger">*</span>
            </label>
            <TextField
              className="form-control"
              id="outlined-controlled"
              type="time"
              value={endTime}
              onChange={(event) => {
                setEndTime(event.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-6">
          <div className="form-group local-forms">
            <label>
              Consulting Doctor<span className="login-danger">*</span>
            </label>
            <Select
              defaultValue={SelectedConsultDoctor}
              onChange={setSelectedConsultDoctor}
              options={ConsultDoctor}
              menuPortalTarget={document.body}
              id="search-commodity"
              components={{ IndicatorSeparator: () => null }}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused
                    ? "none"
                    : "2px solid rgba(46, 55, 164, 0.1);",
                  boxShadow: state.isFocused ? "0 0 0 1px #2e37a4" : "none",
                  "&:hover": {
                    borderColor: state.isFocused
                      ? "none"
                      : "2px solid rgba(46, 55, 164, 0.1)",
                  },
                  borderRadius: "10px",
                  fontSize: "14px",
                  minHeight: "45px",
                }),
                dropdownIndicator: (base, state) => ({
                  ...base,
                  transform: state.selectProps.menuIsOpen
                    ? "rotate(-180deg)"
                    : "rotate(0)",
                  transition: "250ms",
                  width: "35px",
                  height: "35px",
                }),
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Ref Doctor{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={refdoctor}
                              onChange={(e) => setRefDoctor(e.target.value)}
                            />
                          </div>
                        </div>
            </div>

      {/* Buttons inside form */}
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => setShowAddAppointment(false)}
        >
          Close
        </button>
        <button type="submit" className="btn btn-primary">
          Save
        </button>
      </div>
    </form>
  </Modal.Body>
</Modal>

        <Modal
          show={showeditAppointment}
          onHide={() => setShowEditAppointment(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Appointment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="col-12">
                  <div className="form-heading">
                    <h4>Patient Details</h4>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-xl-12">
                  <div className="form-group local-forms">
                    <label>
                      Patient<span className="login-danger">*</span>
                    </label>
                    <Select
                      defaultValue={editSelectedPatient}
                      onChange={setEditSelectedPatient}
                      options={patientList}
                      menuPortalTarget={document.body}
                      id="search-commodity"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused
                            ? "none"
                            : "2px solid rgba(46, 55, 164, 0.1);",
                          boxShadow: state.isFocused
                            ? "0 0 0 1px #2e37a4"
                            : "none",
                          "&:hover": {
                            borderColor: state.isFocused
                              ? "none"
                              : "2px solid rgba(46, 55, 164, 0.1)",
                          },
                          borderRadius: "10px",
                          fontSize: "14px",
                          minHeight: "45px",
                        }),
                        dropdownIndicator: (base, state) => ({
                          ...base,
                          transform: state.selectProps.menuIsOpen
                            ? "rotate(-180deg)"
                            : "rotate(0)",
                          transition: "250ms",
                          width: "35px",
                          height: "35px",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-heading">
                    <h4>Appointment Details</h4>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-xl-12">
                  <div className="form-group local-forms cal-icon">
                    <label>
                      Date of Appointment{" "}
                      <span className="login-danger">*</span>
                    </label>
                    <DatePicker
                      className="form-control datetimepicker"
                      selected={editdateOfAppointment}
                      onChange={(date) => setEditDateOfAppointment(date)}
                      suffixIcon={null}
                      style={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: isClicked
                            ? "#2E37A4"
                            : "2px solid rgba(46, 55, 164, 0.1)",
                          "&:hover": {
                            borderColor: state.isFocused ? "none" : "none",
                          },
                        }),
                      }}
                    />
                    {/* <input
                        className="form-control datetimepicker"
                        type="text"
                      /> */}
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-6">
                  <div className="form-group local-forms">
                    <label>
                      From <span className="login-danger">*</span>
                    </label>
                    <div className="">
                      <TextField
                        className="form-control"
                        id="outlined-controlled"
                        type="time"
                        value={editstartTime}
                        onChange={(event) => {
                          setEditStartTime(event.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-6">
                  <div className="form-group local-forms">
                    <label>
                      To <span className="login-danger">*</span>
                    </label>
                    <div className="">
                      <TextField
                        className="form-control"
                        id="outlined-controlled"
                        type="time"
                        value={editendTime}
                        onChange={(event) => {
                          setEditEndTime(event.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-6">
                  <div className="form-group local-forms">
                    <label>
                      Consulting Doctor
                      <span className="login-danger">*</span>
                    </label>
                    <Select
                      defaultValue={editSelectedConsultDoctor}
                      onChange={setEditSelectedConsultDoctor}
                      options={ConsultDoctor}
                      menuPortalTarget={document.body}
                      id="search-commodity"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused
                            ? "none"
                            : "2px solid rgba(46, 55, 164, 0.1);",
                          boxShadow: state.isFocused
                            ? "0 0 0 1px #2e37a4"
                            : "none",
                          "&:hover": {
                            borderColor: state.isFocused
                              ? "none"
                              : "2px solid rgba(46, 55, 164, 0.1)",
                          },
                          borderRadius: "10px",
                          fontSize: "14px",
                          minHeight: "45px",
                        }),
                        dropdownIndicator: (base, state) => ({
                          ...base,
                          transform: state.selectProps.menuIsOpen
                            ? "rotate(-180deg)"
                            : "rotate(0)",
                          transition: "250ms",
                          width: "35px",
                          height: "35px",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-6">
                  <div className="form-group local-forms">
                    <label>Refrence Doctor</label>
                    <Select
                      // defaultValue={editSelectedRefDoctor}
                      // onChange={setEditSelectedRefDoctor}
                      // options={RefDoctor}
                      menuPortalTarget={document.body}
                      id="search-commodity"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused
                            ? "none"
                            : "2px solid rgba(46, 55, 164, 0.1);",
                          boxShadow: state.isFocused
                            ? "0 0 0 1px #2e37a4"
                            : "none",
                          "&:hover": {
                            borderColor: state.isFocused
                              ? "none"
                              : "2px solid rgba(46, 55, 164, 0.1)",
                          },
                          borderRadius: "10px",
                          fontSize: "14px",
                          minHeight: "45px",
                        }),
                        dropdownIndicator: (base, state) => ({
                          ...base,
                          transform: state.selectProps.menuIsOpen
                            ? "rotate(-180deg)"
                            : "rotate(0)",
                          transition: "250ms",
                          width: "35px",
                          height: "35px",
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setShowEditAppointment(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                /* Handle save event */
              }}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={showSendMessage} onHide={() => setShowSendMessage(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Send Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-12 col-md-12 col-xl-12">
              <div className="form-group local-top-form">
                <label className="local-top">
                  File upload(Max 5mb) <span className="login-danger"></span>
                </label>
                <div className="settings-btn upload-files-avator">
                  <input
                    type="file"
                    name="image_file"
                    id="image_file"
                    className="hide-input"
                    placeholder=""
                    onChange={onFileUpload}
                  />
                  <label htmlFor="file" className="upload">
                    Choose File
                  </label>
                </div>
                <label className="custom-file-label" htmlFor="inputGroupFile01">
                  {image_file ? image_file.name : ""}
                </label>
              </div>
            </div>

            <div className="form-group col-md-12">
              <label>Message</label>
              <div className="input-group">
                <textarea
                  className="form-control"
                  style={{ height: "200px" }}
                  rows={3}
                  cols={30}
                  defaultValue={""}
                  value={CustomMessage}
                  onChange={(e) => setCustomMessage(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setShowSendMessage(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSendData}
            >
              Send
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={showPayment} onHide={() => setShowPayment(false)}>


      <Modal.Header closeButton>
        
        <Modal.Title>
          <div className="row">
            <div className="col-lg-12">
            <strong className="customer-text">
                Payment
              </strong>
            </div>
          </div>
          
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
        <div className="tab-content-set">
            <ul className="nav">
              <li
                className={activeTab === "normal" ? "active" : ""}
                onClick={() => setActiveTab("normal")}
              >
                Normal
              </li>
              <li
                className={activeTab === "treatmentwise" ? "active" : ""}
                onClick={() => setActiveTab("treatmentwise")}
              >
                Treatment Wise
              </li>
            </ul>
          </div>
        </div>
        {activeTab === "normal" && (
          <div className="col-12 col-md-12 col-xl-12">
            {/* normal Modal Body */}
            <div className="form-group local-forms">
              <label>
                Payment Method
                <span className="login-danger">*</span>
              </label>
              <Select
                defaultValue={paymentmethod}
                onChange={setPaymentmethod}
                options={paymentoption}
                menuPortalTarget={document.body}
                id="search-commodity"
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "none"
                      : "2px solid rgba(46, 55, 164, 0.1);",
                    boxShadow: state.isFocused ? "0 0 0 1px #2e37a4" : "none",
                    "&:hover": {
                      borderColor: state.isFocused
                        ? "none"
                        : "2px solid rgba(46, 55, 164, 0.1)",
                    },
                    borderRadius: "10px",
                    fontSize: "14px",
                    minHeight: "45px",
                  }),
                  dropdownIndicator: (base, state) => ({
                    ...base,
                    transform: state.selectProps.menuIsOpen
                      ? "rotate(-180deg)"
                      : "rotate(0)",
                    transition: "250ms",
                    width: "35px",
                    height: "35px",
                  }),
                }}
              />
            </div>
            <div className="form-group local-forms">
              <label>
                Amount
                <span className="login-danger">*</span>
              </label>
              <input
                className="form-control"
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div className="form-group local-forms">
              <label>
                Transaction Id
                <span className="login-danger">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
              />
            </div>
            <div className="form-group local-top-form">
              <label className="local-top">
                Upload Receipt <span className="login-danger"></span>
              </label>
              <div className="settings-btn upload-files-avator">
                <input
                  type="file"
                  accept="image/*"
                  name="payment_receipt"
                  id="file"
                  className="hide-input"
                  onChange={onFilePaymentUpload}
                />
                <label htmlFor="file" className="upload">
                  Choose File
                </label>
                <label className="custom-file-label" htmlFor="inputGroupFile01">
                  {receipt ? receipt.name : ""}
                </label>
              </div>
            </div>
          </div>
        )}
        {activeTab === "treatmentwise" && (
          <div>
            {/* treatmentwise Modal Body */}
            <div className="form-group local-forms">
                <label>
                  Date of Visit{" "}
                </label>
                <DatePicker
                  className="form-control datetimepicker"
                  suffixIcon={null}
                  style={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                    borderColor: isClicked ? '#2E37A4' : '2px solid rgba(46, 55, 164, 0.1)',
                    '&:hover': {
                      borderColor: state.isFocused ? 'none' : 'none',
                    },
                  })
                }}
                />
            </div>
            <div className="form-group local-forms">
              <label>
                Treatment
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Treatment"
              />
            </div>
            <h5>Treatment Info</h5>
            <div className="form-group local-forms">
              <label>
                Payment Mode
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Payment Mode"
              />
            </div>
            <div className="form-group local-forms">
              <label>
                Total Paid Amount
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Total Paid Amount"
              />
            </div>
            <div className="form-group local-forms">
              <label>
              Balance Amount
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Balance Amount"
              />
            </div>
            <div className="form-group local-forms">
              <label>
              Total Amount
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Total Amount"
              />
            </div>
            {/* <div className="form-group local-forms">
              <label>
                Upload X-rays
                <span className="login-danger">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter prescription details"
              />
            </div> */}
            <div className="form-group local-forms">
                <label>Upload X-rays</label>
                <div className="col-lg-12">
                  <input className="form-control" type="file" />
                  
                </div>
              
              </div>
            <div className="form-group local-forms">
              <label>
                Remarks
                <span className="login-danger">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Remarks"
              />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => setShowPayment(false)}
        >
          Close
        </button>
        <button type="button" className="btn btn-primary">
          Save
        </button>
      </Modal.Footer>
    </Modal>
  

      </>
    </>
  );
};

export default AppoinmentList;
