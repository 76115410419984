/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import ClinicSettingsHeader from "../../ClinicSettingsHeader";
import { Link, useParams  } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { favicon } from "../../imagepath";
import Select from "react-select";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Api from "../../../helper/api";
import AuthApi from "../../../helper/authApi";
import { loadingService } from "../../../helper/observer";
import { Modal } from "react-bootstrap";

const AddRoles = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [permissionsList, setPermissionsList] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [name, setName] = useState("");
  const [userData, setUserData] = useState(null);

  const fetchData = async () => {
    try {
        const udata = localStorage.getItem("userdata");
        const data = JSON.parse(udata);
        loadingService.next(true);
    
        // Fetch available permissions list
        const permissionsResponse = await AuthApi.get(`${Api.permissionslist}`);
        const { data: permissionsData } = permissionsResponse.data;
    
        // Fetch assigned permissions for the user/role againt id
        const rolesInfoResponse = await AuthApi.get(`${Api.rolesinfo}/${id}`);
        const { data: assignedPermissionsData } = rolesInfoResponse.data;
    
        loadingService.next(false);
    
        // Ensure that permissionsData and assignedPermissionsData are valid
        if (!permissionsData || !assignedPermissionsData) {
          toast.error("Unable to load permissions data");
          return;
        }
    
        // Set the permissions list to be displayed
        setPermissionsList(permissionsData);
        setName(assignedPermissionsData.name)
    
        // Ensure that the permissions array exists before mapping
        const assignedPermissionIds = assignedPermissionsData.permissions
          ? assignedPermissionsData.permissions.map((perm) => perm.id)
          : [];
        
        setSelectedPermissions(assignedPermissionIds);
      } catch (error) {
        console.error("Error fetching permissions or roles info:", error);
        toast.error("Failed to load data. Please try again.");
      }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCheckboxChange = (e, permission) => {
    if (e.target.checked) {
      // Add permission ID to selectedPermissions
      setSelectedPermissions([...selectedPermissions, permission.id]);
    } else {
      // Remove permission ID from selectedPermissions
      setSelectedPermissions(
        selectedPermissions.filter((id) => id !== permission.id)
      );
    }
  };

  // to Edit Role
  const SubmitForm = async (e) => {
    e.preventDefault();

    if (!name) {
      toast.error("Please fill out all required fields.");
      return;
    }

    let payload = {
      name: name,
      permissions: selectedPermissions,
    };

    try {
      loadingService.next(true);

      const { data } = await AuthApi.put(Api.updateroles, payload);
      loadingService.next(false);

      if (data) {
        toast.success("Success");
        navigate("/roleslist");
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);

    setUserData(data.data);
  }, []);

  return (
    <div>
      <Header />
      <Sidebar activeClassName="usermanagement" />

      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="index.html">Dashboard </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Settings</li>
                </ul>
              </div>
            </div>
          </div>
          <ClinicSettingsHeader />{/* Header for Clinic Settings Page only */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body p-4">
                  <form onSubmit={SubmitForm}>
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="row align-items-center">
                          <div className="card invoices-tabs-card">
                            <div className="card-body card-body pt-0 pb-0">
                              <div className="permissionsnroles-main-tabs">
                                <div className="row align-items-center">
                                  <div className="col-lg-8 col-md-8">
                                    <div className="invoices-tabs">
                                      <ul>
                                        <li>
                                          <Link to="/clinicsetting_addpermission">
                                            Permissions
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="/clinicsetting_roleslist"
                                            className="active"
                                          >
                                            Roles
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group form-placeholder">
                              <label>
                                Role Name <span className="star-red"></span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="page-table-header mb-2">
                      <div className="row">
                        <div className="col-12 col-md-12 col-xl-12">
                          <div className="form-group select-gender">
                            {permissionsList.length > 0 ? (
                              <div className="row">
                                {/* Group every 4 permissions into a column */}
                                {permissionsList.map((permission, index) => {
                                  if (index % 4 === 0) {
                                    return (
                                      <div className="col-2" key={index}>
                                        {permissionsList
                                          .slice(index, index + 4)
                                          .map((perm, subIndex) => (
                                            <div
                                              className="form-check"
                                              key={subIndex}
                                            >
                                              <label className="form-check-label">
                                                <input
                                                  type="checkbox"
                                                  name={perm.name}
                                                  className="form-check-input"
                                                  checked={selectedPermissions.includes(
                                                    perm.id
                                                  )}
                                                  onChange={(e) =>
                                                    handleCheckboxChange(
                                                      e,
                                                      perm
                                                    )
                                                  }
                                                />
                                                {perm.displayName || perm.name}
                                              </label>{" "}
                                              <br />
                                            </div>
                                          ))}
                                        <br />
                                      </div>
                                    );
                                  } else {
                                    return null; // Return null for non-starting indexes of each group of 4
                                  }
                                })}
                              </div>
                            ) : (
                              <p>loadingService.next(true);</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-2">
                        <div className="doctor-submit text-end">
                          <button
                            type="submit"
                            className="btn btn-primary submit-form me-2"
                          >
                            Save
                          </button>
                          <button className="btn btn-primary cancel-form"
                          type="submit"
                          onClick={()=>navigate("/roleslist")}>
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRoles;
