import React from 'react'
import { Link } from "react-router-dom";

const GeneralSettingsHeader = () => {
  return (
    <div>
      {/* /Page Header */}
      <div className="settings-menu-links">
            <ul className="nav nav-tabs menu-tabs">
              <li className="nav-item active">
                <Link className="nav-link " to="/registration">
                  New Registration
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link " to="/appsettings">
                  General Settings
                </Link>
              </li>
              <li className="nav-item ">
                <Link className="nav-link" to="/userlist">
                  User Management
                </Link>
              </li>
              <li className="nav-item ">
                <Link className="nav-link" to="/packagemanagement">
                  Subcscription
                </Link>
              </li>
              <li className="nav-item ">
                <Link className="nav-link" to="/packagemanagement">
                 Billing
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/emailsettings">
                 Communication Settings
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/addpermission">
                Roles & Permissions 
                </Link>
              </li>
              
            </ul>
          </div>
    </div>
  )
}

export default GeneralSettingsHeader
