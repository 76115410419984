/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Select from "react-select";
import { Link, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { loadingService } from "../../helper/observer";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";

const Editons = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Assumes the medication ID is passed in the route parameters
  const [isClicked, setIsClicked] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [name, setName] = useState("");
  const [description, setdescription] = useState("");
  const [price, setprice] = useState("");
  const [is_per_branch, setis_per_branch] = useState("");
  const [is_active, setis_active] = useState("");
  const [status, setstatus] = useState("");

//   const [no_of_times, setNo_of_Times] = useState("");
  const [userData, setUserData] = useState(null);
  const [no_of_time, setTimeOfDay] = useState("");

  const getAddonslist = async () => {
    try {
      const response = await AuthApi.get(`${Api.addonsinfo}/${id}`);
      const { data, message, status } = response;

      if (!data) {
        toast.success(message);
        if (status === 401) navigate("/login");
        return;
      }

      const Addons = data.data;
      setName(Addons.name);
      setdescription(Addons.description);
      setprice(Addons.price);
      setis_per_branch(Addons.is_per_branch);
      setis_active(Addons.is_active);

      
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getAddonslist();
  }, []);

  const SubmitForm = async (e) => {
    e.preventDefault();

    if (!name) {
      toast.error("Please fill out all required fields.");
      return;
    }

    let payload = {
      name: name,
      description: description,
      price: price,
      is_per_branch:is_per_branch,
      is_active:is_active,
      
    };

    try {
      const { status } = await AuthApi.put(
        `${Api.updateaddon}/${id}`,
        payload
      );
      if (status) {
        toast.success("Success");
        navigate("/addonslist");
      }
    } catch (error) {
      toast.error(error);
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item23"
        id1="menu-items23"
        activeClassName="add-Addons"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Addons </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Edit Ons</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-10">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={SubmitForm}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Edit Details</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Name{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              placeholder=""
                              
                            //  onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Description <span className="login-danger"></span>
                            </label>
                            <textarea
                              className="form-control"
                              type="text"
                              value={description}
                              onChange={(e) => setdescription(e.target.value)}
                              placeholder=""
                            />
                          </div>
                        </div> 
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group">
                            <label className="local-forms">
                              Do you want us to use this is Per Branch
                              Update?
                            </label>
                            <div className="status-toggle">
                              <input
                                type="checkbox"
                                id="status_1"
                                className="check"
                                checked={is_per_branch}
                                onChange={(e) =>
                                  setis_per_branch(e.target.checked)
                                } // Use e.target.checked for boolean
                              />
                              <label htmlFor="status_1" className="checktoggle">
                                checkbox
                              </label>
                            </div>
                          </div>
                        </div> 
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group">
                            <label className="local-forms">
                            is Active or not
                              Update?
                            </label>
                            <div className="status-toggle">
                              <input
                                type="checkbox"
                                id="status_2"
                                className="check"
                                checked={is_active}
                                onChange={(e) =>
                                  setis_active(e.target.checked)
                                } // Use e.target.checked for boolean
                              />
                              <label htmlFor="status_2" className="checktoggle">
                                checkbox
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Price <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              value={price}
                              placeholder=""
                              onChange={(e) => setprice(e.target.value)}
                            />
                          </div>
                        </div>
                       
                        {/*  */}
                        

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                navigate("/addonslist");
                              }}
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Editons;
